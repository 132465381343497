import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row } from "antd";


import { API_ERRO_TYPE_VALIDATION } from "../../config/general";

import { FORM_VALIDATION_MESSAGES } from "../../config/lang";

import { authService } from "../../redux/services";

import DefaultTemplate from "../../templates/defaultTemplate";

const FormItem = Form.Item;
class AccountPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isSending: false,
		};
	}

	fieldOptions = {
		password                 : {
			label    : "Senha atual",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
					{min: 6, message: "Deve conter no mínimo 6 caracteres."},
				],
			},
		},
		password_new             : {
			label    : "Nova senha",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
					{min: 6, message: "Deve conter no mínimo 6 caracteres."},
				],
			},
		},
		password_new_confirmation: {
			label    : "Confirmar nova senha",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
					{min: 6, message: "Deve conter no mínimo 6 caracteres."},
				],
			},
		},
	};

	resetFields = () => {
		this.props.form.resetFields();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				// Do Change password
				authService.changePassword(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success(response.data.message);
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});
							}
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	render() {
		const {getFieldDecorator} = this.props.form;

		return (
			<DefaultTemplate>
				<div className="page-content" key="1">
					<h1 className="page-title">Alterar senha</h1>
					<Row>
						<Col xs={24} sm={12} md={10} lg={8} xl={6}>
							<Form layout="vertical" onSubmit={this.onSubmit}>
								<FormItem label={this.fieldOptions.password.label} hasFeedback>
									{getFieldDecorator("password", this.fieldOptions.password.decorator)(
										<Input type="password" />
									)}
								</FormItem>
								<FormItem label={this.fieldOptions.password_new.label} hasFeedback>
									{getFieldDecorator("password_new", this.fieldOptions.password_new.decorator)(
										<Input type="password" />
									)}
								</FormItem>
								<FormItem label={this.fieldOptions.password_new_confirmation.label} hasFeedback>
									{getFieldDecorator("password_new_confirmation", this.fieldOptions.password_new_confirmation.decorator)(
										<Input type="password" />
									)}
								</FormItem>
								<FormItem>
									<Button type="primary" htmlType="submit" loading={this.props.isSending}>Alterar senha</Button>
								</FormItem>
							</Form>
						</Col>
					</Row>
				</div>
			</DefaultTemplate>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(AccountPassword);

import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tabs,
  Upload,
} from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import {
  tutorialCategoryService,
  culturesService,
  tutorialService,
  tutorialAuthorService,
} from "./../../redux/services";

import { EMVideos, EMContents } from "../../components";

const FormItem = Form.Item;

const config = {
  images: {
    images: {
      maxSize: 4,
      maxFiles: 99,
      extensions: ["jpg", "png"],
      type: ["image/jpeg", "image/png"],
    },
    ads: {
      maxSize: 4,
      maxFiles: 1,
      extensions: ["jpg", "png"],
      type: ["image/jpeg", "image/png"],
    },
  },
};

class Edit extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSending: false,
      id: 0,
      item: {},
      // Images
      imagePreviewVisible: false,
      imagePreviewImage: "",
      imageAdsPreviewVisible: false,
      imageAdsPreviewImage: "",
      imageList: {
        images: [],
      },
      imageListDeleted: {
        images: [],
      },
      imageAdsList: {
        images: [],
      },
      imageAdsListDeleted: {
        images: [],
      },
      cultures: [],
      categories: [],
      tutorial_types: ["Gratuito", "Premium", "Patrocinado"],
      authorList: [],
    };
  }

  fieldOptions = {
    cultures: {
      label: "Culturas",
      decorator: {},
    },
    category_id: {
      label: "Categoria",
      decorator: {
        rules: [{ required: true, message: "Campo obrigatório." }],
      },
    },
    author_id: {
      label: "Autor",
      decorator: {
        rules: [{ required: true, message: "Campo obrigatório." }],
      },
    },
    name: {
      label: "Nome",
      decorator: {
        rules: [{ required: true, message: "Campo obrigatório." }],
      },
    },
    subtitle: {
      label: "Subtitulo",
      decorator: {
        rules: [{ required: true, message: "Campo obrigatório." }],
      },
    },
    ads_url: {
      label: "Link externo",
      decorator: {
        rules: [{ type: "url", message: "Informe uma URL válida." }],
      },
    },
    duration: {
      label: "Duração",
      decorator: {
        rules: [{ required: false, message: "Campo obrigatório." }],
      },
    },
    assessment: {
      label: "Avaliacão",
      decorator: {
        rules: [{ required: false, message: "Campo obrigatório." }],
      },
    },
    tutorial_type: {
      label: "Tipo tutorial",
      decorator: {
        rules: [{ required: true, message: "Campo obrigatório." }],
      },
    },
    order: {
      label: "Ordem",
      decorator: {
        rules: [{ required: true, message: "Campo obrigatório." }],
      },
    },
    is_active: {
      label: "Ativo",
      decorator: {
        valuePropName: "checked",
      },
    },
  };

  onOpen = (id) => {
    this.setState({
      isLoading: true,
      id: id,
      item: {},
    });

    let item = {};
    let cultures = [];

    tutorialService
      .show({ id })
      .then((response) => {
        item = response.data.data;

        return culturesService.getAutocomplete({
          orderBy: "name:asc",
        });
      })
      .then((response) => {
        cultures = response.data.data;

        return tutorialCategoryService.getAutocomplete({
          orderBy: "name:asc",
        });
      })
      .then((response) => {
        let imagesList = {
          images: [],
        };

        let imagesAdsList = {
          images: [],
        };

        if (item.images.length) {
          item.images = item.images.filter((item) => item.type === "image");

          item.images.forEach((image) => {
            imagesList.images.push({
              uid: image.id,
              name: image.file.split("/").pop(),
              status: "done",
              url: image.file,
              // Has id, is api image
              id: image.id,
            });
          });
        }

        if (item.ads) {
          imagesAdsList.images.push({
            uid: item.id,
            name: item.ads.split("/").pop(),
            status: "done",
            url: item.ads,
            // Has id, is api image
            id: item.id,
          });
        }

        this.setState(
          {
            item: item,
            imageList: imagesList,
            imageAdsList: imagesAdsList,
            cultures: cultures,
            categories: response.data.data,
          },
          () => {
            // Fill form
            this.fillForm(item);
          }
        );
      })
      .catch((data) => {
        this.setState({
          isLoading: false,
        });

        Modal.error({
          title: "Ocorreu um erro!",
          content: data.error_message,
          onOk: () => {
            // Force close
            return this.onClose();
          },
        });
      });

    tutorialAuthorService
      .getAll()
      .then((res) => {
        console.log("authorList ", res);
        this.setState({
          ...this.state,
          authorList: res.data.data,
          isLoading: false,
        });
      })
      .catch((data) => {
        this.setState({
          isLoading: false,
        });
        Modal.error({
          title: "Ocorreu um erro ao buscar Autores!",
          content: data.error_message,
          onOk: () => {
            // Force close
            return this.onClose();
          },
        });
      });
  };

  fillForm = (data) => {
    this.props.form.setFieldsValue({
      cultures: data.cultures.map((c) => c.id),
      category_id: data.category_id,
      name: data.name,
      ads_url: data.ads_url,
      order: data.order,
      is_active: data.is_active,
      subtitle: data.subtitle,
      assessment: data.assessment,
      tutorial_type: data.tutorial_type,
      author_id: data.author_id,
      duration: data.duration,
    });
  };

  resetFields = () => {
    this.props.form.resetFields();

    this.setState({
      imageList: {
        images: [],
      },
      imageAdsList: {
        images: [],
      },
      imageListDeleted: {
        images: [],
      },
      cultures: [],
      categories: [],
    });
  };

  onClose = () => {
    // Reset fields
    this.resetFields();

    // Callback
    this.props.onClose();
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((error, values) => {
      if (!error) {
        this.setState({
          isSending: true,
        });

        const {
          id,
          imageList,
          imageAdsList,
          imageListDeleted,
          imageAdsListDeleted,
        } = this.state;

        // ID
        values.id = id;

        // Images
        if (imageList.images.length) {
          let images = imageList.images.filter((image) => !image.id);

          if (images.length) {
            values.images = images;
          }
        }

        // Images delete
        if (imageListDeleted.images.length) {
          values.delete_images = imageListDeleted.images;
        }

        // Images ads
        if (imageAdsList.images.length) {
          if (!imageAdsList.images[0].id) {
            values.ads = imageAdsList.images[0];
          }
        } else {
          values.ads = null;
        }

        tutorialService
          .edit(values)
          .then((response) => {
            this.setState({
              isSending: false,
            });

            // Reset fields
            this.resetFields();

            // Success message
            message.success("Registro atualizado com sucesso.");

            // Callback
            this.props.onComplete();
          })
          .catch((data) => {
            this.setState({
              isSending: false,
            });

            // if validation error
            if (data.error_type === API_ERRO_TYPE_VALIDATION) {
              let hasFieldsErrors = false;

              for (let key in data.error_errors) {
                if (
                  data.error_errors[key] &&
                  this.fieldOptions[key]
                ) {
                  this.props.form.setFields({
                    [key]: {
                      value: values[key],
                      errors: [new Error(data.error_errors[key])],
                    },
                  });

                  hasFieldsErrors = true;
                }
              }

              if (!hasFieldsErrors) {
                Modal.error({
                  title: "Ocorreu um erro!",
                  content: data.error_message,
                });
              }
            } else {
              Modal.error({
                title: "Ocorreu um erro!",
                content: data.error_message,
              });
            }
          });
      }
    });
  };

  onImagePreviewClose = () => this.setState({ imagePreviewVisible: false });

  onImagePreview = (type, file) => {
    this.setState({
      imagePreviewImage: file.url,
      imagePreviewVisible: true,
    });
  };

  onImageRemove = (type, file) => {
    let imagesNew = [...this.state.imageList[type]];
    let imageListDeletedNew = [...this.state.imageListDeleted[type]];

    const index = imagesNew.findIndex((item) => file.uid === item.uid);

    if (index !== -1) {
      imagesNew.splice(index, 1);

      // Has id
      if (file.id) {
        imageListDeletedNew.push(file.id);
      }

      this.setState((state) => ({
        imageList: {
          ...state.imageList,
          [type]: imagesNew,
        },
        imageListDeleted: {
          ...state.imageListDeleted,
          [type]: imageListDeletedNew,
        },
      }));
    }
  };

  onImageAdsPreviewClose = () =>
    this.setState({ imageAdsPreviewVisible: false });

  onImageAdsPreview = (type, file) => {
    this.setState({
      imageAdsPreviewImage: file.url,
      imageAdsPreviewVisible: true,
    });
  };

  onImageAdsRemove = (type, file) => {
    let imagesAdsNew = [...this.state.imageAdsList[type]];
    let imageAdsListDeletedNew = [...this.state.imageAdsListDeleted[type]];

    const index = imagesAdsNew.findIndex((item) => file.uid === item.uid);

    if (index !== -1) {
      imagesAdsNew.splice(index, 1);

      // Has id
      if (file.id) {
        imageAdsListDeletedNew.push(file.id);
      }

      this.setState((state) => ({
        imageAdsList: {
          ...state.imageAdsList,
          [type]: imagesAdsNew,
        },
        imageAdsListDeleted: {
          ...state.imageAdsListDeleted,
          [type]: imageAdsListDeletedNew,
        },
      }));
    }
  };

  renderAds(type) {
    const imageAdsList = this.state.imageAdsList[type];
    const imageConfig = config.images["ads"];

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div className="media-images-wrap">
        <Upload
          accept={`.${imageConfig.extensions.join(",.")}`}
          listType="picture-card"
          className="media-images-uploader"
          fileList={imageAdsList}
          onPreview={(file) => this.onImageAdsPreview(type, file)}
          onRemove={(file) => this.onImageAdsRemove(type, file)}
          beforeUpload={(file) => {
            if (!imageConfig.type.includes(file.type)) {
              message.error(
                `Somente são aceitos arquivos ${imageConfig.extensions
                  .join(", ")
                  .toUpperCase()}!`
              );

              return false;
            }

            const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

            if (!isValidSize) {
              message.error(
                `A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`
              );

              return false;
            }

            let reader = new FileReader();
            reader.onload = (e) => {
              let imagesAdsNew = [...this.state.imageAdsList[type]];

              if (imagesAdsNew.length < imageConfig.maxFiles) {
                // Base64
                file.url = e.target.result;

                imagesAdsNew.push(file);

                this.setState((state) => ({
                  imageAdsList: {
                    ...state.imageAdsList,
                    [type]: imagesAdsNew,
                  },
                }));
              }
            };

            reader.readAsDataURL(file);

            return false;
          }}
        >
          {imageAdsList.length >= imageConfig.maxFiles ? null : uploadButton}
        </Upload>
      </div>
    );
  }

  renderImages(type) {
    const imageList = this.state.imageList[type];
    const imageConfig = config.images[type];

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div className="media-images-wrap">
        <Upload
          accept={`.${imageConfig.extensions.join(",.")}`}
          listType="picture-card"
          className="media-images-uploader"
          fileList={imageList}
          onPreview={(file) => this.onImagePreview(type, file)}
          onRemove={(file) => this.onImageRemove(type, file)}
          beforeUpload={(file) => {
            if (!imageConfig.type.includes(file.type)) {
              message.error(
                `Somente são aceitos arquivos ${imageConfig.extensions
                  .join(", ")
                  .toUpperCase()}!`
              );

              return false;
            }

            const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

            if (!isValidSize) {
              message.error(
                `A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`
              );

              return false;
            }

            let reader = new FileReader();
            reader.onload = (e) => {
              let imagesNew = [...this.state.imageList[type]];

              if (imagesNew.length < imageConfig.maxFiles) {
                // Base64
                file.url = e.target.result;

                imagesNew.push(file);

                this.setState((state) => ({
                  imageList: {
                    ...state.imageList,
                    [type]: imagesNew,
                  },
                }));
              }
            };

            reader.readAsDataURL(file);

            return false;
          }}
        >
          {imageList.length >= imageConfig.maxFiles ? null : uploadButton}
        </Upload>
      </div>
    );
  }

  render() {
    const { visible, form } = this.props;

    const {
      id,
      item,
      isLoading,
      isSending,
      imageAdsPreviewVisible,
      imagePreviewVisible,
      imagePreviewImage,
      imageAdsPreviewImage,
      cultures,
      categories,
    } = this.state;

	console.log('ITEM EDIT', item)
    const { getFieldDecorator } = form;

    return (
      <Drawer
        visible={visible}
        className="drawer-form"
        width={1200}
        maskClosable={false}
        closable={false}
        keyboard={false}
        placement="right"
        onClose={this.onClose}
      >
        <Form layout="vertical" onSubmit={this.onSubmit}>
          <div className="form-header">
            <Button
              className="btn-close"
              onClick={this.onClose}
              icon="close"
              disabled={isLoading || isSending}
            />
            <div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-save"
              icon="check"
              loading={isSending}
              disabled={isLoading}
            >
              Salvar
            </Button>
          </div>
          {isLoading ? (
            <div className="text-center" style={{ padding: 20 }}>
              <Spin
                indicator={
                  <Icon type="loading" style={{ fontSize: 60 }} spin />
                }
              />
            </div>
          ) : (
            <div className="form-body">
              <FormItem label="Imagens">{this.renderImages("images")}</FormItem>
              <Row gutter={16}>
                <Col xs={24} md={3}>
                  <FormItem label="Ads">
                    {this.renderAds("images")}
                    <small>Dimensões: (1000x320px)</small>
                  </FormItem>
                </Col>
                <Col xs={24} md={9}>
                  <FormItem label={this.fieldOptions.ads_url.label} hasFeedback>
                    {getFieldDecorator(
                      "ads_url",
                      this.fieldOptions.ads_url.decorator
                    )(<Input />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={10}>
                  <FormItem
                    label={this.fieldOptions.cultures.label}
                    hasFeedback
                  >
                    {getFieldDecorator(
                      "cultures",
                      this.fieldOptions.cultures.decorator
                    )(
                      <Select
                        mode="multiple"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        showSearch
                      >
                        {cultures.map((item, index) => (
                          <Select.Option key={index} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} md={7}>
                  <FormItem label={this.fieldOptions.name.label} hasFeedback>
                    {getFieldDecorator(
                      "name",
                      this.fieldOptions.name.decorator
                    )(<Input />)}
                  </FormItem>
                </Col>
                <Col xs={24} md={7}>
                  <FormItem
                    label={this.fieldOptions.category_id.label}
                    hasFeedback
                  >
                    {getFieldDecorator(
                      "category_id",
                      this.fieldOptions.category_id.decorator
                    )(
                      <Select
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        showSearch
                      >
                        {categories.map((item, index) => (
                          <Select.Option key={index} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <FormItem label={this.fieldOptions.subtitle.label} hasFeedback>
                {getFieldDecorator(
                  "subtitle",
                  this.fieldOptions.subtitle.decorator
                )(<Input />)}
              </FormItem>
              <FormItem label={this.fieldOptions.duration.label} hasFeedback>
                {getFieldDecorator(
                  "duration",
                  this.fieldOptions.duration.decorator
                )(<InputNumber min={0} />)}
              </FormItem>
              <FormItem label={this.fieldOptions.assessment.label} hasFeedback>
                {getFieldDecorator(
                  "assessment",
                  this.fieldOptions.assessment.decorator
                )(<InputNumber min={0} max={5} />)}
              </FormItem>
              <FormItem label={this.fieldOptions.author_id.label} hasFeedback>
                {getFieldDecorator(
                  "author_id",
                  this.fieldOptions.author_id.decorator
                )(
                  <Select
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    showSearch
                  >
                    {this.state.authorList?.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem
                label={this.fieldOptions.tutorial_type.label}
                hasFeedback
              >
                {getFieldDecorator(
                  "tutorial_type",
                  this.fieldOptions.tutorial_type.decorator
                )(
                  <Select
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    showSearch
                  >
                    {this.state.tutorial_types.map((item, index) => (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem
                label={this.fieldOptions.order.label}
                hasFeedback
                style={{ maxWidth: 150 }}
              >
                {getFieldDecorator(
                  "order",
                  this.fieldOptions.order.decorator
                )(<InputNumber min={0} />)}
              </FormItem>
              <FormItem label={this.fieldOptions.is_active.label}>
                {getFieldDecorator(
                  "is_active",
                  this.fieldOptions.is_active.decorator
                )(<Switch />)}
              </FormItem>
					 {item.id &&
              <Tabs defaultActiveKey="contents" style={{ marginBottom: 24 }}>
                <Tabs.TabPane tab="Conteúdos" key="contents">
                  <EMContents item={this.state.item} type="tutorials" />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Videos" key="videos">
                  <EMVideos item={item} type="tutorials" />
                </Tabs.TabPane>
              </Tabs>}

            </div>
          )}
        </Form>
        <Modal
          className="modal-image"
          visible={imagePreviewVisible}
          footer={null}
          destroyOnClose={true}
          onCancel={this.onImagePreviewClose}
        >
          <img src={imagePreviewImage} />
        </Modal>
        <Modal
          className="modal-image"
          visible={imageAdsPreviewVisible}
          footer={null}
          destroyOnClose={true}
          onCancel={this.onImageAdsPreviewClose}
        >
          <img src={imageAdsPreviewImage} />
        </Modal>
      </Drawer>
    );
  }
}

export default Form.create({
  validateMessages: FORM_VALIDATION_MESSAGES,
})(Edit);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Icon, Input, message, Modal } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { authService } from "./../../redux/services";

import LoginTemplate from "./../../templates/loginTemplate";

const FormItem = Form.Item;

class RecoveryPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isSending: false,
		};
	}

	fieldOptions = {
		email: {
			label    : "Informe o e-mail cadastrado",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
					{type: "email", message: "Informe um e-mail válido."},
				],
			},
		},
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			logo               : null,
			logoPreview        : null,
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imageList          : [],
		});
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				authService.passwordRecovery(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success(response.data.message);
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	render() {
		const {getFieldDecorator} = this.props.form;

		return (
			<LoginTemplate>
				<div className="page-content" key="1">
					<h1 className="page-title">Recuperar senha</h1>
					<Form layout="vertical" onSubmit={this.onSubmit}>
						<FormItem>
							{getFieldDecorator("email", this.fieldOptions.email.decorator)(
								<Input prefix={<Icon type="mail" />} placeholder={this.fieldOptions.email.label} />
							)}
						</FormItem>
						<FormItem>
							<Button type="primary" htmlType="submit" size="large" block loading={this.state.isSending}>Enviar</Button>
						</FormItem>
					</Form>
					<div className="text-center">
						<br /><br />
						<Link to="/login">Voltar</Link>
					</div>
				</div>
			</LoginTemplate>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(RecoveryPassword);

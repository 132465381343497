import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Card, Checkbox, Col, Drawer, Form, Icon, Input, message, Modal, Row, Spin, Switch } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { roleAndPermissionService, userService } from "./../../redux/services";

const FormItem = Form.Item;

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading    : true,
			isSending    : false,
			roles        : [],
			rolesSelected: [],
			id           : 0,
		};
	}

	fieldOptions = {
		first_name: {
			label    : "Nome",
			decorator: {
				hidden: true,
			},
		},
		last_name : {
			label    : "Sobrenome",
			decorator: {
				hidden: true,
			},
		},
		email     : {
			label    : "E-mail",
			decorator: {
				hidden: true,
			},
		},
		is_active : {
			label    : "Ativo",
			decorator: {
				valuePropName: "checked",
			},
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
		});

		let item = {};

		userService.show({id})
		.then((response) => {
			item = response.data.data;

			return roleAndPermissionService.getAutocomplete({
				orderBy: "system:desc|name:asc",
			});
		})
		.then((response) => {
			this.setState({
				isLoading: false,
				roles    : response.data.data,
			});

			// Fill form
			this.fillForm(item);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			first_name: data.first_name,
			last_name : data.last_name,
			email     : data.email,
			is_active : data.is_active,
		});

		this.setState({
			rolesSelected: data.roles.map(role => role.id),
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			roles        : [],
			rolesSelected: [],
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				const {rolesSelected} = this.state;

				if( !rolesSelected.length )
				{
					Modal.error({
						title  : "Ocorreu um erro!",
						content: "Selecione pelo menos um papél.",
					});

					return false;
				}

				this.setState({
					isSending: true,
				});

				const {id} = this.state;

				// ID
				values.id = id;

				// Roles
				values.roles = rolesSelected;

				userService.edit(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro atualizado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	onChangeRole = (id, checked) => {
		const {rolesSelected} = this.state;

		let newRolesSelected = [...rolesSelected];
		const indexSelected  = newRolesSelected.indexOf(id);

		if( checked )
		{
			if( indexSelected === -1 )
			{
				newRolesSelected.push(id);
			}
		}
		else
		{
			if( indexSelected !== -1 )
			{
				newRolesSelected.splice(indexSelected, 1);
			}
		}

		this.setState({
			rolesSelected: newRolesSelected,
		});
	};

	render() {
		const {visible, form}                                  = this.props;
		const {id, isLoading, isSending, roles, rolesSelected} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={500}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.first_name.label} hasFeedback>
										{getFieldDecorator("first_name", this.fieldOptions.first_name.decorator)(
											<Input disabled />
										)}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.last_name.label} hasFeedback>
										{getFieldDecorator("last_name", this.fieldOptions.last_name.decorator)(
											<Input disabled />
										)}
									</FormItem>
								</Col>
							</Row>
							<FormItem label={this.fieldOptions.email.label} hasFeedback>
								{getFieldDecorator("email", this.fieldOptions.email.decorator)(
									<Input disabled />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.is_active.label}>
								{getFieldDecorator("is_active", this.fieldOptions.is_active.decorator)(
									<Switch />
								)}
							</FormItem>
							<h3>Papéis</h3>
							{roles.map((role, key) => (
								<Card key={key} style={{marginBottom: 15}} title={<Checkbox onChange={(e) => this.onChangeRole(role.id, e.target.checked)} checked={rolesSelected.indexOf(role.id) !== -1}>{role.name}</Checkbox>}>
									{role.description}
								</Card>
							))}
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Edit);

import { api } from "./../../config/api";

const basePath = "settings";

/**
 * Get social
 *
 * @returns {Promise<T>}
 */
export const getSocial = () => {
	return api.get(`${basePath}/social`);
};

/**
 * Edit social
 *
 * @param {Object} options
 * @param {number} [options.facebook_app_id]
 * @param {string} [options.twitter]
 *
 * @returns {Promise<T>}
 */
export const updateSocial = (options) => {
	return api.post(`${basePath}/social`, options);
};

/**
 * Get general
 *
 * @returns {Promise<T>}
 */
export const getGeneral = () => {
	return api.get(`${basePath}/general`);
};

/**
 * Edit general
 *
 * @param {Object} options
 * @param {string} [options.app_url_android]
 * @param {string} [options.app_url_ios]
 * @param {string} [options.url_site]
 *
 * @returns {Promise<T>}
 */
export const updateGeneral = (options) => {
	return api.post(`${basePath}/general`, options);
};

/**
 * Get notifications
 *
 * @returns {Promise<T>}
 */
export const getNotifications = () => {
	return api.get(`${basePath}/notifications`);
};

/**
 * Edit notifications
 *
 * @param {Object} options
 * @param {string[]} [options.report_a_problem]
 * @param {string[]} [options.ask_agronomists]
 * @param {string[]} [options.denunciations]
 *
 * @returns {Promise<T>}
 */
export const updateNotifications = (options) => {
	return api.post(`${basePath}/notifications`, options);
};

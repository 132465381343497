import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import '../../assets/css/antd.css';
import '../../assets/css/app.css';

import { LocaleProvider } from "antd";

import pt_BR from "antd/lib/locale-provider/pt_BR";

import { PUBLIC_ROUTES, PRIVATE_ROUTES, SESSION_ROUTES } from "./../../config/routes";

import { authActions } from "./../../redux/actions";

import {
	EMRoutePrivate,
	EMRoutePublic,
	EMRouteSession,
} from "./../../components";

import Error404 from "./../../screens/Error404";

class Main extends Component {
	componentDidMount() {
		if( this.props.isAuthenticated )
		{
			// Get usar data
			this.props.refreshUserData();
		}
	}

	render() {
		return (
			<LocaleProvider locale={pt_BR}>
				<BrowserRouter basename="/">
					<Switch>
						{PUBLIC_ROUTES.map((route, key) => (
							<EMRoutePublic exact={route.exact} path={route.path} key={key} component={route.component} />
						))}
						{PRIVATE_ROUTES.map((route, key) => (
							<EMRoutePrivate exact={route.exact} path={route.path} key={key} component={route.component} />
						))}
						{SESSION_ROUTES.map((route, key) => (
							<EMRouteSession exact={route.exact} path={route.path} key={key} component={route.component} />
						))}
						<Route component={Error404} />
					</Switch>
				</BrowserRouter>
			</LocaleProvider>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		accessToken    : state.auth.access_token,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		refreshUserData: () => {
			dispatch(authActions.refreshUserData());
		},
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

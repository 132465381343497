import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Button, Col, Drawer, Form, Icon, Input, message, Modal, Row, Select, Spin, Switch, Tabs, Upload,} from "antd";

import {API_ERRO_TYPE_VALIDATION} from "../../config/general";

import {FORM_VALIDATION_MESSAGES} from "../../config/lang";

import {animalService} from "../../redux/services";
import {EMContents, EMVideos} from "../../components";

const FormItem = Form.Item;

const config = {
    images: {
        images: {
            maxSize: 4,
            maxFiles: 99,
            extensions: ["jpg", "png"],
            type: ["image/jpeg", "image/png"],
        },
    },
};

class Edit extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isSending: false,
            id: 0,
            item: {},
            // Images
            imagePreviewVisible: false,
            imagePreviewImage: "",
            imageList: {
                images: [],
            },
            imageListDeleted: {
                images: [],
            },
            types: props.types,
        };
    }

    fieldOptions = {
        name: {
            label: "Nome",
            decorator: {
                rules: [{required: true, message: "Campo obrigatório."}],
            },
        },
        type_id: {
            label: "Tipo",
            decorator: {
                rules: [{required: true, message: "Campo obrigatório."}],
            },
        },
        race: {
            label: "Raça",
            decorator: {
                rules: [{required: true, message: "Campo obrigatório."}],
            },
        },
        description: {
            label: "Descricao",
            decorator: {
                rules: [],
            },
        },
        is_active: {
            label: "Ativo",
            decorator: {
                valuePropName: "checked",
                initialValue: true,
            },
        },
    };

    onOpen = (id, types) => {
        this.setState({
            isLoading: true,
            id: id,
            item: {},
            types
        });

        let item = {};

        animalService
            .show({id})
            .then((response) => {
                item = response.data.data || response.data;
                let imagesList = {
                    images: [],
                };

                let imagesAdsList = {
                    images: [],
                };

                if (item.images.length) {
                    item.images = item.images.filter((item) => item.type === "image");

                    item.images.forEach((image) => {
                        imagesList.images.push({
                            uid: image.id,
                            name: image.file.split("/").pop(),
                            status: "done",
                            url: image.file,
                            // Has id, is api image
                            id: image.id,
                        });
                    });
                }

                this.setState({
                    isLoading: false,
                    item: item,
                    imageList: imagesList,
                });

                // Fill form
                this.fillForm(item);
            })
            .catch((data) => {
                console.log(data);
                this.setState({
                    isLoading: false,
                });

                Modal.error({
                    title: "Ocorreu um erro!",
                    content: data.error_message,
                    onOk: () => {
                        // Force close
                        return this.onClose();
                    },
                });
            });
    };

    getTypeAnimalStructure = (item) => {
        if(item.parent) {
            return `${item.name}  ->  ${this.getTypeAnimalStructure(item.parent)}`;
        }
        return item.name;
    };

    fillForm = (data) => {
        this.props.form.setFieldsValue({
            type_id: data.type_id,
            name: data.name,
            description: data.description,
            race: data.race,
            is_active: data.is_active,
        });
    };

    resetFields = () => {
        this.props.form.resetFields();

        this.setState({
            imageList: {
                images: [],
            },
            imageAdsList: {
                images: [],
            },
            imageListDeleted: {
                images: [],
            },
            types: [],
        });
    };

    onClose = () => {
        // Reset fields
        this.resetFields();

        // Callback
        this.props.onClose();
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                this.setState({
                    isSending: true,
                });

                const {id, imageList, imageListDeleted} = this.state;

                // ID
                values.id = id;

                // Images
                if (imageList.images.length) {
                    let images = imageList.images.filter((image) => !image.id);

                    if (images.length) {
                        values.images = images;
                    }
                }

                // Images delete
                if (imageListDeleted.images.length) {
                    values.delete_images = imageListDeleted.images;
                }

                animalService
                    .edit(values)
                    .then((response) => {
                        this.setState({
                            isSending: false,
                        });

                        // Reset fields
                        this.resetFields();

                        // Success message
                        message.success("Registro atualizado com sucesso.");

                        // Callback
                        this.props.onComplete();
                    })
                    .catch((data) => {
                        console.log(data);
                        this.setState({
                            isSending: false,
                        });

                        // if validation error
                        if (data.error_type === API_ERRO_TYPE_VALIDATION) {
                            let hasFieldsErrors = false;

                            for (let key in data.error_errors) {
                                if (data.error_errors[key] && this.fieldOptions[key]) {
                                    this.props.form.setFields({
                                        [key]: {
                                            value: values[key],
                                            errors: [new Error(data.error_errors[key])],
                                        },
                                    });

                                    hasFieldsErrors = true;
                                }
                            }

                            if (!hasFieldsErrors) {
                                Modal.error({
                                    title: "Ocorreu um erro!",
                                    content: data.error_message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: "Ocorreu um erro!",
                                content: data.error_message,
                            });
                        }
                    });
            }
        });
    };

    onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

    onImagePreview = (type, file) => {
        this.setState({
            imagePreviewImage: file.url,
            imagePreviewVisible: true,
        });
    };

    onImageRemove = (type, file) => {
        let imagesNew = [...this.state.imageList[type]];
        let imageListDeletedNew = [...this.state.imageListDeleted[type]];

        const index = imagesNew.findIndex((item) => file.uid === item.uid);

        if (index !== -1) {
            imagesNew.splice(index, 1);

            // Has id
            if (file.id) {
                imageListDeletedNew.push(file.id);
            }

            this.setState((state) => ({
                imageList: {
                    ...state.imageList,
                    [type]: imagesNew,
                },
                imageListDeleted: {
                    ...state.imageListDeleted,
                    [type]: imageListDeletedNew,
                },
            }));
        }
    };

    onImageAdsPreviewClose = () =>
        this.setState({imageAdsPreviewVisible: false});

    onImageAdsPreview = (type, file) => {
        this.setState({
            imageAdsPreviewImage: file.url,
            imageAdsPreviewVisible: true,
        });
    };

    onImageAdsRemove = (type, file) => {
        let imagesAdsNew = [...this.state.imageAdsList[type]];
        let imageAdsListDeletedNew = [...this.state.imageAdsListDeleted[type]];

        const index = imagesAdsNew.findIndex((item) => file.uid === item.uid);

        if (index !== -1) {
            imagesAdsNew.splice(index, 1);

            // Has id
            if (file.id) {
                imageAdsListDeletedNew.push(file.id);
            }

            this.setState((state) => ({
                imageAdsList: {
                    ...state.imageAdsList,
                    [type]: imagesAdsNew,
                },
                imageAdsListDeleted: {
                    ...state.imageAdsListDeleted,
                    [type]: imageAdsListDeletedNew,
                },
            }));
        }
    };

    renderAds(type) {
        const imageAdsList = this.state.imageAdsList[type];
        const imageConfig = config.images["ads"];

        const uploadButton = (
            <div>
                <Icon type="plus"/>
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div className="media-images-wrap">
                <Upload
                    accept={`.${imageConfig.extensions.join(",.")}`}
                    listType="picture-card"
                    className="media-images-uploader"
                    fileList={imageAdsList}
                    onPreview={(file) => this.onImageAdsPreview(type, file)}
                    onRemove={(file) => this.onImageAdsRemove(type, file)}
                    beforeUpload={(file) => {
                        if (!imageConfig.type.includes(file.type)) {
                            message.error(
                                `Somente são aceitos arquivos ${imageConfig.extensions
                                    .join(", ")
                                    .toUpperCase()}!`
                            );

                            return false;
                        }

                        const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

                        if (!isValidSize) {
                            message.error(
                                `A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`
                            );

                            return false;
                        }

                        let reader = new FileReader();
                        reader.onload = (e) => {
                            let imagesAdsNew = [...this.state.imageAdsList[type]];

                            if (imagesAdsNew.length < imageConfig.maxFiles) {
                                // Base64
                                file.url = e.target.result;

                                imagesAdsNew.push(file);

                                this.setState((state) => ({
                                    imageAdsList: {
                                        ...state.imageAdsList,
                                        [type]: imagesAdsNew,
                                    },
                                }));
                            }
                        };

                        reader.readAsDataURL(file);

                        return false;
                    }}
                >
                    {imageAdsList.length >= imageConfig.maxFiles ? null : uploadButton}
                </Upload>
            </div>
        );
    }

    renderImages(type) {
        const imageList = this.state.imageList[type];
        const imageConfig = config.images[type];

        const uploadButton = (
            <div>
                <Icon type="plus"/>
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div className="media-images-wrap">
                <Upload
                    accept={`.${imageConfig.extensions.join(",.")}`}
                    listType="picture-card"
                    className="media-images-uploader"
                    fileList={imageList}
                    onPreview={(file) => this.onImagePreview(type, file)}
                    onRemove={(file) => this.onImageRemove(type, file)}
                    beforeUpload={(file) => {
                        if (!imageConfig.type.includes(file.type)) {
                            message.error(
                                `Somente são aceitos arquivos ${imageConfig.extensions
                                    .join(", ")
                                    .toUpperCase()}!`
                            );

                            return false;
                        }

                        const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

                        if (!isValidSize) {
                            message.error(
                                `A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`
                            );

                            return false;
                        }

                        let reader = new FileReader();
                        reader.onload = (e) => {
                            let imagesNew = [...this.state.imageList[type]];

                            if (imagesNew.length < imageConfig.maxFiles) {
                                // Base64
                                file.url = e.target.result;

                                imagesNew.push(file);

                                this.setState((state) => ({
                                    imageList: {
                                        ...state.imageList,
                                        [type]: imagesNew,
                                    },
                                }));
                            }
                        };

                        reader.readAsDataURL(file);

                        return false;
                    }}
                >
                    {imageList.length >= imageConfig.maxFiles ? null : uploadButton}
                </Upload>
            </div>
        );
    }

    render() {
        const {visible, form} = this.props;
        const {
            id,
            item,
            isLoading,
            isSending,
            imagePreviewVisible,
            imagePreviewImage,
            imageAdsPreviewVisible,
            imageAdsPreviewImage,
            types,
        } = this.state;

        const {getFieldDecorator} = form;

        return (
            <Drawer
                visible={visible}
                className="drawer-form"
                width={1200}
                maskClosable={false}
                closable={false}
                keyboard={false}
                placement="right"
                onClose={this.onClose}
            >
                <Form layout="vertical" onSubmit={this.onSubmit}>
                    <div className="form-header">
                        <Button
                            className="btn-close"
                            onClick={this.onClose}
                            icon="close"
                            disabled={isLoading || isSending}
                        />
                        <div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-save"
                            icon="check"
                            loading={isSending}
                            disabled={isLoading}
                        >
                            Salvar
                        </Button>
                    </div>
                    {isLoading ? (
                        <div className="text-center" style={{padding: 20}}>
                            <Spin
                                indicator={
                                    <Icon type="loading" style={{fontSize: 60}} spin/>
                                }
                            />
                        </div>
                    ) : (
                        <div className="form-body">
                            <FormItem label="Imagens">{this.renderImages("images")}</FormItem>
                            <Row gutter={16}>
                                <Col xs={8}>
                                    <FormItem label={this.fieldOptions.name.label} hasFeedback>
                                        {getFieldDecorator(
                                            "name",
                                            this.fieldOptions.name.decorator
                                        )(<Input/>)}
                                    </FormItem>
                                </Col>
                                <Col xs={8}>
                                    {/*<FormItem label={this.fieldOptions.race.label} hasFeedback>*/}
                                    {/*    {getFieldDecorator(*/}
                                    {/*        "race",*/}
                                    {/*        this.fieldOptions.race.decorator*/}
                                    {/*    )(<Input/>)}*/}
                                    {/*</FormItem>*/}
                                    <FormItem label={this.fieldOptions.description.label} hasFeedback>
                                        {getFieldDecorator(
                                            "description",
                                            this.fieldOptions.description.decorator
                                        )(<Input/>)}
                                    </FormItem>
                                </Col>
                                <Col xs={8}>
                                    <FormItem label={this.fieldOptions.type_id.label} hasFeedback>
                                        {getFieldDecorator(
                                            "type_id",
                                            this.fieldOptions.type_id.decorator
                                        )(
                                            <Select
                                                filterOption={(input, option) =>
                                                    option.props.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                                allowClear
                                                showSearch
                                            >
                                                {types.map((item, index) => (
                                                    <Select.Option key={index} value={item.id}>
                                                        {this.getTypeAnimalStructure(item)}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={8}>
                                    <FormItem label={this.fieldOptions.is_active.label}>
                                        {getFieldDecorator(
                                            "is_active",
                                            this.fieldOptions.is_active.decorator
                                        )(<Switch/>)}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Tabs defaultActiveKey="contents" style={{marginBottom: 24}}>
                                <Tabs.TabPane tab="Conteúdos" key="contents">
                                    <EMContents item={item} type="animals"/>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Videos" key="videos">
                                    <EMVideos item={item} type="animals"/>
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                    )}
                </Form>
                <Modal
                    className="modal-image"
                    visible={imagePreviewVisible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.onImagePreviewClose}
                >
                    <img src={imagePreviewImage}/>
                </Modal>
                <Modal
                    className="modal-image"
                    visible={imageAdsPreviewVisible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.onImageAdsPreviewClose}
                >
                    <img src={imageAdsPreviewImage}/>
                </Modal>
            </Drawer>
        );
    }
}

export default Form.create({
    validateMessages: FORM_VALIDATION_MESSAGES,
})(Edit);

import React, { Component } from "react";
import { Button, Col, Form, Icon, Input, InputNumber, message, Modal, Row, Spin, Typography } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { settingService } from "./../../redux/services";

import DefaultTemplate from "./../../templates/defaultTemplate";

const FormItem = Form.Item;

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
		};
	}

	componentDidMount() {
		settingService.getSocial()
		.then((response) => {
			this.setState({
				isLoading: false,
			});

			// Fill form
			this.fillForm(response.data.data);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
			});
		});
	}

	fieldOptions = {
		facebook_app_id: {
			label    : "Facebook app ID",
			decorator: {},
		},
		twitter        : {
			label    : "Conta no twitter",
			decorator: {},
		},
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			facebook_app_id: data.facebook_app_id,
			twitter        : data.twitter,
		});
	};

	resetValidations = () => {
		const values = this.props.form.getFieldsValue();
		let fields   = {};

		for( let key in this.fieldOptions )
		{
			if( this.fieldOptions[key] && values.hasOwnProperty(key) )
			{
				fields = {
					...fields,
					[key]: {
						value: values[key],
					}
				};
			}
		}

		this.props.form.setFields(fields);
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				settingService.updateSocial(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset validations
					this.resetValidations();

					// Success message
					message.success("Configuração atualizada.");
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	render() {
		const {form}                 = this.props;
		const {isLoading, isSending} = this.state;

		const {getFieldDecorator} = form;

		return (
			<DefaultTemplate>
				<div className="page-content" key="1">
					<h1 className="page-title">Configurações de rede social</h1>
					<Form layout="vertical" onSubmit={this.onSubmit}>
						{isLoading ? (
							<div className="text-center" style={{padding: 20}}>
								<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
							</div>
						) : (
							<Row gutter={16}>
								<Col xs={24} sm={12} lg={8} xl={6}>
									<FormItem label={this.fieldOptions.facebook_app_id.label} hasFeedback>
										{getFieldDecorator("facebook_app_id", this.fieldOptions.facebook_app_id.decorator)(
											<InputNumber min={0} />
										)}
									</FormItem>
									<FormItem label={this.fieldOptions.twitter.label} hasFeedback extra={<Typography>Apenas o username como no ex.: https://twitter.com/<Typography.Text mark>nameaccount</Typography.Text></Typography>}>
										{getFieldDecorator("twitter", this.fieldOptions.twitter.decorator)(
											<Input min={0} max={500} />
										)}
									</FormItem>
									<Button type="primary" htmlType="submit" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
								</Col>
							</Row>
						)}
					</Form>
				</div>
			</DefaultTemplate>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Index);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Alert, Button, Form, Icon, Input } from "antd";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { authActions } from "./../../redux/actions";

import LoginTemplate from "./../../templates/loginTemplate";

const FormItem = Form.Item;

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirectToReferrer: false,
		};
	}

	fieldOptions = {
		email   : {
			label    : "E-mail",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
					{type: "email", message: "Informe um e-mail válido."},
				],
			},
		},
		password: {
			label    : "Senha",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				// Do Login
				this.props.doLogin(values);
			}
		});
	};

	render() {
		const {referrer}           = this.props.location.state || {referrer: {pathname: "/"}};
		const {redirectToReferrer} = this.state;

		if( redirectToReferrer )
		{
			return <Redirect to={referrer} />
		}

		const {getFieldDecorator} = this.props.form;
		const {loginError}        = this.props;

		return (
			<LoginTemplate className="page-login">
				<div className="page-content" key="1">
					{loginError.length > 0 &&
					<Alert message={loginError} type="error" showIcon />
					}
					<Form layout="vertical" onSubmit={this.onSubmit}>
						<FormItem>
							{getFieldDecorator("email", this.fieldOptions.email.decorator)(
								<Input prefix={<Icon type="mail" />} placeholder={this.fieldOptions.email.label} />
							)}
						</FormItem>
						<FormItem>
							{getFieldDecorator("password", this.fieldOptions.password.decorator)(
								<Input prefix={<Icon type="lock" />} type="password" placeholder={this.fieldOptions.password.label} />
							)}
						</FormItem>
						<FormItem>
							<Button type="primary" htmlType="submit" size="large" block loading={this.props.isLoggingIn}>Entrar</Button>
						</FormItem>
					</Form>
					<div className="text-center">
						<br /><br />
						<Link to="/recovery-password">Esqueci minha senha</Link>
					</div>
				</div>
			</LoginTemplate>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		isLoggingIn: state.auth.isLoggingIn,
		loginError : state.auth.loginError,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		doLogin: ({email, password}) => {
			dispatch(authActions.login({email, password}));
		}
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Login));

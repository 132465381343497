import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { BackTop, Drawer, Layout, Icon } from "antd";
import QueueAnim from "rc-queue-anim";
import enquire from "enquire.js";

import { SIDEBAR_DRAWER_MEDIA_QUERIE } from "./../config/general";

import {
	generalActions,
} from "./../redux/actions";

import MainNavigation from "./../navigations/mainNavigation";
import UserDropdownNavigation from "./../navigations/userDropdownNavigation";

const {Content, Footer, Header, Sider} = Layout;

class DefaultTemplate extends Component {
	static propTypes = {
		className: PropTypes.string,
	};

	static defaultProps = {
		className: "",
	};

	constructor(props) {
		super(props);

		this.state = {
			siderBarDrawer: false,
		};
	}

	componentDidMount() {
		// Listen Media Querie sideBar
		enquire.register(SIDEBAR_DRAWER_MEDIA_QUERIE, {
			match  : () => {
				this.setState({
					siderBarDrawer: true,
				})
			},
			unmatch: () => {
				this.setState({
					siderBarDrawer: false,
				})
			}
		});
	}

	componentWillUnmount() {
		// Unlisten Media Querie sideBar
		enquire.unregister(SIDEBAR_DRAWER_MEDIA_QUERIE);
	}

	toggle = () => {
		this.props.siderToggle(!this.props.siderCollapsed);
	};

	render() {
		const {siderBarDrawer}            = this.state;
		const {className, siderCollapsed} = this.props;

		const siderWidth = siderBarDrawer ? 0 : (siderCollapsed ? 80 : 256);

		return (
			<Layout className={`template-default ${className}`}>
				{siderBarDrawer ? (
					<Drawer
						placement="left"
						closable={false}
						onClose={this.toggle}
						visible={!siderCollapsed}
						className="template-default-ant-drawer site-menu">
						<div className="logo">
							<img src="images/logos/logo.svg" alt="" />
						</div>
						<MainNavigation
							onClick={this.toggle}
						/>
					</Drawer>
				) : (
					<Sider
						theme="dark"
						trigger={null}
						collapsible={true}
						collapsed={siderCollapsed}
						breakpoint="lg"
						collapsedWidth={80}
						width={256}
						className="site-menu">
						<div className="logo">
							<img src="images/logos/logo.svg" alt="" />
						</div>
						<MainNavigation />
					</Sider>
				)}
				<Layout style={{paddingLeft: siderWidth}}>
					<Header className="site-header" style={{left: siderWidth}}>
						<Icon
							className="trigger"
							type={siderCollapsed ? "menu-unfold" : "menu-fold"}
							onClick={this.toggle}
						/>
						<div className="inner">
							<UserDropdownNavigation />
						</div>
					</Header>
					<Content className="site-content">
						<QueueAnim type="bottom" className="site-content-inner">
							{this.props.children}
						</QueueAnim>
					</Content>
					<Footer className="site-footer">
						<div className="inner">
							<a target="_blank" href="http://www.clickweb.com.br/?utm_source=admin_agroapp&utm_medium=link+copyright&utm_content=link+copyright&utm_campaign=link+copyright">
								<img src="images/developer.svg" alt="" />
							</a>
						</div>
					</Footer>
					<BackTop />
				</Layout>
			</Layout>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		siderCollapsed: state.general.siderCollapsed,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		siderToggle: (collapsed) => {
			dispatch(generalActions.siderToggle(collapsed));
		}
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTemplate);

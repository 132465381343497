import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Card, Checkbox, Col, Drawer, Form, Icon, Modal, Row, Spin } from "antd";

import moment from "moment";

import { permissionService, roleAndPermissionService } from "./../../redux/services";

const FormItem = Form.Item;

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading               : true,
			permissions             : [],
			permissionsSelected     : [],
			permissionsCheckAll     : [],
			permissionsIndeterminate: [],
			id                      : 0,
			item                    : {},
		};
	}

	fieldOptions = {
		name             : {
			label: "Nome",
		},
		description      : {
			label: "Descrição",
		},
		permissions_count: {
			label: "Permissões",
		},
		users_count      : {
			label: "Usuários",
		},
		created_at       : {
			label: "Criação",
		},
		updated_at       : {
			label: "Última atualização",
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		let item = {};

		roleAndPermissionService.show({id})
		.then((response) => {
			item = response.data.data;

			return permissionService.getAutocomplete({
				orderBy: "group:asc|order:asc"
			});
		})
		.then((response) => {
			let permissions = response.data.data;

			let newPermissionsSelected      = item.permissions.map(permission => permission.id);
			let newPermissionsCheckAll      = [];
			let newPermissionsIndeterminate = [];

			permissions.forEach(permissionGroup => {
				let groupTotal         = permissionGroup.permissions.length;
				let groupTotalSelected = 0;

				permissionGroup.permissions.forEach(permission => {
					let permissionIndex = newPermissionsSelected.indexOf(permission.id);

					if( permissionIndex !== -1 )
					{
						groupTotalSelected++;
					}
				});

				if( groupTotal === groupTotalSelected )
				{
					newPermissionsCheckAll.push(permissionGroup.key);
				}
				else if( groupTotalSelected > 0 )
				{
					newPermissionsIndeterminate.push(permissionGroup.key);
				}
			});

			this.setState({
				isLoading               : false,
				permissionsSelected     : newPermissionsSelected,
				permissionsCheckAll     : newPermissionsCheckAll,
				permissionsIndeterminate: newPermissionsIndeterminate,
				permissions             : permissions,
				item                    : item,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			permissions             : [],
			permissionsSelected     : [],
			permissionsCheckAll     : [],
			permissionsIndeterminate: [],
			item                    : {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const {visible}                                                                                              = this.props;
		const {id, isLoading, item, permissions, permissionsSelected, permissionsCheckAll, permissionsIndeterminate} = this.state;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={1150}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.name.label}>
										{item.name}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.description.label}>
										{item.description}
									</FormItem>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.permissions_count.label}>
										{item.permissions_count}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.users_count.label}>
										{item.users_count}
									</FormItem>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.created_at.label}>
										{moment(item.created_at).calendar()}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.updated_at.label}>
										{moment(item.updated_at).calendar()}
									</FormItem>
								</Col>
							</Row>
							<h3>Permissões</h3>
							<Row gutter={16} type="flex">
								{permissions.map((permissionGroup, key) => (
									<Col xs={24} sm={6} key={key} style={{marginBottom: 16}}>
										<Card title={permissionGroup.name} style={{height: "100%"}}>
											<Checkbox disabled indeterminate={permissionsIndeterminate.indexOf(permissionGroup.key) !== -1} checked={permissionsCheckAll.indexOf(permissionGroup.key) !== -1} style={{marginBottom: 20}}>Controle total</Checkbox>
											{permissionGroup.permissions.map((permission, key_p) => (
												<div key={key_p} style={{marginBottom: 5}}>
													<Checkbox disabled checked={permissionsSelected.indexOf(permission.id) !== -1}>{permission.name}</Checkbox>
												</div>
											))}
										</Card>
									</Col>
								))}
							</Row>
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Show;

import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Card, Checkbox, Col, Drawer, Form, Icon, Modal, Row, Spin, Switch } from "antd";

import moment from "moment";

import { roleAndPermissionService, userService } from "./../../redux/services";

const FormItem = Form.Item;

const config = {
	externalName: "usuário",
};

class Show extends Component {
	static propTypes = {
		visible : PropTypes.bool.isRequired,
		onClose : PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading    : true,
			roles        : [],
			rolesSelected: [],
			id           : 0,
			item         : {},
		};
	}

	fieldOptions = {
		first_name: {
			label: "Nome",
		},
		last_name : {
			label: "Sobrenome",
		},
		email     : {
			label: "E-mail",
		},
		is_active : {
			label: "Ativo",
		},
		created_at: {
			label: "Criação",
		},
		updated_at: {
			label: "Última atualização",
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		let item = {};

		userService.show({id})
		.then((response) => {
			item = response.data.data;

			return roleAndPermissionService.getAutocomplete({
				orderBy: "system:desc|name:asc",
			});
		})
		.then((response) => {
			this.setState({
				isLoading    : false,
				roles        : response.data.data,
				rolesSelected: item.roles.map(role => role.id),
				item         : item,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			roles        : [],
			rolesSelected: [],
			item         : {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const {visible}                                   = this.props;
		const {id, isLoading, item, roles, rolesSelected} = this.state;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={500}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar ${this.props.external ? config.externalName : "registro"} [${id}]`}</div>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.first_name.label}>
										{item.first_name}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.last_name.label}>
										{item.last_name}
									</FormItem>
								</Col>
							</Row>
							<FormItem label={this.fieldOptions.email.label} hasFeedback>
								{item.email}
							</FormItem>
							<FormItem label={this.fieldOptions.is_active.label}>
								<Switch disabled checked={item.is_active} />
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.created_at.label}>
										{moment(item.created_at).calendar()}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.updated_at.label}>
										{moment(item.updated_at).calendar()}
									</FormItem>
								</Col>
							</Row>
							<h3>Papéis</h3>
							{roles.map((role, key) => (
								<Card key={key} style={{marginBottom: 15}} title={<Checkbox disabled checked={rolesSelected.indexOf(role.id) !== -1}>{role.name}</Checkbox>}>
									{role.description}
								</Card>
							))}
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Show;

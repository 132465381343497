import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Col, Drawer, Form, Icon, Modal, Row, Spin, Switch, Tabs, Upload } from "antd";

import moment from "moment";

import { machineImplementService } from "./../../redux/services";

import TabContents from "./contents";
import { EMVideos, EMContents } from "../../components";

const FormItem = Form.Item;

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading          : true,
			id                 : 0,
			item               : {},
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imageList          : {
				images: [],
			},
		};
	}

	fieldOptions = {
		type_id     : {
			label: "Tipo",
		},
		name           : {
			label: "Nome",
		},
		pattern    : {
			label: "Modelo",
		},
		brand: {
			label    : "Marca",
			decorator: {},
		},
		is_highlighted : {
			label: "Destaque",
		},
		is_quote_now      : {
			label    : "Cotar agora",
		},
		is_active      : {
			label: "Ativo",
		},
		created_at     : {
			label: "Criação",
		},
		updated_at     : {
			label: "Última atualização",
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		machineImplementService.show({id})
		.then((response) => {
			let item       = response.data.data;
			let imagesList = {
				images: [],
			};

			if( item.images.length )
			{

				item.images = item.images.filter(item => item.type === "image")

				item.images.forEach(image => {
					imagesList.images.push({
						uid   : image.id,
						name  : image.file.split("/").pop(),
						status: "done",
						url   : image.file,
						// Has id, is api image
						id    : image.id,
					});
				});
			}

			this.setState({
				isLoading: false,
				item     : item,
				imageList: imagesList,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			item     : {},
			imageList: {
				images: [],
			},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	renderImages(type) {
		const imageList = this.state.imageList[type];

		if( !imageList.length )
		{
			return (
				<div className="clearfix">
					Sem imagem
				</div>
			);
		}

		return (
			<div className="media-images-wrap">
				<Upload
					listType="picture-card"
					className="media-images-uploader media-images-view"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
				/>
			</div>
		);
	}

	render() {
		const {visible}                                                     = this.props;
		const {id, isLoading, item, imagePreviewVisible, imagePreviewImage} = this.state;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={1200}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label="Imagens">
								{this.renderImages("images")}
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} md={12}>
									<FormItem label={this.fieldOptions.type_id.label} hasFeedback>
										{item.type && item.type.name}
									</FormItem>
								</Col>
							</Row>
							<Row gutter={16}>
								{/* <Col xs={24} md={12}>
									<FormItem label={this.fieldOptions.name.label}>
										{item.name}
									</FormItem>
								</Col> */}
								<Col xs={24} md={12}>
									<FormItem label={this.fieldOptions.pattern.label}>
										{item.pattern}
									</FormItem>
								</Col>
								<Col xs={24} md={12}>
									<FormItem label={this.fieldOptions.brand.label} hasFeedback>
										{item.brand}
									</FormItem>
								</Col>
							</Row>
							<Row gutter={16}>
							</Row>
							<Row gutter={16}>
							<Col xs={8}>
									<FormItem label={this.fieldOptions.is_active.label}>
										<Switch disabled checked={item.is_active} />
									</FormItem>
								</Col>
								<Col xs={8}>
									<FormItem label={this.fieldOptions.is_highlighted.label}>
										<Switch disabled checked={item.is_highlighted} />
									</FormItem>
								</Col>
								<Col xs={8}>
									<FormItem label={this.fieldOptions.is_quote_now.label}>
										<Switch disabled checked={item.is_quote_now} />
									</FormItem>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.created_at.label}>
										{moment(item.created_at).calendar()}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.updated_at.label}>
										{moment(item.updated_at).calendar()}
									</FormItem>
								</Col>
							</Row>
							<Tabs defaultActiveKey="contents" style={{marginBottom: 24}}>
								<Tabs.TabPane tab="Conteúdos" key="contents">
									<EMContents item={item} type="machines-implements" />
								</Tabs.TabPane>
								<Tabs.TabPane tab="Videos" key="videos">
									<EMVideos item={item} type="machines-implements" />
								</Tabs.TabPane>
							</Tabs>
						</div>
					)}
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Show;

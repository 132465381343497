import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Modal,
  Row,
  Spin,
  Switch,
} from "antd";
import * as PropTypes from "prop-types";
import React, { Component } from "react";

import moment from "moment";

import { customerService } from "./../../redux/services";

const FormItem = Form.Item;

const config = {
  externalName: "usuário",
};

class Show extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      id: 0,
      item: {},
    };
  }

  fieldOptions = {
    first_name: {
      label: "Nome",
    },
    last_name: {
      label: "Sobrenome",
    },
    email: {
      label: "E-mail",
    },
    cellphone: {
      label: "Telefone/WhatsApp",
    },
    activity: {
      label: "Atividade",
    },
    cities: {
      label: "Região em que exerce suas atividades profissionais",
    },
    is_active: {
      label: "Ativo",
    },
    created_at: {
      label: "Criação",
    },
    updated_at: {
      label: "Última atualização",
    },
    principal_activity: {
      label: "Atividade principal",
    },
    acreage: {
      label: "Area Cultivada",
    },
    production_system: {
      label: "Sistema de produção",
    },
    farm_area: {
      label: "Area da fazenda",
    },
  };

  onOpen = (id) => {
    this.setState({
      isLoading: true,
      id: id,
      item: {},
    });

    customerService
      .show({ id })
      .then((response) => {
        this.setState({
          isLoading: false,
          item: response.data.data,
        });
      })
      .catch((data) => {
        this.setState({
          isLoading: false,
        });

        Modal.error({
          title: "Ocorreu um erro!",
          content: data.error_message,
          onOk: () => {
            // Force close
            return this.onClose();
          },
        });
      });
  };

  resetFields = () => {
    this.setState({
      item: {},
    });
  };

  onClose = () => {
    this.props.onClose();
  };

  _renderCities = () => {
    const { item } = this.state;

    if (!item.cities || !item.cities.length) {
      return <></>;
    }

    return (
      <FormItem label={this.fieldOptions.cities.label}>
        {item.cities.map((item) => (
          <div>{`${item.name}-${item.state.abbr}`}</div>
        ))}
      </FormItem>
    );
  };

  render() {
    const { visible } = this.props;
    const { id, isLoading, item } = this.state;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={500}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar ${this.props.external ? config.externalName : "registro"} [${id}]`}</div>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.first_name.label}>
										{item.first_name}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.last_name.label}>
										{item.last_name}
									</FormItem>
								</Col>
							</Row>
							<FormItem label={this.fieldOptions.email.label} hasFeedback>
								{item.email}
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.cellphone.label}>
										{item.customer && item.customer.cellphone}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.activity.label}>
										{item.customer && item.customer.activity && item.customer.activity.name}
									</FormItem>
								</Col>
							</Row>
							{this._renderCities()}
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.principal_activity.label}>
										{item?.item_relacao?.data?.length && item.item_relacao?.data[0]?.principal_activity}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.acreage.label}>
										{item.item_relacao.data?.length && item.item_relacao?.data[0]?.acreage}
									</FormItem>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.production_system.label}>
										{item.item_relacao.data?.length && item.item_relacao?.data[0]?.production_system}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.farm_area.label}>
										{item.item_relacao.data?.length && item.item_relacao?.data[0]?.farm_area}
									</FormItem>
								</Col>
							</Row>
							<FormItem label={this.fieldOptions.is_active.label}>
								<Switch disabled checked={item.is_active} />
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.created_at.label}>
										{moment(item.created_at).calendar()}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.updated_at.label}>
										{moment(item.updated_at).calendar()}
									</FormItem>
								</Col>
							</Row>
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Show;

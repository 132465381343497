import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Checkbox, DatePicker, Form, Modal } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { pestService } from "./../../redux/services";

const FormItem = Form.Item;

class Export extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isSending: false,
			allPeriod: true,
		};
	}

	fieldOptions = {
		date_range: {
			label    : "Período",
			decorator: {
				initialValue: "",
			},
		},
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			allPeriod: true
		});
	};

	onClose = () => {
		if( this.state.isSending )
		{
			return false;
		}

		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	exportOnConfirm = () => {
		this.props.form.validateFields((error, values) => {
			if( !error )
			{
				const {allPeriod} = this.state;

				if( !values.date_range && !allPeriod )
				{
					Modal.error({
						title  : "Ocorreu um erro!",
						content: "Informe o período desejado.",
					});

					return false;
				}

				this.setState({
					isSending: true
				});

				let data = {};

				if( values.date_range )
				{
					data.date_start = values.date_range[0].startOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
					data.date_end   = values.date_range[1].endOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
				}

				pestService.exportItens(data)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					window.open(response.data.file_url, "_blank");

					// Reset fields
					this.resetFields();

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	render() {
		const {visible, form}        = this.props;
		const {isSending, allPeriod} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Modal
				visible={visible}
				title="Exportar dados"
				destroyOnClose={true}
				maskClosable={false}
				width={350}
				okText={isSending ? "Exportando" : "Exportar"}
				cancelButtonProps={{disabled: isSending}}
				onCancel={this.onClose}
				onOk={this.exportOnConfirm}
				confirmLoading={isSending}>
				<Form layout="vertical">
					<FormItem label={this.fieldOptions.date_range.label}>
						{getFieldDecorator("date_range", this.fieldOptions.date_range.decorator)(
							<DatePicker.RangePicker
								disabled={isSending || allPeriod}
								format="DD/MM/YYYY"
							/>
						)}
					</FormItem>
					<Checkbox disabled={isSending} onChange={(e) => this.setState({allPeriod: e.target.checked})} checked={allPeriod}>Todo o período</Checkbox>
				</Form>
			</Modal>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Export);

import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
    toolbar: [
        [{header: [1, 2, false]}],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            {list: "ordered"},
            {list: "bullet"},
            {indent: "-1"},
            {indent: "+1"},
        ],
        ["link", "image"],
        ["clean"],
    ],
    clipboard: {
        matchVisual: false,
    },
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
];

const TextEditor = ({ value, onChange, placeholder }) => {
    return (
        <>
            <ReactQuill
                style={{backgroundColor: 'white'}}
                value={value || ""}
                modules={modules}
                formats={formats}
                onChange={onChange}
                placeholder={placeholder}
            />
        </>
    );
};

export default TextEditor;


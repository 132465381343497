import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Avatar, Button, Col, Drawer, Form, Icon, Modal, Row, Rate, Spin, Tabs } from "antd";

import moment from "moment";

import { ratingService } from "./../../redux/services";

import TabLikes from "./likes";

const FormItem = Form.Item;

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			id       : 0,
			item     : {},
		};
	}

	fieldOptions = {
		id        : {
			label: "ID",
		},
		star      : {
			label: "Nota",
		},
		message   : {
			label: "Mensagem",
		},
		created_at: {
			label: "Criação",
		},
		updated_at: {
			label: "Última atualização",
		},
		user      : {
			label: "Usuário",
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		ratingService.show({id})
		.then((response) => {
			this.setState({
				isLoading: false,
				item     : response.data.data,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	itemType = (val) => {
		if( val === "defensive" )
		{
			return "Defensivo";
		}
		else if( val === "fertilizer" )
		{
			return "Fertilizante/Corretivo";
		}
		else if( val === "leaf_fertilizer" )
		{
			return "Fertilizante foliar";
		}
	};

	render() {
		const {visible}             = this.props;
		const {id, isLoading, item} = this.state;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={600}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label={this.fieldOptions.star.label}>
								<Rate disabled defaultValue={item.star} />
							</FormItem>
							<FormItem label={this.fieldOptions.message.label}>
								{item.message}
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.created_at.label}>
										{moment(item.created_at).calendar()}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.updated_at.label}>
										{moment(item.updated_at).calendar()}
									</FormItem>
								</Col>
							</Row>
							<FormItem label={this.fieldOptions.user.label}>
								{item.user && (
									<Row gutter={16}>
										<Col xs={24} sm={4}>
											<p>{item.user.avatar ? <Avatar size={64} src={item.user.avatar_sizes.admin_listing} /> : <Avatar size={64} icon="user" />}</p>
										</Col>
										<Col xs={24} sm={20}>
											{item.user.name}<br />
											<b>Celular:</b> {item.user.customer.cellphone}<br />
											<b>E-mail:</b> {item.user.email}<br />
											<b>Atividade:</b> {item.user.customer.activity.name}
										</Col>
									</Row>
								)}
							</FormItem>
							<h3>Informações do item avaliado</h3>
							{item.item && (
								<div>
									{item.item.name}<br />
									<b>Tipo:</b> {this.itemType(item.rating_type)}<br />
									<b>ID:</b> {item.rating_id}<br />
									{item.item.culture && (
										<Fragment>
											<b>Cultura:</b> {item.item.culture.name}<br />
										</Fragment>
									)}
									<b>Ativo:</b> {item.item.is_active ? "Sim" : "Não"}<br />
								</div>
							)}
							<br />
							<Tabs defaultActiveKey="likes" style={{marginBottom: 24}}>
								<Tabs.TabPane tab={`Curtidas (${item.likes_count})`} key="likes">
									<TabLikes items={item.likes ? item.likes : []} />
								</Tabs.TabPane>
							</Tabs>
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Show;

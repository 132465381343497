import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Card, Checkbox, Col, Drawer, Form, Icon, Input, message, Modal, Row, Spin } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { permissionService, roleAndPermissionService } from "./../../redux/services";

const FormItem = Form.Item;

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading               : true,
			isSending               : false,
			permissions             : [],
			permissionsSelected     : [],
			permissionsCheckAll     : [],
			permissionsIndeterminate: [],
			id                      : 0,
		};
	}

	fieldOptions = {
		name       : {
			label    : "Nome",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		description: {
			label    : "Descrição",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
		});

		let item = {};

		roleAndPermissionService.show({id})
		.then((response) => {
			item = response.data.data;

			return permissionService.getAutocomplete({
				orderBy: "group:asc|order:asc"
			});
		})
		.then((response) => {
			let permissions = response.data.data;

			this.setState({
				isLoading  : false,
				permissions: permissions,
			});

			// Fill form
			this.fillForm(item, permissions);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data, permissions) => {
		this.props.form.setFieldsValue({
			name       : data.name,
			description: data.description,
		});

		let newPermissionsSelected      = data.permissions.map(permission => permission.id);
		let newPermissionsCheckAll      = [];
		let newPermissionsIndeterminate = [];

		permissions.forEach(permissionGroup => {
			let groupTotal         = permissionGroup.permissions.length;
			let groupTotalSelected = 0;

			permissionGroup.permissions.forEach(permission => {
				let permissionIndex = newPermissionsSelected.indexOf(permission.id);

				if( permissionIndex !== -1 )
				{
					groupTotalSelected++;
				}
			});

			if( groupTotal === groupTotalSelected )
			{
				newPermissionsCheckAll.push(permissionGroup.key);
			}
			else if( groupTotalSelected > 0 )
			{
				newPermissionsIndeterminate.push(permissionGroup.key);
			}
		});

		this.setState({
			permissionsSelected     : newPermissionsSelected,
			permissionsCheckAll     : newPermissionsCheckAll,
			permissionsIndeterminate: newPermissionsIndeterminate,
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			permissions             : [],
			permissionsSelected     : [],
			permissionsCheckAll     : [],
			permissionsIndeterminate: [],
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				const {permissionsSelected} = this.state;

				if( !permissionsSelected.length )
				{
					Modal.error({
						title  : "Ocorreu um erro!",
						content: "Selecione pelo menos uma permissão.",
					});

					return false;
				}

				this.setState({
					isSending: true,
				});

				const {id} = this.state;

				// ID
				values.id = id;

				// Permissions
				values.permissions = permissionsSelected;

				roleAndPermissionService.edit(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro atualizado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	onChangePermission = (id, groupKey, checked) => {
		const {permissions, permissionsSelected, permissionsCheckAll, permissionsIndeterminate} = this.state;

		let newPermissionsCheckAll      = [...permissionsCheckAll];
		let newPermissionsIndeterminate = [...permissionsIndeterminate];
		let newPermissionsSelected      = [...permissionsSelected];
		const indexSelected             = newPermissionsSelected.indexOf(id);
		const groupIndex                = permissions.findIndex(item => item.key === groupKey);
		const groupIndexCheckAll        = newPermissionsCheckAll.indexOf(groupKey);
		const groupIndexIndeterminate   = newPermissionsIndeterminate.indexOf(groupKey);

		if( checked )
		{
			if( indexSelected === -1 )
			{
				newPermissionsSelected.push(id);
			}
		}
		else
		{
			if( indexSelected !== -1 )
			{
				newPermissionsSelected.splice(indexSelected, 1);
			}
		}

		let groupTotal         = permissions[groupIndex].permissions.length;
		let groupTotalSelected = 0;

		permissions[groupIndex].permissions.forEach(permission => {
			let permissionIndex = newPermissionsSelected.indexOf(permission.id);

			if( permissionIndex !== -1 )
			{
				groupTotalSelected++;
			}
		});

		if( groupTotal === groupTotalSelected )
		{
			if( groupIndexCheckAll === -1 )
			{
				newPermissionsCheckAll.push(groupKey);
			}

			if( groupIndexIndeterminate !== -1 )
			{
				newPermissionsIndeterminate.splice(groupIndexIndeterminate, 1);
			}
		}
		else
		{
			if( groupIndexCheckAll !== -1 )
			{
				newPermissionsCheckAll.splice(groupIndexCheckAll, 1);
			}

			if( groupTotalSelected > 0 )
			{
				if( groupIndexIndeterminate === -1 )
				{
					newPermissionsIndeterminate.push(groupKey);
				}
			}
			else
			{
				if( groupIndexIndeterminate !== -1 )
				{
					newPermissionsIndeterminate.splice(groupIndexIndeterminate, 1);
				}
			}
		}

		this.setState({
			permissionsSelected     : newPermissionsSelected,
			permissionsCheckAll     : newPermissionsCheckAll,
			permissionsIndeterminate: newPermissionsIndeterminate,
		});
	};

	onChangeAllPermissions = (key, checked) => {
		const {permissions, permissionsSelected, permissionsCheckAll, permissionsIndeterminate} = this.state;

		let newPermissionsCheckAll      = [...permissionsCheckAll];
		let newPermissionsSelected      = [...permissionsSelected];
		let newPermissionsIndeterminate = [...permissionsIndeterminate];
		const indexCheckAll             = newPermissionsCheckAll.indexOf(key);
		const indexIndeterminate        = newPermissionsIndeterminate.indexOf(key);
		const groupIndex                = permissions.findIndex(item => item.key === key);

		if( checked )
		{
			if( indexCheckAll === -1 )
			{
				newPermissionsCheckAll.push(key);
			}

			if( indexIndeterminate !== -1 )
			{
				newPermissionsIndeterminate.splice(indexIndeterminate, 1);
			}

			permissions[groupIndex].permissions.forEach(permission => {
				let permissionIndex = newPermissionsSelected.indexOf(permission.id);

				if( permissionIndex === -1 )
				{
					newPermissionsSelected.push(permission.id);
				}
			});
		}
		else
		{
			if( indexCheckAll !== -1 )
			{
				newPermissionsCheckAll.splice(indexCheckAll, 1);
			}

			permissions[groupIndex].permissions.forEach(permission => {
				let permissionIndex = newPermissionsSelected.indexOf(permission.id);

				if( permissionIndex !== -1 )
				{
					newPermissionsSelected.splice(permissionIndex, 1);
				}
			});
		}

		this.setState({
			permissionsSelected     : newPermissionsSelected,
			permissionsCheckAll     : newPermissionsCheckAll,
			permissionsIndeterminate: newPermissionsIndeterminate,
		});
	};

	render() {
		const {visible, form}                                                                                             = this.props;
		const {id, isLoading, isSending, permissions, permissionsSelected, permissionsCheckAll, permissionsIndeterminate} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={1150}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.name.label} hasFeedback>
										{getFieldDecorator("name", this.fieldOptions.name.decorator)(
											<Input />
										)}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.description.label} hasFeedback>
										{getFieldDecorator("description", this.fieldOptions.description.decorator)(
											<Input />
										)}
									</FormItem>
								</Col>
							</Row>
							<h3>Permissões</h3>
							<Row gutter={16} type="flex">
								{permissions.map((permissionGroup, key) => (
									<Col xs={24} sm={6} key={key} style={{marginBottom: 16}}>
										<Card title={permissionGroup.name} style={{height: "100%"}}>
											<Checkbox onChange={(e) => this.onChangeAllPermissions(permissionGroup.key, e.target.checked)} indeterminate={permissionsIndeterminate.indexOf(permissionGroup.key) !== -1} checked={permissionsCheckAll.indexOf(permissionGroup.key) !== -1} style={{marginBottom: 20}}>Controle total</Checkbox>
											{permissionGroup.permissions.map((permission, key_p) => (
												<div key={key_p} style={{marginBottom: 5}}>
													<Checkbox onChange={(e) => this.onChangePermission(permission.id, permissionGroup.key, e.target.checked)} checked={permissionsSelected.indexOf(permission.id) !== -1}>{permission.name}</Checkbox>
												</div>
											))}
										</Card>
									</Col>
								))}
							</Row>
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Edit);

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import * as Sentry from "@sentry/react";

import "moment/locale/pt-br";

import { persistor, store } from "./redux/store/configureStore";

import Main from "./screens/Main";
if(process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: "https://fdb58c180f2ea9f6d67f183fd8597bae@o4506904556535808.ingest.us.sentry.io/4506904557780992",
		integrations: [
			Sentry.replayIntegration({
				// Additional SDK configuration goes in here, for example:
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		// Session Replay
		replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
class App extends Component {

	render() {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Main />
				</PersistGate>
			</Provider>
		);
	}
}

ReactDOM.render(<App />, document.getElementById("root"));

import React, { Component } from "react";
import { Button, Col, Form, Icon, Input, message, Modal, Row, Spin } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { settingService } from "./../../redux/services";

import DefaultTemplate from "./../../templates/defaultTemplate";

const FormItem = Form.Item;

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
		};
	}

	componentDidMount() {
		settingService.getGeneral()
		.then((response) => {
			this.setState({
				isLoading: false,
			});

			// Fill form
			this.fillForm(response.data.data);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
			});
		});
	}

	fieldOptions = {
		app_url_android: {
			label    : "Url do app android",
			decorator: {
				rules: [
					{type: "url", message: "Informe uma URL válida."},
				],
			},
		},
		app_url_ios    : {
			label    : "Url do app iOS",
			decorator: {
				rules: [
					{type: "url", message: "Informe uma URL válida."},
				],
			},
		},
		whatsapp_contact       : {
			label    : "Whatsapp",
			decorator: {
				rules: [
					{type: "url", message: "Informe uma URL válida."},
				],
			},
		},
		url_site       : {
			label    : "Site",
			decorator: {
				rules: [
					{type: "url", message: "Informe uma URL válida."},
				],
			},
		},
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			app_url_android : data.app_url_android,
			app_url_ios     : data.app_url_ios,
			whatsapp_contact: data.whatsapp_contact,
			url_site        : data.url_site,
		});
	};

	resetValidations = () => {
		const values = this.props.form.getFieldsValue();
		let fields   = {};

		for( let key in this.fieldOptions )
		{
			if( this.fieldOptions[key] && values.hasOwnProperty(key) )
			{
				fields = {
					...fields,
					[key]: {
						value: values[key],
					}
				};
			}
		}

		this.props.form.setFields(fields);
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				settingService.updateGeneral(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset validations
					this.resetValidations();

					// Success message
					message.success("Configuração atualizada.");
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	render() {
		const {form}                 = this.props;
		const {isLoading, isSending} = this.state;

		const {getFieldDecorator} = form;

		return (
			<DefaultTemplate>
				<div className="page-content" key="1">
					<h1 className="page-title">Configurações gerais</h1>
					<Form layout="vertical" onSubmit={this.onSubmit}>
						{isLoading ? (
							<div className="text-center" style={{padding: 20}}>
								<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
							</div>
						) : (
							<Row gutter={16}>
								<Col xs={24} sm={12} lg={8} xl={6}>
									<FormItem label={this.fieldOptions.app_url_android.label} hasFeedback>
										{getFieldDecorator("app_url_android", this.fieldOptions.app_url_android.decorator)(
											<Input />
										)}
									</FormItem>
									<FormItem label={this.fieldOptions.app_url_ios.label} hasFeedback extra="">
										{getFieldDecorator("app_url_ios", this.fieldOptions.app_url_ios.decorator)(
											<Input />
										)}
									</FormItem>
									<FormItem label={this.fieldOptions.whatsapp_contact.label} hasFeedback extra="">
										{getFieldDecorator("whatsapp_contact", this.fieldOptions.whatsapp_contact.decorator)(
											<Input />
										)}
									</FormItem>
									<FormItem label={this.fieldOptions.url_site.label} hasFeedback extra="">
										{getFieldDecorator("url_site", this.fieldOptions.url_site.decorator)(
											<Input />
										)}
									</FormItem>
									<Button type="primary" htmlType="submit" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
								</Col>
							</Row>
						)}
					</Form>
				</div>
			</DefaultTemplate>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Index);

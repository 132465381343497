import {Button, Drawer, Form, Icon, Input, message, Modal, Select, Switch, Upload,} from "antd";
import * as PropTypes from "prop-types";
import React, {Component} from "react";
import "react-quill/dist/quill.snow.css";

import {API_ERRO_TYPE_VALIDATION} from "./../../config/general";

import {FORM_VALIDATION_MESSAGES} from "./../../config/lang";

import {publicationService} from "./../../redux/services";
import TextEditor from "../../components/TextEditor";

const FormItem = Form.Item;

const config = {
    images: {
        file: {
            maxSize: 4,
            maxFiles: 1,
            extensions: ["jpg", "png"],
            type: ["image/jpeg", "image/png"],
        },
        files: {
            maxSize: 4,
            maxFiles: 0,
            extensions: ["jpg", "png"],
            type: ["image/jpeg", "image/png"],
        },
        ads: {
            maxSize: 4,
            maxFiles: 1,
            extensions: ["jpg", "png"],
            type: ["image/jpeg", "image/png"],
        },
    },
};

class Create extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            text: "",
            isSending: false,
            // Images
            imagePreviewVisible: false,
            imagePreviewImage: "",
            imageAdsPreviewVisible: false,
            imageAdsPreviewImage: "",
            activePublicationType: {},
            file: {},
            publicationTypes: [],
            images: {
                file: [],
                files: [],
            },
            imageAdsList: {
                images: [],
            },
            imageAdsListDeleted: {
                images: [],
            },
        };
    }
    fieldOptionsModel = {
        title: {
            label: "Título",
            decorator: {
                rules: [],
            },
        },
        text: {
            label: "Texto",
            decorator: {
                initialValue: "",
                rules: [],
            },
        },
        author: {
            label: "Autor",
            decorator: {
                rules: [],
            },
        },
        link: {
            label: "Link",
            decorator: {
                rules: [],
            },
        },
    };
    fieldOptions = {
        publication_type: {
            label: "Tipo",
            decorator: {
                rules: [],
            },
        },
        is_active: {
            label: "Ativo",
            decorator: {
                valuePropName: "checked",
                initialValue: true,
            },
        },
    };

    onOpen = (publicationTypes) => {
        this.setState({
            text: "",
            isSending: false,
            // Images
            imagePreviewVisible: false,
            imagePreviewImage: "",
            imageAdsPreviewVisible: false,
            imageAdsPreviewImage: "",
            activePublicationType: {},
            file: {},
            images: {
                file: [],
                files: [],
            },
            imageAdsList: {
                images: [],
            },
            imageAdsListDeleted: {
                images: [],
            },
            publicationTypes
        })
        const keys = Object.keys(publicationTypes[0]);
        for(const type of publicationTypes){
            for(const key of keys){
                if(this.fieldOptionsModel[key]){
                    if(!this.fieldOptions[type.id]){
                        this.fieldOptions[type.id] = {};
                    }
                    this.fieldOptions[type.id][key] = JSON.parse(JSON.stringify(this.fieldOptionsModel[key]));
                    if(type[key] === 1) {
                        this.fieldOptions[type.id][key].decorator.rules.push({required: true, message: "Campo obrigatório."});
                    }
                }
            }
        }
    };

    resetFields = () => {
        this.props.form.resetFields();

        this.setState({
            images: {
                file: [],
                files: [],
            },
            imageAdsList: {
                images: [],
            },
        });
    };

    onClose = () => {
        // Reset fields
        this.resetFields();

        // Callback
        this.props.onClose();
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                const {images, imageAdsList, file} = this.state;

                this.setState({
                    isSending: true,
                });

                // File
                const fileKeys = Object.keys(file);
                for(const fileKey of fileKeys){
                    const k = fileKey === 'mp3' ? 'audio' : fileKey === 'mp4' ? 'video' : fileKey;
                    values[k] = file[fileKey].length ? file[fileKey][0] : null;
                }

                const imageKeys =  Object.keys(images);
                for(const imageKey of imageKeys){
                    if(!['file', 'files'].includes(imageKey)) {
                        debugger;
                        if(['thumbnail', 'ads'].includes(imageKey)){
                            values[imageKey] = images[imageKey].length ? images[imageKey][0] : null;
                        } else {
                            values[imageKey] = images[imageKey].length ? images[imageKey] : null;
                        }
                    }
                }

                // Images ads
                if (imageAdsList.images.length) {
                    values.ads = imageAdsList.images;
                }

                publicationService
                    .create(values)
                    .then((response) => {
                        this.setState({
                            isSending: false,
                        });

                        // Reset fields
                        this.resetFields();

                        // Success message
                        message.success("Registro cadastrado com sucesso.");

                        // Callback
                        this.props.onComplete();
                    })
                    .catch((data) => {
                        this.setState({
                            isSending: false,
                        });

                        // if validation error
                        if (data.error_type === API_ERRO_TYPE_VALIDATION) {
                            let hasFieldsErrors = false;

                            for (let key in data.error_errors) {
                                if (
                                    data.error_errors[key] &&
                                    this.fieldOptions[key]
                                ) {
                                    this.props.form.setFields({
                                        [key]: {
                                            value: values[key],
                                            errors: [new Error(data.error_errors[key])],
                                        },
                                    });

                                    hasFieldsErrors = true;
                                }
                            }

                            if (!hasFieldsErrors) {
                                Modal.error({
                                    title: "Ocorreu um erro!",
                                    content: data.error_message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: "Ocorreu um erro!",
                                content: data.error_message,
                            });
                        }
                    });
            }
        });
    };

    onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

    onImagePreview = (type, file) => {
        this.setState({
            imagePreviewImage: file.url,
            imagePreviewVisible: true,
        });
    };

    onImageRemove = (type, file) => {
        let imagesNew = [...this.state.images[type]];

        const index = imagesNew.findIndex((item) => file.uid === item.uid);

        if (index !== -1) {
            imagesNew.splice(index, 1);

            this.setState((state) => ({
                images: {
                    ...state.images,
                    [type]: imagesNew,
                },
            }));
        }
    };

    onImageAdsPreviewClose = () =>
        this.setState({imageAdsPreviewVisible: false});

    onImageAdsPreview = (type, file) => {
        this.setState({
            imageAdsPreviewImage: file.url,
            imageAdsPreviewVisible: true,
        });
    };

    onImageAdsRemove = (type, file) => {
        let imagesAdsNew = [...this.state.imageAdsList[type]];
        let imageAdsListDeletedNew = [...this.state.imageAdsListDeleted[type]];

        const index = imagesAdsNew.findIndex((item) => file.uid === item.uid);

        if (index !== -1) {
            imagesAdsNew.splice(index, 1);

            // Has id
            if (file.id) {
                imageAdsListDeletedNew.push(file.id);
            }

            this.setState((state) => ({
                imageAdsList: {
                    ...state.imageAdsList,
                    [type]: imagesAdsNew,
                },
                imageAdsListDeleted: {
                    ...state.imageAdsListDeleted,
                    [type]: imageAdsListDeletedNew,
                },
            }));
        }
    };

    renderAds(type) {
        const imageAdsList = this.state.imageAdsList[type];
        const imageConfig = config.images["ads"];

        const uploadButton = (
            <div>
                <Icon type="plus"/>
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div className="media-images-wrap">
                <Upload
                    accept={`.${imageConfig.extensions.join(",.")}`}
                    listType="picture-card"
                    className="media-images-uploader"
                    fileList={imageAdsList}
                    onPreview={(file) => this.onImageAdsPreview(type, file)}
                    onRemove={(file) => this.onImageAdsRemove(type, file)}
                    beforeUpload={(file) => {
                        if (!imageConfig.type.includes(file.type)) {
                            message.error(
                                `Somente são aceitos arquivos ${imageConfig.extensions
                                    .join(", ")
                                    .toUpperCase()}!`
                            );

                            return false;
                        }

                        const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

                        if (!isValidSize) {
                            message.error(
                                `A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`
                            );

                            return false;
                        }

                        let reader = new FileReader();
                        reader.onload = (e) => {
                            let imagesAdsNew = [...this.state.imageAdsList[type]];

                            if (imagesAdsNew.length < imageConfig.maxFiles) {
                                // Base64
                                file.url = e.target.result;

                                imagesAdsNew.push(file);

                                this.setState((state) => ({
                                    imageAdsList: {
                                        ...state.imageAdsList,
                                        [type]: imagesAdsNew,
                                    },
                                }));
                            }
                        };

                        reader.readAsDataURL(file);

                        return false;
                    }}
                >
                    {imageAdsList.length >= imageConfig.maxFiles ? null : uploadButton}
                </Upload>
            </div>
        );
    }

    renderImages(type, name) {
        const images = this.state.images[type];
        const imageConfig = config.images[type];

        const uploadButton = (
            <div>
                <Icon type="plus"/>
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div className="media-images-wrap">
                <Upload
                    accept={`.${imageConfig.extensions.join(",.")}`}
                    listType="picture-card"
                    className="media-images-uploader"
                    fileList={images}
                    onPreview={(file) => this.onImagePreview(type, file)}
                    onRemove={(file) => this.onImageRemove(type, file)}
                    multiple={imageConfig.maxFiles === 0}
                    beforeUpload={(file) => {
                        if (!imageConfig.type.includes(file.type)) {
                            message.error(
                                `Somente são aceitos arquivos ${imageConfig.extensions
                                    .join(", ")
                                    .toUpperCase()}!`
                            );

                            return false;
                        }

                        const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

                        if (!isValidSize) {
                            message.error(
                                `A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`
                            );

                            return false;
                        }

                        let reader = new FileReader();
                        reader.onload = (e) => {
                            let imagesNew = [...this.state.images[type]];

                            if (imageConfig.maxFiles === 0 || imagesNew.length < imageConfig.maxFiles) {
                                // Base64
                                file.url = e.target.result;

                                imagesNew.push(file);

                                this.setState((state) => ({
                                    images: {
                                        ...state.images,
                                        [type]: imagesNew,
                                        [name]: imagesNew,
                                    },
                                }));
                            }
                        };

                        reader.readAsDataURL(file);

                        return false;
                    }}
                >
                    {imageConfig.maxFiles > 0 && images.length >= imageConfig.maxFiles ? null : uploadButton}
                </Upload>
            </div>
        );
    }


    // Button to upload PDF
    renderUpload(type) {
        const tiposAceitos = ['pdf', 'mp3', 'mp4'];
        if (!tiposAceitos.includes(type)) {
            return <>Formato <strong>{type}</strong> não encontrado. Formatos aceitos {tiposAceitos.join(', ')}</>
        }
        const file = this.state.file[type] || [];

        return (
            <Upload
                accept={`.${type.toLowerCase()}`}
                fileList={file}
                onRemove={(file) => {
                    this.setState(state => ({
                        file: {
                            ...state.file,
                            [type]: [],
                        },
                    }));
                }}
                beforeUpload={(file) => {
                    this.setState(state => ({
                        file: {
                            ...state.file,
                            [type]: [file],
                        },
                    }));

                    return false;
                }}>
                {file.length === 0 && <Button>
                    <Icon type="upload"/> Selecionar arquivo
                </Button>}
            </Upload>
        );
    }

    changePublicationType = (value) => {
        this.setState({
            activePublicationType: this.state.publicationTypes.find(publicationType => publicationType.id === value)
        });
    }

    render() {
        const {visible, form} = this.props;
        const {
            isSending,
            imagePreviewVisible,
            imagePreviewImage,
            imageAdsPreviewVisible,
            imageAdsPreviewImage,
            publicationTypes,
            activePublicationType
        } = this.state;

        const {getFieldDecorator} = form;

        return (
            <Drawer
                visible={visible}
                className="drawer-form"
                width={500}
                maskClosable={false}
                closable={false}
                keyboard={false}
                placement="right"
                onClose={this.onClose}
            >
                <Form layout="vertical" onSubmit={this.onSubmit}>
                    <div className="form-header">
                        <Button
                            className="btn-close"
                            onClick={this.onClose}
                            icon="close"
                            disabled={isSending}
                        />
                        <div className="ant-drawer-title">Inserir novo registro</div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-save"
                            icon="check"
                            loading={isSending}
                        >
                            Salvar
                        </Button>
                    </div>
                    <div className="form-body">
                        <FormItem label={this.fieldOptions.publication_type.label} hasFeedback>
                            {getFieldDecorator(
                                "publication_type",
                                this.fieldOptions.publication_type.decorator
                            )(
                                <Select
                                    onChange={this.changePublicationType}
                                >
                                    {publicationTypes && publicationTypes.map(item => (
                                        <Select.Option key={item.id} value={item.id}>{item.description}</Select.Option>
                                    ))}
                                </Select>
                            )}
                        </FormItem>
                        {activePublicationType.id && <>
                            {activePublicationType.author > 0 &&
                                <FormItem label={this.fieldOptions[activePublicationType.id.toString()].author.label} hasFeedback>
                                    {getFieldDecorator(
                                        "author",
                                        this.fieldOptions[activePublicationType.id.toString()].author.decorator
                                    )(<Input/>)}
                                </FormItem>
                            }
                            {activePublicationType.title > 0 &&
                                <FormItem label={this.fieldOptions[activePublicationType.id.toString()].title.label} hasFeedback>
                                    {getFieldDecorator(
                                        "title",
                                        this.fieldOptions[activePublicationType.id.toString()].title.decorator
                                    )(<Input/>)}
                                </FormItem>
                            }
                            {activePublicationType.text > 0 &&
                            <FormItem label={this.fieldOptions[activePublicationType.id.toString()].text.label} hasFeedback>
                                {getFieldDecorator(
                                    "text",
                                    this.fieldOptions[activePublicationType.id.toString()].text.decorator
                                )(
                                    <TextEditor/>
                                )}
                            </FormItem>
                            }
                            {activePublicationType.images > 0 &&
                                <FormItem label="Imagens">
                                    {this.renderImages("files", 'images')}
                                </FormItem>
                            }
                            {activePublicationType.thumbnail > 0 &&
                                <FormItem label="Thumbnail">
                                    {this.renderImages("file", 'thumbnail')}
                                </FormItem>
                            }
                            {activePublicationType.ads > 0 &&
                                <FormItem label="Ads">
                                    {this.renderAds("images")}
                                    <small>Dimensões: (940x780px)</small>
                                </FormItem>
                            }
                            {activePublicationType.banner_top > 0 &&
                                <FormItem label="Banner Topo">
                                    {this.renderImages("file", 'banner_top')}
                                </FormItem>
                            }
                            {activePublicationType.banner_foot > 0 &&
                                <FormItem label="Banner Rodapé">
                                    {this.renderImages("file", 'banner_foot')}
                                </FormItem>
                            }
                            {activePublicationType.pdf > 0 &&
                                <FormItem label="Pdf">
                                    {this.renderUpload("pdf")}
                                </FormItem>
                            }
                            {activePublicationType.video > 0 &&
                                <FormItem label="Vídeo">
                                    {this.renderUpload("mp4")}
                                </FormItem>
                            }
                            {activePublicationType.audio > 0 &&
                                <FormItem label="Áudio">
                                    {this.renderUpload("mp3")}
                                </FormItem>
                            }
                            {activePublicationType.link > 0 &&
                                <FormItem label={this.fieldOptions[activePublicationType.id.toString()].link.label} hasFeedback>
                                    {getFieldDecorator(
                                        "link",
                                        this.fieldOptions[activePublicationType.id.toString()].link.decorator
                                    )(<Input/>)}
                                </FormItem>
                            }
                            <FormItem label={this.fieldOptions.is_active.label}>
                                {getFieldDecorator(
                                    "is_active",
                                    this.fieldOptions.is_active.decorator
                                )(<Switch/>)}
                            </FormItem>
                        </>
                        }
                    </div>
                </Form>
                <Modal
                    className="modal-image"
                    visible={imagePreviewVisible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.onImagePreviewClose}
                >
                    <img src={imagePreviewImage}/>
                </Modal>
                <Modal
                    className="modal-image"
                    visible={imageAdsPreviewVisible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.onImageAdsPreviewClose}
                >
                    <img src={imageAdsPreviewImage}/>
                </Modal>
            </Drawer>
        );
    }
}

export default Form.create({
    validateMessages: FORM_VALIDATION_MESSAGES,
})(Create);

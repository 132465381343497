import React, { Component, Fragment } from 'react'
import { Button, Dropdown, Menu, Modal, Switch } from 'antd'

import { videosService } from '../../redux/services'

import EMPageListing from '../PageListing'

import ModalCreate from './create'
import ModalEdit from './edit'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      data: [],
      // Actions
      createModalVisible: false,
      editModalVisible: false,
      // Owner item
      ownerItem: {},
      imagePreviewVisible: false,
      imagePreviewImage: '',
    }
  }

  componentDidMount() {
    // Fecth all

    if (this.props.item) {
      this.fetchGetAll()
    }
  }

  init = (ownerItem) => {
    this.setState(
      {
        ownerItem: ownerItem,
      },
      () => {
        // Fecth all
        this.fetchGetAll(true)
      }
    )
  }

  menuItem = (item) => (
    <Menu className="actions-dropdown-menu">
      <Menu.Item key="edit">
        <a onClick={() => this.editOpen(item)}>
          <i className="fal fa-pen" />
          Editar
        </a>
      </Menu.Item>
      <Menu.Item key="delete" className="divider btn-delete">
        <a onClick={() => this.deleteConfirm(item)}>
          <i className="fal fa-trash" />
          Excluir
        </a>
      </Menu.Item>
    </Menu>
  )

  columns = () => {
    return [
      {
        title: 'ID',
        className: 'id',
        render: (item) => <span title={item.id}>{item.id}</span>,
      },
      {
        title: 'Nome',
        className: 'no-ellipsis',
        width: 200,
        render: (item) => item.name,
      },
      {
        title: 'Thumbnail',
        className: 'no-ellipsis',
        width: 150,
        render: (item) => {
          if (!item.file) {
            return null
          }

          return (
            <a onClick={() => this.onImagePreview(item.file)}>
              <img src={item.file} style={{ width: 50, height: 50 }} />
            </a>
          )
        },
      },
      {
        title: 'Url',
        className: 'no-padding-horizontal',
        render: (item) => (
          <a href={item.video} target="_blank" rel="noopener noreferrer">
            {item.video}
          </a>
        ),
      },
      // {
      // 	title    : "Ativo",
      // 	width    : 150,
      // 	render   : (item) => <Switch checked={item.is_active} onChange={(checked) => this.activateDeactivate(item, checked)} />
      // },
      {
        title: 'Ações',
        className: 'actions no-ellipsis',
        render: (item) => (
          <Dropdown
            overlay={this.menuItem(item)}
            className="actions-dropdown"
            placement="bottomRight"
            trigger={['click']}
          >
            <Button icon="more" />
          </Dropdown>
        ),
      },
    ]
  }

  fetchGetAll = (init = false) => {
    const { item, type } = this.props

    this.setState({
      isLoading: true,
    })

    const options = {
      type,
      id: item.id,
    }

    videosService
      .index(options)
      .then((response) => {
        this.setState((state) => ({
          isLoading: false,
          data: response.data.data,
        }))
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  activateDeactivate = ({ id }, activate) => {
    videosService
      .edit({ id, is_active: activate })
      .then((response) => {
        const newData = [...this.state.data]
        const index = newData.findIndex((item) => id === item.id)

        if (index !== -1) {
          const item = newData[index]

          newData.splice(index, 1, {
            ...item,
            is_active: response.data.data.is_active,
          })

          this.setState({
            data: newData,
          })
        }
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: data.error_message,
        })
      })
  }

  /**
   * Create
   */
  createOpen = () => {
    this.setState({ createModalVisible: true })
  }

  createOnClose = () => {
    this.setState({ createModalVisible: false })
  }

  createOnComplete = () => {
    this.setState({ createModalVisible: false })

    // Fecth all
    this.fetchGetAll(true)
  }

  /**
   * Edit
   *
   * @param uuid
   */
  editOpen = ({ id }) => {
    this.setState({ editModalVisible: true })

    // On open screen
    this.editScreen.onOpen(id)
  }

  editOnClose = () => {
    this.setState({ editModalVisible: false })
  }

  editOnComplete = () => {
    this.setState({ editModalVisible: false })

    // Fecth all
    this.fetchGetAll()
  }

  /**
   * Delete
   *
   * @param uuid
   */
  deleteConfirm = ({ id }) => {
    Modal.confirm({
      title: 'Confirmar exclusão!',
      content: 'Tem certeza de que deseja excluir este registro?',
      okText: 'Excluir',
      autoFocusButton: null,
      onOk: () => {
        return this.deleteConfirmed(id)
      },
    })
  }

  /**
   * Image preview
   */
  onImagePreviewClose = () => this.setState({ imagePreviewVisible: false })

  onImagePreview = (url) => {
    this.setState({
      imagePreviewImage: url,
      imagePreviewVisible: true,
    })
  }

  deleteConfirmed = (id) => {
    const { item, type } = this.props

    return videosService
      .destroy({
        type,
        content_id: item.id,
        id,
      })
      .then((response) => {
        // Fecth all
        this.fetchGetAll()
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  render() {
    return (
      <div style={{ paddingRight: 20, paddingLeft: 20 }}>
        <EMPageListing
          theme="small"
          enableSearch={false}
          enablePagination={false}
          isLoading={this.state.isLoading}
          showListTypeChange={false}
          data={this.state.data}
          columns={this.columns()}
          buttons={[
            {
              visible: true,
              onClick: this.createOpen,
              title: 'Cadastrar',
            },
          ]}
        />
        <ModalCreate
          wrappedComponentRef={(el) => (this.createScreen = el)}
          visible={this.state.createModalVisible}
          onComplete={this.createOnComplete}
          onClose={this.createOnClose}
          item={this.props.item}
          type={this.props.type}
        />
        <ModalEdit
          wrappedComponentRef={(el) => (this.editScreen = el)}
          visible={this.state.editModalVisible}
          onComplete={this.editOnComplete}
          onClose={this.editOnClose}
          item={this.props.item}
          type={this.props.type}
        />
        <Modal
          className="modal-image"
          visible={this.state.imagePreviewVisible}
          footer={null}
          destroyOnClose={true}
          onCancel={this.onImagePreviewClose}
        >
          <img src={this.state.imagePreviewImage} />
        </Modal>
      </div>
    )
  }
}

export default Index

import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Col, Drawer, Form, Icon, Modal, Row, Spin, Switch, Tabs, Upload } from "antd";

import moment from "moment";

import { tutorialService } from "./../../redux/services";

import { EMVideos, EMContents } from "../../components";

const FormItem = Form.Item;

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			id       : 0,
			item     : {},
			// Images
			imagePreviewVisible   : false,
			imagePreviewImage     : "",
			imageAdsPreviewVisible: false,
			imageAdsPreviewImage  : "",
			imageList             : {
				images: [],
			},
			imageAdsList          : {
				images: [],
			},
		};
	}

	fieldOptions = {
		cultures   : {
			label: "Culturas",
		},
		category_id: {
			label: "Categoria",
		},
		name       : {
			label: "Nome",
		},
		ads_url    : {
			label: "Link externo",
		},
		order      : {
			label: "Ordem",
		},
		is_active  : {
			label: "Ativo",
		},
		created_at : {
			label: "Criação",
		},
		updated_at : {
			label: "Última atualização",
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		tutorialService.show({id})
		.then((response) => {
			let item       = response.data.data;
			let imagesList = {
				images: [],
			};

			let imagesAdsList = {
				images: [],
			};

			if( item.images.length )
			{

				item.images = item.images.filter(item => item.type === "image")

				item.images.forEach(image => {
					imagesList.images.push({
						uid   : image.id,
						name  : image.file.split("/").pop(),
						status: "done",
						url   : image.file,
						// Has id, is api image
						id: image.id,
					});
				});
			}

			if( item.ads )
			{
				imagesAdsList.images.push({
					uid   : item.id,
					name  : item.ads.split("/").pop(),
					status: "done",
					url   : item.ads,
					// Has id, is api image
					id: item.id,
				});
			}

			this.setState({
				isLoading   : false,
				imageList   : imagesList,
				imageAdsList: imagesAdsList,
				item
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			item        : {},
			imageList   : {
				images: [],
			},
			imageAdsList: {
				images: [],
			},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	onImageAdsPreviewClose = () => this.setState({imageAdsPreviewVisible: false});

	onImageAdsPreview = (type, file) => {
		this.setState({
			imageAdsPreviewImage  : file.url,
			imageAdsPreviewVisible: true,
		});
	};

	renderImages(type) {
		const imageList = this.state.imageList[type]

		if( !imageList.length )
		{
			return (
				<div className="clearfix">
					Sem imagem
				</div>
			);
		}

		return (
			<div className="media-images-wrap">
				<Upload
					listType="picture-card"
					className="media-images-uploader media-images-view"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
				/>
			</div>
		);
	}

	renderAds(type) {
		const imageList = this.state.imageAdsList[type]

		if( !imageList.length )
		{
			return (
				<div className="clearfix">
					Sem imagem
				</div>
			);
		}

		return (
			<div className="media-images-wrap">
				<Upload
					listType="picture-card"
					className="media-images-uploader media-images-view"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
				/>
			</div>
		);
	}

	render() {
		const {visible} = this.props;

		const {id, isLoading, item, imagePreviewVisible, imagePreviewImage, imageAdsPreviewVisible, imageAdsPreviewImage} = this.state;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={1200}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label="Imagens">
								{this.renderImages("images")}
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} md={3}>
									<FormItem label="Ads">
										{this.renderAds("images")}
										<small>Dimensões: (1000x320px)</small>
									</FormItem>
								</Col>
								<Col xs={24} md={9}>
									<FormItem label={this.fieldOptions.ads_url.label}>
										{item.ads_url ?? '-'}
									</FormItem>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} md={10}>
									<FormItem label={this.fieldOptions.cultures.label}>
										{item.cultures && item.cultures.map(c => c.name).join(", ")}
									</FormItem>
								</Col>
								<Col xs={24} md={7}>
									<FormItem label={this.fieldOptions.name.label}>
										{item.name}
									</FormItem>
								</Col>
								<Col xs={24} md={7}>
									<FormItem label={this.fieldOptions.category_id.label}>
										{item.category && item.category.name}
									</FormItem>
								</Col>
							</Row>
							<FormItem label={this.fieldOptions.order.label}>
								{item.order}
							</FormItem>
							<FormItem label={this.fieldOptions.is_active.label}>
								<Switch disabled checked={item.is_active} />
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.created_at.label}>
										{moment(item.created_at).calendar()}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.updated_at.label}>
										{moment(item.updated_at).calendar()}
									</FormItem>
								</Col>
							</Row>
							<Tabs defaultActiveKey="contents" style={{marginBottom: 24}}>
								<Tabs.TabPane tab="Conteúdos" key="contents">
									<EMContents item={item} type="tutorials" />
								</Tabs.TabPane>
								<Tabs.TabPane tab="Videos" key="videos">
									<EMVideos item={item} type="tutorials" />
								</Tabs.TabPane>
							</Tabs>
						</div>
					)}
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
				<Modal className="modal-image" visible={imageAdsPreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImageAdsPreviewClose}>
					<img src={imageAdsPreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Show;

import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Col, Drawer, Form, Icon, Input, InputNumber, message, Modal, Row, Switch, Upload } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../../config/lang";

import { pestService } from "./../../../redux/services";

const FormItem = Form.Item;

const config = {
	images: {
		media: {
			maxSize     : 4,
			maxSizeVideo: 100,
			maxFiles    : 99,
			extensions  : ["jpg", "png", "mp4"],
			type        : ["image/jpeg", "image/png", "video/mp4"],
		}
	},
};

class Create extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isSending          : false,
			owner_id           : 0,
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imagePreviewType   : "image",
			imageList          : {
				media: [],
			},
		};
	}

	fieldOptions = {
		name      : {
			label    : "Nome",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		text      : {
			label    : "Texto",
			decorator: {
				initialValue: "",
			},
		},
		order     : {
			label    : "Ordem",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		start_open: {
			label    : "Iniciar aberto",
			decorator: {
				valuePropName: "checked",
				initialValue : false,
			},
		},
		is_active : {
			label    : "Ativo",
			decorator: {
				valuePropName: "checked",
				initialValue : true,
			},
		},
	};

	onOpen = (owner_id, nextOrder) => {
		this.setState({
			owner_id: owner_id,
		});

		// Fill form
		this.fillForm(nextOrder);
	};

	fillForm = (nextOrder) => {
		this.props.form.setFieldsValue({
			order: nextOrder,
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			imageList: {
				media: [],
			},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				const {owner_id, imageList} = this.state;

				// Owner ID
				values.owner_id = owner_id;

				// Media
				if( imageList.media.length )
				{
					values.media = imageList.media;
				}

				pestService.createContent(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro cadastrado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewType   : file.mediaType,
			imagePreviewVisible: true,
		});
	};

	onImageRemove = (type, file) => {
		let imagesNew = [...this.state.imageList[type]];

		const index = imagesNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesNew.splice(index, 1);

			this.setState(state => ({
				imageList: {
					...state.imageList,
					[type]: imagesNew,
				},
			}));
		}
	};

	renderImages(type) {
		const imageList   = this.state.imageList[type];
		const imageConfig = config.images[type];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
					onRemove={(file) => this.onImageRemove(type, file)}
					beforeUpload={(file) => {
						file.mediaType = file.type === "video/mp4" ? "video" : "image";

						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < (file.mediaType === "video" ? imageConfig.maxSizeVideo : imageConfig.maxSize);

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${(file.mediaType === "video" ? imageConfig.maxSizeVideo : imageConfig.maxSize)}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesNew = [...this.state.imageList[type]];

							if( imagesNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesNew.push(file);

								this.setState(state => ({
									imageList: {
										...state.imageList,
										[type]: imagesNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	render() {
		const {visible, form}                                                       = this.props;
		const {isSending, imagePreviewVisible, imagePreviewImage, imagePreviewType} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={520}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isSending} />
						<div className="ant-drawer-title">Inserir novo registro</div>
						<Button type="primary" onClick={this.onSubmit} className="btn-save" icon="check" loading={isSending}>Salvar</Button>
					</div>
					<div className="form-body">
						<FormItem label="Imagens/Vídeos">
							{this.renderImages("media")}
						</FormItem>
						<FormItem label={this.fieldOptions.name.label} hasFeedback>
							{getFieldDecorator("name", this.fieldOptions.name.decorator)(
								<Input />
							)}
						</FormItem>
						<FormItem label={this.fieldOptions.text.label} hasFeedback>
							{getFieldDecorator("text", this.fieldOptions.text.decorator)(
								<Input.TextArea autoSize={{minRows: 2, maxRows: 15}} />
							)}
						</FormItem>
						<FormItem label={this.fieldOptions.order.label} hasFeedback style={{maxWidth: 150}}>
							{getFieldDecorator("order", this.fieldOptions.order.decorator)(
								<InputNumber min={0} />
							)}
						</FormItem>
						<Row gutter={16}>
							<Col xs={12}>
								<FormItem label={this.fieldOptions.is_active.label}>
									{getFieldDecorator("is_active", this.fieldOptions.is_active.decorator)(
										<Switch />
									)}
								</FormItem>
							</Col>
							<Col xs={12}>
								<FormItem label={this.fieldOptions.start_open.label}>
									{getFieldDecorator("start_open", this.fieldOptions.start_open.decorator)(
										<Switch />
									)}
								</FormItem>
							</Col>
						</Row>
					</div>
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					{imagePreviewType === "video" ? (
						<video controls autoPlay>
							<source src={imagePreviewImage} type="video/mp4" />
						</video>
					) : <img src={imagePreviewImage} />}
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Create);

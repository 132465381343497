import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Drawer, Form, Icon, Input, InputNumber, message, Modal, Select, Spin, Switch, Upload } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { culturesService } from "./../../redux/services";

const FormItem = Form.Item;

const config = {
	images: {
		file: {
			maxSize   : 4,
			maxFiles  : 1,
			extensions: ["png"],
			type      : ["image/png"],
		}
	},
};

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading          : true,
			isSending          : false,
			id                 : 0,
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imageList          : {
				file: [],
			},
			imageListDeleted   : {
				file: [],
			},
			// Files
			files              : {
				phenological_stages       : [],
				fertility_nutrition_ground: [],
				fertility_nutrition_leaf  : [],
			}
		};
	}

	fieldOptions = {
		type     : {
			label    : "Tipo",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		name     : {
			label    : "Nome",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		order    : {
			label    : "Ordem",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		is_active: {
			label    : "Ativo",
			decorator: {
				valuePropName: "checked",
			},
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
		});

		culturesService.show({id})
		.then((response) => {
			this.setState({
				isLoading: false,
			});

			// Fill form
			this.fillForm(response.data.data);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			type     : data.type,
			name     : data.name,
			order    : data.order,
			is_active: data.is_active,
		});

		let imagesList = {
			file: [],
		};

		if( data.file )
		{
			imagesList.file.push({
				uid   : 1,
				name  : data.file.split("/").pop(),
				status: "done",
				url   : data.file,
				// Has id, is api image
				id    : 1,
			});
		}

		let files = {
			phenological_stages       : [],
			fertility_nutrition_ground: [],
			fertility_nutrition_leaf  : [],
		};

		if( data.phenological_stages )
		{
			files.phenological_stages.push({
				uid   : 1,
				name  : data.phenological_stages.split("/").pop(),
				status: "done",
				url   : data.phenological_stages,
				// Has id, is api image
				id    : 1,
			});
		}

		if( data.fertility_nutrition_ground )
		{
			files.fertility_nutrition_ground.push({
				uid   : 2,
				name  : data.fertility_nutrition_ground.split("/").pop(),
				status: "done",
				url   : data.fertility_nutrition_ground,
				// Has id, is api image
				id    : 2,
			});
		}

		if( data.fertility_nutrition_leaf )
		{
			files.fertility_nutrition_leaf.push({
				uid   : 3,
				name  : data.fertility_nutrition_leaf.split("/").pop(),
				status: "done",
				url   : data.fertility_nutrition_leaf,
				// Has id, is api image
				id    : 3,
			});
		}

		this.setState({
			imageList: imagesList,
			files    : files,
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			imageList       : {
				file: [],
			},
			imageListDeleted: {
				file: [],
			},
			// Files
			files           : {
				phenological_stages       : [],
				fertility_nutrition_ground: [],
				fertility_nutrition_leaf  : [],
			}
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				const {id, imageList, imageListDeleted, files} = this.state;

				if( !imageList.file.length )
				{
					Modal.error({
						title  : "Ocorreu um erro!",
						content: "Imagem é obrigatório",
					});

					return false;
				}

				this.setState({
					isSending: true,
				});

				// ID
				values.id = id;

				// File
				if( imageList.file.length && !imageList.file[0].id )
				{
					values.file = imageList.file[0];
				}

				if( files.phenological_stages.length )
				{
					if( !files.phenological_stages[0].id )
					{
						values.phenological_stages = files.phenological_stages[0];
					}
				}
				else
				{
					values.phenological_stages = null;
				}

				if( files.fertility_nutrition_ground.length )
				{
					if( !files.fertility_nutrition_ground[0].id )
					{
						values.fertility_nutrition_ground = files.fertility_nutrition_ground[0];
					}
				}
				else
				{
					values.fertility_nutrition_ground = null;
				}

				if( files.fertility_nutrition_leaf.length )
				{
					if( !files.fertility_nutrition_leaf[0].id )
					{
						values.fertility_nutrition_leaf = files.fertility_nutrition_leaf[0];
					}
				}
				else
				{
					values.fertility_nutrition_leaf = null;
				}

				culturesService.edit(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro atualizado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	onImageRemove = (type, file) => {
		let imagesNew           = [...this.state.imageList[type]];
		let imageListDeletedNew = [...this.state.imageListDeleted[type]];

		const index = imagesNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesNew.splice(index, 1);

			// Has id
			if( file.id )
			{
				imageListDeletedNew.push(file.id);
			}

			this.setState(state => ({
				imageList       : {
					...state.imageList,
					[type]: imagesNew,
				},
				imageListDeleted: {
					...state.imageListDeleted,
					[type]: imageListDeletedNew,
				},
			}));
		}
	};

	renderImages(type) {
		const imageList   = this.state.imageList[type];
		const imageConfig = config.images[type];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
					onRemove={(file) => this.onImageRemove(type, file)}
					beforeUpload={(file) => {
						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesNew = [...this.state.imageList[type]];

							if( imagesNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesNew.push(file);

								this.setState(state => ({
									imageList: {
										...state.imageList,
										[type]: imagesNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	renderUpload(type) {
		const file = this.state.files[type];

		return (
			<Upload
				accept=".pdf"
				fileList={file}
				onRemove={(file) => {
					this.setState(state => ({
						files: {
							...state.files,
							[type]: [],
						},
					}));
				}}
				beforeUpload={(file) => {
					this.setState(state => ({
						files: {
							...state.files,
							[type]: [file],
						},
					}));

					return false;
				}}>
				{file.length === 0 && <Button>
					<Icon type="upload" /> Selecionar arquivo
				</Button>}
			</Upload>
		);
	}

	render() {
		const {visible, form}                                                    = this.props;
		const {id, isLoading, isSending, imagePreviewVisible, imagePreviewImage} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={500}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label="Imagem">
								{this.renderImages("file")}
								<small>PNG com transparência e conteúdo na cor preta, essa imagem será convertida para no máximo 100x100px</small>
							</FormItem>
							<FormItem label="Estágios fenológicos">
								{this.renderUpload("phenological_stages")}
							</FormItem>
							<FormItem label="Fertilidade/Nutrição > solo">
								{this.renderUpload("fertility_nutrition_ground")}
							</FormItem>
							<FormItem label="Fertilidade/Nutrição > folha">
								{this.renderUpload("fertility_nutrition_leaf")}
							</FormItem>
							<FormItem label={this.fieldOptions.type.label} hasFeedback>
								{getFieldDecorator("type", this.fieldOptions.type.decorator)(
									<Select
										filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										allowClear
										showSearch>
										<Select.Option value="Arroz">Arroz</Select.Option>
										<Select.Option value="Café">Café</Select.Option>
										<Select.Option value="Cana-de-açucar">Cana-de-açucar</Select.Option>
										<Select.Option value="Feijão">Feijão</Select.Option>
										<Select.Option value="Milho">Milho</Select.Option>
										<Select.Option value="Soja">Soja</Select.Option>
										<Select.Option value="Sorgo">Sorgo</Select.Option>
										<Select.Option value="Trigo">Trigo</Select.Option>
										<Select.Option value="Outros">Outros</Select.Option>
									</Select>
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.name.label} hasFeedback>
								{getFieldDecorator("name", this.fieldOptions.name.decorator)(
									<Input />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.order.label} hasFeedback style={{maxWidth:150}}>
								{getFieldDecorator("order", this.fieldOptions.order.decorator)(
									<InputNumber min={0} />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.is_active.label}>
								{getFieldDecorator("is_active", this.fieldOptions.is_active.decorator)(
									<Switch />
								)}
							</FormItem>
						</div>
					)}
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Edit);

import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Avatar, Button, Col, Drawer, Form, Icon, Input, message, Modal, Row, Spin } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { suggestionService } from "./../../redux/services";

const FormItem = Form.Item;

class Answer extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			id       : 0,
			item     : {},
		};
	}

	fieldOptions = {
		message: {
			label    : "Mensagem",
			decorator: {
				hidden: true,
			},
		},
		answer : {
			label    : "Resposta",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		user   : {
			label    : "Usuário",
			decorator: {
				hidden: true,
			},
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		suggestionService.show({id})
		.then((response) => {
			this.setState({
				isLoading: false,
				item     : response.data.data,
			});

			// Fill form
			this.fillForm(response.data.data);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			answer: data.answer,
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				const {id} = this.state;

				// ID
				values.id = id;

				suggestionService.answer(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro atualizado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	render() {
		const {visible, form}                  = this.props;
		const {id, isLoading, isSending, item} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={500}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar e Enviar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label={this.fieldOptions.message.label}>
								{item.message}
							</FormItem>
							<FormItem label={this.fieldOptions.user.label}>
								{item.user && (
									<Row gutter={16}>
										<Col xs={24} sm={4}>
											<p>{item.user.avatar ? <Avatar size={64} src={item.user.avatar_sizes.admin_listing} /> : <Avatar size={64} icon="user" />}</p>
										</Col>
										<Col xs={24} sm={20}>
											{item.user.name}<br />
											<b>Celular:</b> {item.user.customer.cellphone}<br />
											<b>E-mail:</b> {item.user.email}<br />
											<b>Atividade:</b> {item.user.customer.activity.name}
										</Col>
									</Row>
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.answer.label} hasFeedback>
								{getFieldDecorator("answer", this.fieldOptions.answer.decorator)(
									<Input.TextArea autoSize={{minRows: 2, maxRows: 15}} />
								)}
							</FormItem>
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Answer);

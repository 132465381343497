import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Avatar, Button, Col, Drawer, Form, Icon, Modal, Row, Spin, Upload } from "antd";

import moment from "moment";

import { askAgronomistService } from "../../redux/services";

const FormItem = Form.Item;

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading          : true,
			id                 : 0,
			item               : {},
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imageList          : {
				images: [],
			},
		};
	}

	fieldOptions = {
		id         : {
			label: "ID",
		},
		message    : {
			label: "Mensagem",
		},
		answer     : {
			label: "Resposta",
		},
		answered_at: {
			label: "Respondido em",
		},
		created_at : {
			label: "Criação",
		},
		updated_at : {
			label: "Última atualização",
		},
		user       : {
			label: "Usuário",
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		askAgronomistService.show({id})
		.then((response) => {
			let item       = response.data.data;
			let imagesList = {
				images: [],
			};

			if( item.images.length )
			{
				item.images.forEach(image => {
					imagesList.images.push({
						uid   : image.id,
						name  : image.file.split("/").pop(),
						status: "done",
						url   : image.file,
						// Has id, is api image
						id    : image.id,
					});
				});
			}

			this.setState({
				isLoading: false,
				item     : item,
				imageList: imagesList,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			item     : {},
			imageList: {
				images: [],
			},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	renderImages(type) {
		const imageList = this.state.imageList[type];

		if( !imageList.length )
		{
			return (
				<div className="clearfix">
					Sem imagem
				</div>
			);
		}

		return (
			<div className="media-images-wrap">
				<Upload
					listType="picture-card"
					className="media-images-uploader media-images-view"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
				/>
			</div>
		);
	}

	render() {
		const {visible}                                                     = this.props;
		const {id, isLoading, item, imagePreviewVisible, imagePreviewImage} = this.state;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={500}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label="Imagens">
								{this.renderImages("images")}
							</FormItem>
							<FormItem label={this.fieldOptions.message.label}>
								{item.message}
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.created_at.label}>
										{moment(item.created_at).calendar()}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.updated_at.label}>
										{moment(item.updated_at).calendar()}
									</FormItem>
								</Col>
							</Row>
							<FormItem label={this.fieldOptions.user.label}>
								{item.user && (
									<Row gutter={16}>
										<Col xs={24} sm={4}>
											<p>{item.user.avatar ? <Avatar size={64} src={item.user.avatar_sizes.admin_listing} /> : <Avatar size={64} icon="user" />}</p>
										</Col>
										<Col xs={24} sm={20}>
											{item.user.name}<br />
											<b>Celular:</b> {item.user.customer.cellphone}<br />
											<b>E-mail:</b> {item.user.email}<br />
											<b>Atividade:</b> {item.user.customer.activity.name}
										</Col>
									</Row>
								)}
							</FormItem>
							{item.is_answered && (
								<Fragment>
									<FormItem label={this.fieldOptions.answer.label}>
										{item.agronomist_answer.message}
									</FormItem>
									<FormItem label={this.fieldOptions.answered_at.label}>
										{moment(item.agronomist_answer.created_at).calendar()}
									</FormItem>
								</Fragment>
							)}
						</div>
					)}
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Show;

import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Drawer, Form, Input, InputNumber, message, Modal, Select, Switch, Upload, Icon } from "antd";

import { FORM_VALIDATION_MESSAGES } from "../../config/lang";
import { videosService } from "../../redux/services";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const config = {
	images: {
		thumb: {
			maxSize   : 4,
			maxFiles  : 1,
			extensions: ["jpg", "png"],
			type      : ["image/jpeg", "image/png"],
		}
	},
};

class Create extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
		ownerItem : PropTypes.any,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading     : false,
			isSending     : false,
			specifications: [],

			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imagePreviewType   : "image",
			imageList          : {
				thumb: []
			},
		};

	}

	fieldOptions = {
		name              : {
			label    : "Nome",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		video              : {
			label    : "Url do video",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		order              : {
			label    : "Ordem",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		active              : {
			label    : "Ativo",
			decorator: {
				valuePropName: "checked",
				initialValue : true,
			},
		},

	};

	onOpen = () => {
		this.setState({
			isLoading: false,
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			imageList: {
				thumb: []
			},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (e) => {
		e.preventDefault();

		const {item, type} = this.props

		this.props.form.validateFieldsAndScroll((error, values) => {
			if (!error) {
				this.setState({
					isSending: true
				})

				const {imageList} = this.state;

				// Images
				if( imageList.thumb.length )
				{
					values.file = imageList.thumb[0];
				}

				const data = {
					...values,
					type,
					id: item.id
				};

				videosService.create(data).then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro cadastrado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					Modal.error({
						title  : "Ocorreu um erro!",
						content: String(data),
					});
				});
			}
		})
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewType   : file.mediaType,
			imagePreviewVisible: true,
		});
	};

	onImageRemove = (type, file) => {
		let imagesNew = [...this.state.imageList[type]];

		const index = imagesNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesNew.splice(index, 1);

			this.setState(state => ({
				imageList: {
					...state.imageList,
					[type]: imagesNew,
				},
			}));
		}
	};

	renderImages(type) {
		const imageList   = this.state.imageList[type];
		const imageConfig = config.images[type];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
					onRemove={(file) => this.onImageRemove(type, file)}
					beforeUpload={(file) => {
						file.mediaType = "image";

						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < (file.mediaType === "video" ? imageConfig.maxSizeVideo : imageConfig.maxSize);

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${(file.mediaType === "video" ? imageConfig.maxSizeVideo : imageConfig.maxSize)}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesNew = [...this.state.imageList[type]];

							if( imagesNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesNew.push(file);

								this.setState(state => ({
									imageList: {
										...state.imageList,
										[type]: imagesNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	render() {
		const {visible, form}                   = this.props;
		const {isLoading, isSending, imagePreviewVisible, imagePreviewImage, imagePreviewType} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={600}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onFinish}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">Inserir novo registro</div>
						<Button type="primary" onClick={this.onFinish} className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					<div className="form-body">

						<Form.Item label="Thumbnail do video">
							{this.renderImages("thumb")}
						</Form.Item>

						<Form.Item label="Nome">
							{getFieldDecorator("name", this.fieldOptions.name.decorator)(
								<Input/>
							)}
						</Form.Item>

						<Form.Item  label="Url do video">
							{getFieldDecorator("video", this.fieldOptions.video.decorator)(
								<Input/>
							)}
						</Form.Item>
						<Form.Item  label="Ordem">
							{getFieldDecorator("order", this.fieldOptions.order.decorator)(
								<Input/>
							)}
						</Form.Item>
						<Form.Item label="Ativo">
							{getFieldDecorator("is_active", this.fieldOptions.active.decorator)(
								<Switch/>
							)}
						</Form.Item>
					</div>
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					{imagePreviewType === "video" ? (
						<video controls autoPlay>
							<source src={imagePreviewImage} type="video/mp4" />
						</video>
					) : <img src={imagePreviewImage} />}
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Create);

import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Drawer, Form, Icon, Input, InputNumber, message, Modal, Switch, Upload } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { tutorialAuthorService } from "./../../redux/services";

const FormItem = Form.Item;

const config = {
	images: {
		file: {
			maxSize   : 4,
			maxFiles  : 1,
			extensions: ["png"],
			type      : ["image/png"],
		}
	},
};

class Create extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isSending: false,
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imageList          : {
				file: [],
			},
		};
	}

	fieldOptions = {
		name     : {
			label    : "Nome",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		bio    : {
			label    : "Bio",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		is_active: {
			label    : "Ativo",
			decorator: {
				valuePropName: "checked",
				initialValue : true,
			},
		},
	};

	onOpen = () => {
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			imageList: {
				file: [],
			},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				const {imageList} = this.state;

				if( !imageList.file.length )
				{
					Modal.error({
						title  : "Ocorreu um erro!",
						content: "Imagem é obrigatório",
					});

					return false;
				}

				this.setState({
					isSending: true,
				});

				// File
				values.file = imageList.file[0];

				tutorialAuthorService.create(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro cadastrado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	renderImages(type) {
		const imageList   = this.state.imageList[type];
		const imageConfig = config.images[type];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
					onRemove={(file) => this.onImageRemove(type, file)}
					beforeUpload={(file) => {
						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesNew = [...this.state.imageList[type]];

							if( imagesNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesNew.push(file);

								this.setState(state => ({
									imageList: {
										...state.imageList,
										[type]: imagesNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	render() {
		const {visible, form}                                     = this.props;
		const {isSending, imagePreviewVisible, imagePreviewImage} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={500}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isSending} />
						<div className="ant-drawer-title">Inserir novo registro</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending}>Salvar</Button>
					</div>
					<div className="form-body">
						<FormItem label="Imagem">
							{this.renderImages("file")}
							<small>PNG com transparência e conteúdo na cor preta, essa imagem será convertida para no máximo 100x100px</small>
						</FormItem>
						<FormItem label={this.fieldOptions.name.label} hasFeedback>
							{getFieldDecorator("name", this.fieldOptions.name.decorator)(
								<Input />
							)}
						</FormItem>
						<FormItem label={this.fieldOptions.bio.label} hasFeedback>
							{getFieldDecorator("bio", this.fieldOptions.bio.decorator)(
								<Input />
								)}
						</FormItem>
						<FormItem label={this.fieldOptions.is_active.label}>
							{getFieldDecorator("is_active", this.fieldOptions.is_active.decorator)(
								<Switch />
							)}
						</FormItem>
					</div>
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Create);

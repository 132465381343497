import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Drawer, Form, Icon, Modal, Spin } from "antd";

import moment from "moment";

import { pushStateService } from "./../../redux/services";

const FormItem = Form.Item;

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			id       : 0,
			item     : {},
		};
	}

	fieldOptions = {
		state_id    : {
			label: "Estado",
		},
		title       : {
			label: "Título",
		},
		body        : {
			label: "Mensagem",
		},
		total_users : {
			label: "Total de usuários",
		},
		url         : {
			label: "Url",
		},
		scheduled_at: {
			label: "Agendado para",
		},
		send_at     : {
			label: "Enviado em",
		},
		created_at  : {
			label: "Criação",
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		pushStateService.show({id})
		.then((response) => {
			this.setState({
				isLoading: false,
				item     : response.data.data,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const {visible}             = this.props;
		const {id, isLoading, item} = this.state;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={360}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label={this.fieldOptions.state_id.label}>
								{item.state && item.state.name}
							</FormItem>
							<FormItem label={this.fieldOptions.title.label}>
								{item.title}
							</FormItem>
							<FormItem label={this.fieldOptions.body.label}>
								{item.body}
							</FormItem>
							<FormItem label={this.fieldOptions.total_users.label}>
								{item.total_users}
							</FormItem>
							<FormItem label={this.fieldOptions.url.label}>
								{item.url}
							</FormItem>
							<FormItem label={this.fieldOptions.scheduled_at.label}>
								{item.scheduled_at && moment(item.scheduled_at).calendar()}
							</FormItem>
							<FormItem label={this.fieldOptions.send_at.label}>
								{item.send_at && moment(item.send_at).calendar()}
							</FormItem>
							<FormItem label={this.fieldOptions.created_at.label}>
								{moment(item.created_at).calendar()}
							</FormItem>
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Show;

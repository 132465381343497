import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Col, Drawer, Form, Icon, Input, message, Modal, Row, Select, Spin, Switch, Tabs, Upload } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { fertilizersFilters } from "./../../config/filters";

import { fertilizersService } from "./../../redux/services";

import TabContents from "./contents";
import { EMVideos, EMContents } from "../../components";

const FormItem = Form.Item;

const config = {
	images: {
		images: {
			maxSize   : 4,
			maxFiles  : 99,
			extensions: ["jpg", "png"],
			type      : ["image/jpeg", "image/png"],
		}
	},
};

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading          : true,
			isSending          : false,
			id                 : 0,
			item               : {},
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imageList          : {
				images: [],
			},
			imageListDeleted   : {
				images: [],
			},
		};
	}

	fieldOptions = {
		name          : {
			label    : "Nome",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		common_name   : {
			label    : "Nome comum",
			decorator: {},
		},
		category      : {
			label    : "Categoria",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		is_highlighted: {
			label    : "Destaque",
			decorator: {
				valuePropName: "checked",
			},
		},
		is_quote_now      : {
			label    : "Cotar agora",
			decorator: {
				valuePropName: "checked",
				initialValue : false,
			},
		},
		is_active     : {
			label    : "Ativo",
			decorator: {
				valuePropName: "checked",
			},
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		let item = {};

		fertilizersService.show({id})
		.then((response) => {
			item           = response.data.data;
			let imagesList = {
				images: [],
			};

			if( item.images.length )
			{

				item.images = item.images.filter(item => item.type === "image")

				item.images.forEach(image => {
					imagesList.images.push({
						uid   : image.id,
						name  : image.file.split("/").pop(),
						status: "done",
						url   : image.file,
						// Has id, is api image
						id    : image.id,
					});
				});
			}

			this.setState({
				isLoading: false,
				item     : item,
				imageList: imagesList,
			});

			// Fill form
			this.fillForm(item);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			name          : data.name,
			common_name   : data.common_name,
			category      : data.category,
			is_highlighted: data.is_highlighted,
			is_quote_now   : data.is_quote_now,
			is_active     : data.is_active,
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			imageList       : {
				images: [],
			},
			imageListDeleted: {
				images: [],
			},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				const {id, imageList, imageListDeleted} = this.state;

				// ID
				values.id = id;

				// Images
				if( imageList.images.length )
				{
					let images = imageList.images.filter(image => !image.id);

					if( images.length )
					{
						values.images = images;
					}
				}

				// Images delete
				if( imageListDeleted.images.length )
				{
					values.delete_images = imageListDeleted.images;
				}

				fertilizersService.edit(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro atualizado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	onImageRemove = (type, file) => {
		let imagesNew           = [...this.state.imageList[type]];
		let imageListDeletedNew = [...this.state.imageListDeleted[type]];

		const index = imagesNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesNew.splice(index, 1);

			// Has id
			if( file.id )
			{
				imageListDeletedNew.push(file.id);
			}

			this.setState(state => ({
				imageList       : {
					...state.imageList,
					[type]: imagesNew,
				},
				imageListDeleted: {
					...state.imageListDeleted,
					[type]: imageListDeletedNew,
				},
			}));
		}
	};

	renderImages(type) {
		const imageList   = this.state.imageList[type];
		const imageConfig = config.images[type];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
					onRemove={(file) => this.onImageRemove(type, file)}
					beforeUpload={(file) => {
						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesNew = [...this.state.imageList[type]];

							if( imagesNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesNew.push(file);

								this.setState(state => ({
									imageList: {
										...state.imageList,
										[type]: imagesNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	render() {
		const {visible, form}                                                          = this.props;
		const {id, item, isLoading, isSending, imagePreviewVisible, imagePreviewImage} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={1200}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label="Imagens">
								{this.renderImages("images")}
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} md={12}>
									<FormItem label={this.fieldOptions.name.label} hasFeedback>
										{getFieldDecorator("name", this.fieldOptions.name.decorator)(
											<Input />
										)}
									</FormItem>
								</Col>
								<Col xs={24} md={12}>
									<FormItem label={this.fieldOptions.common_name.label} hasFeedback>
										{getFieldDecorator("common_name", this.fieldOptions.common_name.decorator)(
											<Input />
										)}
									</FormItem>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} md={12}>
									<FormItem label={this.fieldOptions.category.label} hasFeedback>
										{getFieldDecorator("category", this.fieldOptions.category.decorator)(
											<Select
												filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												showSearch>
												{fertilizersFilters.categories.map((item, index) => (
													<Select.Option key={index} value={item}>{item}</Select.Option>
												))}
											</Select>
										)}
									</FormItem>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={8}>
									<FormItem label={this.fieldOptions.is_active.label}>
										{getFieldDecorator("is_active", this.fieldOptions.is_active.decorator)(
											<Switch />
										)}
									</FormItem>
								</Col>
								<Col xs={8}>
									<FormItem label={this.fieldOptions.is_highlighted.label}>
										{getFieldDecorator("is_highlighted", this.fieldOptions.is_highlighted.decorator)(
											<Switch />
										)}
									</FormItem>
								</Col>
								<Col xs={8}>
									<FormItem label={this.fieldOptions.is_quote_now.label}>
										{getFieldDecorator("is_quote_now", this.fieldOptions.is_quote_now.decorator)(
											<Switch />
										)}
									</FormItem>
								</Col>
							</Row>
							<Tabs defaultActiveKey="contents" style={{marginBottom: 24}}>
								<Tabs.TabPane tab="Conteúdos" key="contents">
									<EMContents item={item} type="fertilizers" />
								</Tabs.TabPane>
								<Tabs.TabPane tab="Videos" key="videos">
									<EMVideos item={item} type="fertilizers" />
								</Tabs.TabPane>
							</Tabs>
						</div>
					)}
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Edit);

import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const RoutePrivate = ({component: Component, ...rest}) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				rest.isAuthenticated ? <Component {...props} /> : <Redirect to={{pathname: "/login", state: {referrer: props.location}}} />
			)}
		/>
	)
};

const mapStateToProps = (state, ownProps) => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
	};
};

export default connect(mapStateToProps)(RoutePrivate);

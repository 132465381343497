// -----------------------------------------------------------------------------
// Ads screens
// -----------------------------------------------------------------------------
export const adsFilters = {
	"contents"  : [
		{
			type: null,
			name: "Plantas daninhas"
		},
		{
			type: "collection-inputs",
			name: "Insumos"
		},
		{
			type: "collection-fertility-nutrition",
			name: "Fertilidade e Nutrição"
		},
		{
			type: "collection-machines-implements",
			name: "Máquinas e implementos"
		},
		{
			type: null,
			name: "Cotações"
		},
		{
			type: null,
			name: "Notícias"
		}
	],
	"cultures": [
		"Defensivos",
		"Deficiências nutricionais",
		"Doenças",
		"Fertilizantes foliares",
		"Pragas",
		"Variedades e Híbridos"
	],
	"collection-inputs": [
		"Defensivos",
		"Variedades e Híbridos",
		"Fertilizantes/Corretivos",
		"Fertilizantes foliares",
	],
	"collection-fertility-nutrition": [
		"Solo",
		"Folha"
	],
	"collection-machines-implements": [
		"Tratores",
		"Pulverizadores",
		"Colhedoras",
		"Semeadoras",
		"Implementos",
		"Drones",
		"Distribuidores de corretivos/fertilizantes"
	]
};

// -----------------------------------------------------------------------------
// Defensive
// -----------------------------------------------------------------------------
export const defensiveFilters = {
	"categories": [
		'Acaricidas',
	//	'Acaricida Microbiológico',
//		'Agente Biológico de Controle',
//		'Bactericida Microbiológico, Fungicida Microbiológico',
//		'Feromônio',
//		'Feromônio, Inseticida',
		"Fungicidas",
		"Fungicidas biológicos",
//		'Fungicida Microbiológico',
//		'Fungicida Microbiológico, Nematicida Microbiológico',
		//'Fungicida, Nematicida Microbiológico',
		"Herbicidas",
		"Inseticidas",
		"Inseticidas biológicos",
		//'Inseticida Microbiológico',
		"Nematicidas",
		"Nematicidas biológicos",
		//'Nematicida Microbiológico',
		"Reguladores de crescimento",
		"Microbiológicos",
		"Macrobiológicos",
		"Semioquimicos"
	],
};

// -----------------------------------------------------------------------------
// Diseases
// -----------------------------------------------------------------------------
export const diseasesFilters = {
	"categories": [
		"Folhas e caules",
		"Órgãos reprodutivos (Vagens/Cachos/Espigas)",
		"Raízes",
	],
};

// -----------------------------------------------------------------------------
// Fertilizers
// -----------------------------------------------------------------------------
export const fertilizersFilters = {
	"categories": [
		"Nitrogenados",
		"Fosfatados",
		"Potássicos",
		"Corretivos",
	],
};

// -----------------------------------------------------------------------------
// Leaf Fertilizers
// -----------------------------------------------------------------------------
export const leafFertilizersFilters = {
	"nutrients": [
		"N",
		"P",
		"K",
		"Ca",
		"Mg",
		"S",
		"B",
		"Cu",
		"Mn",
		"Zn",
	],
	"sources"  : [
		"Sulfato",
		"Cloreto",
		"Nitrato",
		"Quelato",
		"Mistura de fontes",
		"Outros",
	],
};

// -----------------------------------------------------------------------------
// Nutritional deficiencies
// -----------------------------------------------------------------------------
export const nutritionalDeficienciesFilters = {
	"categories": [
		"Folhas superiores",
		"Folhas dos baixeiros",
		"Raízes e pontos de crescimento",
	],
};

// -----------------------------------------------------------------------------
// Pests
// -----------------------------------------------------------------------------
export const pestsFilters = {
	"categories": [
		"Folhas e caules",
		"Orgãos reprodutivos",
		"Raízes",
	],
};

// -----------------------------------------------------------------------------
// Varieties
// -----------------------------------------------------------------------------
export const varietiesFilters = {
	"region"      : [
		"Norte",
		"Nordeste",
		"Centro-Oeste",
		"Sudeste",
		"Sul",
	],
	"cycle"       : [
		"Precoce",
		"Médio",
		"Tardio",
	],
	"growth_habit": [
		"Indeterminado",
		"Semi-determinado",
		"Determinado",
	],
	"technology"  : [
		"Convencional",
		"RR",
		"Intacta (IPRO)",
		"VT PRO",
		"VT PRO 2",
		"VT PRO 3",
		"VT PRO 4",
		"VIPTERA",
		"VIP 2",
		"VIP 3",
		"POWER CORE (PW)",
		"POWER CORE ULTRA (PWU)",
		"BOLLGARD II RR FLEX",
		"GLYTOL",
		"LIBERTYLINK",
		"TWINLINK PLUS",
		"GLTP",
		"WIDESTRIKE",
		"ENLIST",
		"INTACTA 2 XTEND",
	],
	"genetics"    : [
		"Adisa",
		"Advanta",
		"Agromen",
		"Agro Norte",
		"Agroceres",
		"Agroeste",
		"Atlântica",
		"Balu",
		"Basf",
		"Biomatrix",
		"Biotrigo",
		"Brasmax",
		"Brevant Sementes",
		"Caraíba Genética",
		"Coodetec",
		"Cordius",
		"Conkesta E3",
		"Credenz",
		"Crivus",
		"CTC",
		"Dekalb",
		"Deltapine",
		"DI Solo Sementes",
		"DonMario",
		"Ellas",
		"Embrapa",
		"Fibermax",
		"FPS - Fundação Pro-Sementes",
		'Forseed',
		"FT - Sementes",
		"Geneze",
		"Guaiá",
		"Seedcorp | HO",
		"Stine",
		"IAC",
		"IAPAR",
		"KWS",
		"Leptra",
		"Limagran",
		"Monsoy",
		"Morgan",
		"Neogen",
		"Nidera",
		"NK seeds",
		"Nortox",
		"OR sementes",
		"Outras",
		"PionEEr",
		"Priorizi",
		"Riber",
		"Ridesa Brasil",
		"Santa Helena",
		"Semeali",
		"Sempre",
		"Soy",
		"Soytech",
		"Syngenta",
		"TMG",
		"Vertix",
		"Vignis",
		"Xtend Refúgio"
	],
};

// -----------------------------------------------------------------------------
// Weeds
// -----------------------------------------------------------------------------
export const weedsFilters = {
	"categories": [
		"Folha larga",
		"Folha estreita",
	],
};

import Activities from "./../screens/Activities";
import About from "./../screens/About";
import Account from "./../screens/Account";
import AccountPassword from "./../screens/AccountPassword";
import ActiveIngredients from "./../screens/ActiveIngredients";
import AskAgronomists from "./../screens/AskAgronomists";
import AskAgronomistsAnswers from "./../screens/AskAgronomistsAnswers";
import ChemicalGroups from "./../screens/ChemicalGroups";
import Comments from "./../screens/Comments";
import Companies from "./../screens/Companies";
import Contracts from "./../screens/Contracts";
import Credit from "./../screens/Credit";
import Cultures from "./../screens/Cultures";
import Customers from "./../screens/Customers";
import CustomersDeleted from "./../screens/CustomersDeleted";
import Defensives from "./../screens/Defensives";
import Denunciations from "./../screens/Denunciations";
import Diseases from "./../screens/Diseases";
import Faq from "./../screens/Faq";
import Fertilizers from "./../screens/Fertilizers";
import LeafFertilizers from "./../screens/LeafFertilizers";
import MachinesImplements from "./../screens/MachinesImplements";
import TypesMachinesImplements from "./../screens/TypesMachinesImplements";
import Animals from "./../screens/Animals";
import AnimalsType from "./../screens/AnimalsType";
import NutritionalDeficiencies from "./../screens/NutritionalDeficiencies";
import Home from "./../screens/Home";
import Login from "./../screens/Login";
import Logs from "./../screens/Logs";
import Pests from "./../screens/Pests";
import PrivacyPolicy from "./../screens/PrivacyPolicy";
import PushCity from "./../screens/PushCity";
import PushGeneral from "./../screens/PushGeneral";
import PushState from "./../screens/PushState";
import PushUser from "./../screens/PushUser";
import Ratings from "./../screens/Ratings";
import RecoveryPassword from "./../screens/RecoveryPassword";
import ReportAProblem from "./../screens/ReportAProblem";
import RolesAndPermissions from "./../screens/RolesAndPermissions";
import SettingsGeneral from "./../screens/SettingsGeneral";
import SettingsNotifications from "./../screens/SettingsNotifications";
import SettingsSocial from "./../screens/SettingsSocial";
import Splash from "./../screens/Splash";
import SystemLog from "./../screens/SystemLog";
import TablesConversions from "./../screens/TablesConversions";
import Tutorials from "./../screens/Tutorials";
import TutorialsCategories from "./../screens/TutorialsCategories";
import TermOfUse from "./../screens/TermOfUse";
import Users from "./../screens/Users";
import Varieties from "./../screens/Varieties";
import Weeds from "./../screens/Weeds";
import TutorialsAuthor from "../screens/TutorialsAuthor";
import Tokens from "../screens/Tokens";
import Suggestions from "../screens/Suggestions";
import Publications from "../screens/Publications";

// -----------------------------------------------------------------------------
// Routes
// -----------------------------------------------------------------------------
/**
 * Public routes for free access
 *
 * @type {Array}
 */
export const PUBLIC_ROUTES = [];

/**
 * Private routes for registered user access
 *
 * @type {Array}
 */
export const PRIVATE_ROUTES = [
	// Home
	{
		path     : "/",
		component: Home,
		exact    : true,
	},
	// Account
	{
		path     : "/account",
		component: Account,
		exact    : true,
	},
	{
		path     : "/account/password",
		component: AccountPassword,
		exact    : true,
	},
	// Activities
	{
		path     : "/activities",
		component: Activities,
		exact    : true,
	},
	// Administrator
	{
		path     : "/administrator/logs",
		component: Logs,
		exact    : true,
	},
	{
		path     : "/administrator/users",
		component: Users,
		exact    : true,
	},
	{
		path     : "/administrator/roles-and-permissions",
		component: RolesAndPermissions,
		exact    : true,
	},
	{
		path     : "/administrator/system-log",
		component: SystemLog,
		exact    : true,
	},
	{
		path     : "/administrator/tokens",
		component: Tokens,
		exact    : true,
	},
	// Ads
	// {
	// 	path     : "/ads",
	// 	component: Ads,
	// 	exact    : true,
	// },
	// Ask agronomists
	{
		path     : "/ask-agronomists/questions",
		component: AskAgronomists,
		exact    : true,
	},
	{
		path     : "/ask-agronomists/answers",
		component: AskAgronomistsAnswers,
		exact    : true,
	},
	// Contents
	{
		path     : "/contents/active-ingredients",
		component: ActiveIngredients,
		exact    : true,
	},
	{
		path     : "/contents/chemical-groups",
		component: ChemicalGroups,
		exact    : true,
	},
	{
		path     : "/contents/companies",
		component: Companies,
		exact    : true,
	},
	{
		path     : "/contents/contracts",
		component: Contracts,
		exact    : true,
	},
	{
		path     : "/contents/credit",
		component: Credit,
		exact    : true,
	},
	{
		path     : "/contents/cultures",
		component: Cultures,
		exact    : true,
	},
	{
		path     : "/contents/defensives",
		component: Defensives,
		exact    : true,
	},
	{
		path     : "/contents/diseases",
		component: Diseases,
		exact    : true,
	},
	{
		path     : "/contents/fertilizers",
		component: Fertilizers,
		exact    : true,
	},
	{
		path     : "/contents/leaf-fertilizers",
		component: LeafFertilizers,
		exact    : true,
	},
	{
		path     : "/contents/machines-implements",
		component: MachinesImplements,
		exact    : true,
	},
	{
		path     : "/contents/types-machines-implements",
		component: TypesMachinesImplements,
		exact    : true,
	},
	{
		path     : "/contents/animals",
		component: Animals,
		exact    : true,
	},
	{
		path     : "/contents/type-animals",
		component: AnimalsType,
		exact    : true,
	},
	{
		path     : "/contents/nutritional-deficiencies",
		component: NutritionalDeficiencies,
		exact    : true,
	},
	// {
	// 	path     : "/contents/news",
	// 	component: News,
	// 	exact    : true,
	// },
	// {
	// 	path     : "/contents/media",
	// 	component: Media,
	// 	exact    : true,
	// },
	// {
	// 	path     : "/contents/sponsoredpost",
	// 	component: SponsoredPublication,
	// 	exact    : true,
	// },
	{
		path     : "/contents/publications",
		component: Publications,
		exact    : true,
	},
	{
		path     : "/contents/pests",
		component: Pests,
		exact    : true,
	},
	{
		path     : "/contents/tables-conversions",
		component: TablesConversions,
		exact    : true,
	},
	{
		path     : "/contents/tutorials",
		component: Tutorials,
		exact    : true,
	},
	{
		path     : "/contents/tutorials-categories",
		component: TutorialsCategories,
		exact    : true,
	},
	{
		path     : "/contents/tutorials-authors",
		component: TutorialsAuthor,
		exact    : true,
	},
	{
		path     : "/contents/varieties",
		component: Varieties,
		exact    : true,
	},
	{
		path     : "/contents/weeds",
		component: Weeds,
		exact    : true,
	},
	// Comments
	{
		path     : "/comments",
		component: Comments,
		exact    : true,
	},
	// Customers
	{
		path     : "/customers",
		component: Customers,
		exact    : true,
	},
	// Deleted Customers
	{
		path     : "/customers-deleted",
		component: CustomersDeleted,
		exact    : true,
	},
	// Denunciations
	{
		path     : "/denunciations",
		component: Denunciations,
		exact    : true,
	},
	// Faq
	{
		path     : "/faq",
		component: Faq,
		exact    : true,
	},
	// Instutional
	{
		path     : "/institutional/about",
		component: About,
		exact    : true,
	},
	{
		path     : "/institutional/privacy-policy",
		component: PrivacyPolicy,
		exact    : true,
	},
	{
		path     : "/institutional/terms-of-use",
		component: TermOfUse,
		exact    : true,
	},
	// Push
	{
		path     : "/push/city",
		component: PushCity,
		exact    : true,
	},
	{
		path     : "/push/general",
		component: PushGeneral,
		exact    : true,
	},
	{
		path     : "/push/state",
		component: PushState,
		exact    : true,
	},
	{
		path     : "/push/user",
		component: PushUser,
		exact    : true,
	},
	// Ratings
	{
		path     : "/ratings",
		component: Ratings,
		exact    : true,
	},
	// Report a problem
	{
		path     : "/report-a-problem",
		component: ReportAProblem,
		exact    : true,
	},
	// Suggestions
	{
		path     : "/suggestions",
		component: Suggestions,
		exact    : true,
	},
	// Settings
	{
		path     : "/settings/general",
		component: SettingsGeneral,
		exact    : true,
	},
	{
		path     : "/settings/notifications",
		component: SettingsNotifications,
		exact    : true,
	},
	{
		path     : "/settings/social",
		component: SettingsSocial,
		exact    : true,
	},
	{
		path	 : "/splash",
		component: Splash,
		exact	 : true
	},
];

/**
 * Session routes that if logged in need to be redirected to the dashboard
 *
 * @type {Array}
 */
export const SESSION_ROUTES = [
	// Login
	{
		path     : "/login",
		component: Login,
		exact    : true,
	},
	// Recovery password
	{
		path     : "/recovery-password",
		component: RecoveryPassword,
		exact    : true,
	},
];

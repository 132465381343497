import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Col, Drawer, Form, Icon, Input, message, Modal, Row, Select, Spin, Switch, Upload } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { nutritionalDeficienciesFilters } from "./../../config/filters";

import { culturesService, nutritionalDeficiencyService } from "./../../redux/services";

const FormItem = Form.Item;

const config = {
	images: {
		images: {
			maxSize   : 4,
			maxFiles  : 99,
			extensions: ["jpg", "png"],
			type      : ["image/jpeg", "image/png"],
		},
		ads: {
			maxSize   : 4,
			maxFiles  : 1,
			extensions: ["jpg", "png"],
			type      : ["image/jpeg", "image/png"],
		}
	},
};

class Create extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading          : true,
			isSending          : false,
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imageAdsPreviewVisible: false,
			imageAdsPreviewImage  : "",
			imageList          : {
				images: [],
			},
			imageAdsList          : {
				images: [],
			},
			imageAdsListDeleted   : {
				images: [],
			},
			cultures           : [],
		};
	}

	fieldOptions = {
		culture_id    : {
			label    : "Cultura",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		name          : {
			label    : "Nome",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		element   : {
			label    : "Elemento",
			decorator: {
				initialValue: "",
			},
		},
		category      : {
			label    : "Categoria",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		ads_url		   : {
			label    : "Link externo",
			decorator: {
				rules: [],
			},
		},
		is_highlighted: {
			label    : "Destaque",
			decorator: {
				valuePropName: "checked",
				initialValue : false,
			},
		},
		is_active     : {
			label    : "Ativo",
			decorator: {
				valuePropName: "checked",
				initialValue : true,
			},
		},
	};

	onOpen = () => {
		this.setState({
			isLoading: true,
		});

		culturesService.getAutocomplete({
			orderBy: "name:asc",
		})
		.then((response) => {
			this.setState({
				isLoading: false,
				cultures : response.data.data,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			imageList: {
				images: [],
			},
			imageAdsList: {
				images: [],
			},
			cultures : [],
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				const {imageList, imageAdsList} = this.state;

				// Images
				if( imageList.images.length )
				{
					values.images = imageList.images;
				}

				// Images ads
				if( imageAdsList.images.length )
				{
					values.ads = imageAdsList.images;
				}

				nutritionalDeficiencyService.create(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro cadastrado com sucesso.");

					// Callback
					this.props.onComplete(response.data.data);
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	onImageRemove = (type, file) => {
		let imagesNew = [...this.state.imageList[type]];

		const index = imagesNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesNew.splice(index, 1);

			this.setState(state => ({
				imageList: {
					...state.imageList,
					[type]: imagesNew,
				},
			}));
		}
	};

	onImageAdsPreviewClose = () => this.setState({imageAdsPreviewVisible: false});

	onImageAdsPreview = (type, file) => {
		this.setState({
			imageAdsPreviewImage  : file.url,
			imageAdsPreviewVisible: true,
		});
	};

	onImageAdsRemove = (type, file) => {
		let imagesAdsNew           = [...this.state.imageAdsList[type]];
		let imageAdsListDeletedNew = [...this.state.imageAdsListDeleted[type]];

		const index = imagesAdsNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesAdsNew.splice(index, 1);

			// Has id
			if( file.id )
			{
				imageAdsListDeletedNew.push(file.id);
			}

			this.setState(state => ({
				imageAdsList       : {
					...state.imageAdsList,
					[type]: imagesAdsNew,
				},
				imageAdsListDeleted: {
					...state.imageAdsListDeleted,
					[type]: imageAdsListDeletedNew,
				},
			}));
		}
	};

	renderAds(type) {
		const imageAdsList = this.state.imageAdsList[type];
		const imageConfig  = config.images["ads"];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageAdsList}
					onPreview={(file) => this.onImageAdsPreview(type, file)}
					onRemove={(file) => this.onImageAdsRemove(type, file)}
					beforeUpload={(file) => {
						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesAdsNew = [...this.state.imageAdsList[type]];

							if( imagesAdsNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesAdsNew.push(file);

								this.setState(state => ({
									imageAdsList: {
										...state.imageAdsList,
										[type]: imagesAdsNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageAdsList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	renderImages(type) {
		const imageList   = this.state.imageList[type];
		const imageConfig = config.images[type];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
					onRemove={(file) => this.onImageRemove(type, file)}
					beforeUpload={(file) => {
						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesNew = [...this.state.imageList[type]];

							if( imagesNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesNew.push(file);

								this.setState(state => ({
									imageList: {
										...state.imageList,
										[type]: imagesNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	render() {
		const {visible, form}                                                          = this.props;
		const {isLoading, isSending, imagePreviewVisible, imagePreviewImage, imageAdsPreviewVisible, imageAdsPreviewImage, cultures} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={410}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">Inserir novo registro</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label="Imagens">
								{this.renderImages("images")}
							</FormItem>
							<FormItem label="Ads">
								{this.renderAds("images")}
								<small>Dimensões: (1000x320px)</small>
							</FormItem>
							<FormItem label={this.fieldOptions.ads_url.label} hasFeedback>
								{getFieldDecorator("ads_url", this.fieldOptions.ads_url.decorator)(
									<Input />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.culture_id.label} hasFeedback>
								{getFieldDecorator("culture_id", this.fieldOptions.culture_id.decorator)(
									<Select
										filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										allowClear
										showSearch>
										{cultures.map((item, index) => (
											<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
										))}
									</Select>
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.name.label} hasFeedback>
								{getFieldDecorator("name", this.fieldOptions.name.decorator)(
									<Input />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.element.label} hasFeedback>
								{getFieldDecorator("element", this.fieldOptions.element.decorator)(
									<Input />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.category.label} hasFeedback>
								{getFieldDecorator("category", this.fieldOptions.category.decorator)(
									<Select
										filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										allowClear
										showSearch>
										{nutritionalDeficienciesFilters.categories.map((item, index) => (
											<Select.Option key={index} value={item}>{item}</Select.Option>
										))}
									</Select>
								)}
							</FormItem>
							<Row gutter={16}>
								<Col xs={12}>
									<FormItem label={this.fieldOptions.is_active.label}>
										{getFieldDecorator("is_active", this.fieldOptions.is_active.decorator)(
											<Switch />
										)}
									</FormItem>
								</Col>
								<Col xs={12}>
									<FormItem label={this.fieldOptions.is_highlighted.label}>
										{getFieldDecorator("is_highlighted", this.fieldOptions.is_highlighted.decorator)(
											<Switch />
										)}
									</FormItem>
								</Col>
							</Row>
						</div>
					)}
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
				<Modal className="modal-image" visible={imageAdsPreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImageAdsPreviewClose}>
					<img src={imageAdsPreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Create);

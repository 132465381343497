import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Col, Drawer, Form, Icon, Input, message, Modal, Row, Select, Spin, Switch, Tabs, Upload } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { splashService } from "./../../redux/services";

const FormItem = Form.Item;


const config = {
	images: {
		images: {
			maxSize   : 4,
			maxFiles  : 1,
			extensions: ["jpg", "png"],
			type      : ["image/jpeg", "image/png"],
		}
	},
};

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading          : true,
			isSending          : false,
			id                 : 0,
			item               : {},
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imageList          : {
				images: [],
			},
			imageListDeleted   : {
				images: [],
			},
		};
	}

	fieldOptions = {
		name           : {
			label    : "Nome",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		is_active      : {
			label    : "Ativo",
			decorator: {
				valuePropName: "checked",
			},
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		let item = {};

		splashService.show({id})
		.then((response) => {
			item = response.data.data;

			let imagesList = {
				images: [],
			};

			if( item.file )
			{
				imagesList.images.push({
					uid   : item.id,
					name  : item.file.split("/").pop(),
					status: "done",
					url   : item.file,
					// Has id, is api image
					id    : item.id,
				});
			}

			this.setState({
				isLoading: false,
				item     : item,
				imageList: imagesList,
			});

			// Fill form
			this.fillForm(item);
		})
		.catch((data) => {

			console.log(data)
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			name           : data.name,
			is_active      : data.is_active,
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			imageList       : {
				images: [],
			},
			imageListDeleted: {
				images: [],
			},
			cultures        : [],
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				const {id, imageList, imageListDeleted} = this.state;

				// ID
				values.id = id;

				// Images
				if( imageList.images.length )
				{
					let images = imageList.images.filter(image => !image.id);

					if( images.length )
					{
						values.images = images;
					}
				}

				// Images delete
				if( imageListDeleted.images.length )
				{
					values.delete_images = imageListDeleted.images;
				}

				splashService.edit(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro atualizado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	onImageRemove = (type, file) => {
		let imagesNew           = [...this.state.imageList[type]];
		let imageListDeletedNew = [...this.state.imageListDeleted[type]];

		const index = imagesNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesNew.splice(index, 1);

			// Has id
			if( file.id )
			{
				imageListDeletedNew.push(file.id);
			}

			this.setState(state => ({
				imageList       : {
					...state.imageList,
					[type]: imagesNew,
				},
				imageListDeleted: {
					...state.imageListDeleted,
					[type]: imageListDeletedNew,
				},
			}));
		}
	};

	renderImages(type) {
		const imageList   = this.state.imageList[type];
		const imageConfig = config.images[type];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
					onRemove={(file) => this.onImageRemove(type, file)}
					beforeUpload={(file) => {
						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesNew = [...this.state.imageList[type]];

							if( imagesNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesNew.push(file);

								this.setState(state => ({
									imageList: {
										...state.imageList,
										[type]: imagesNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	render() {
		const {visible, form}                                                                    = this.props;
		const {id, item, isLoading, isSending, imagePreviewVisible, imagePreviewImage} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={680}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label="Imagens">
								{this.renderImages("images")}
								<small>Dimensões: (1080x1920px)</small>
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} md={12}>
									<FormItem label={this.fieldOptions.name.label} hasFeedback>
										{getFieldDecorator("name", this.fieldOptions.name.decorator)(
											<Input />
										)}
									</FormItem>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={12}>
									<FormItem label={this.fieldOptions.is_active.label}>
										{getFieldDecorator("is_active", this.fieldOptions.is_active.decorator)(
											<Switch />
										)}
									</FormItem>
								</Col>
							</Row>
						</div>
					)}
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Edit);

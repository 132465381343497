import {Button, DatePicker, Drawer, Form, Input, message, Modal,} from "antd";
import * as PropTypes from "prop-types";
import React, {Component} from "react";
import "react-quill/dist/quill.snow.css";

import {API_ERRO_TYPE_VALIDATION} from "../../config/general";

import {FORM_VALIDATION_MESSAGES} from "../../config/lang";

import {tokensService} from "./../../redux/services";
import moment from "moment";

const FormItem = Form.Item;

class Create extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            expiration: "",
        };
    }

    fieldOptions = {
        name: {
            label: "Nome",
            decorator: {
                rules: [{required: true, message: "Campo obrigatório."}],
            },
        },
        expiration: {
            label: "Validade",
            decorator: {
                defaultValue: null
            },
        },
    };

    onOpen = () => {
    };

    resetFields = () => {
        this.props.form.resetFields();
    };

    onClose = () => {
        // Reset fields
        this.resetFields();

        // Callback
        this.props.onClose();
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (!error) {

                this.setState({
                    isSending: true,
                });
                values.expiration = values.expiration ? moment(values.expiration).format('yyyy-MM-DD') : null;

                tokensService
                    .create(values)
                    .then(() => {
                        this.setState({
                            isSending: false,
                        });

                        // Reset fields
                        this.resetFields();

                        // Success message
                        message.success("Registro cadastrado com sucesso.");

                        // Callback
                        this.props.onComplete();
                    })
                    .catch((data) => {
                        this.setState({
                            isSending: false,
                        });

                        // if validation error
                        if (data.error_type === API_ERRO_TYPE_VALIDATION) {
                            let hasFieldsErrors = false;

                            for (let key in data.error_errors) {
                                if (
                                    data.error_errors[key] &&
                                    this.fieldOptions[key]
                                ) {
                                    this.props.form.setFields({
                                        [key]: {
                                            value: values[key],
                                            errors: [new Error(data.error_errors[key])],
                                        },
                                    });

                                    hasFieldsErrors = true;
                                }
                            }

                            if (!hasFieldsErrors) {
                                Modal.error({
                                    title: "Ocorreu um erro!",
                                    content: data.error_message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: "Ocorreu um erro!",
                                content: data.error_message,
                            });
                        }
                    });
            }
        });
    };

    render() {
        const {visible, form} = this.props;
        const {
            isSending,
        } = this.state;

        const {getFieldDecorator} = form;

        return (
            <Drawer
                visible={visible}
                className="drawer-form"
                width={"50%"}
                maskClosable={false}
                closable={false}
                keyboard={true}
                placement="right"
                onClose={this.onClose}
            >
                <Form layout="vertical" onSubmit={this.onSubmit}>
                    <div className="form-header">
                        <Button
                            className="btn-close"
                            onClick={this.onClose}
                            icon="close"
                            disabled={isSending}
                        />
                        <div className="ant-drawer-title">Inserir novo registro</div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-save"
                            icon="check"
                            loading={isSending}
                        >
                            Salvar
                        </Button>
                    </div>
                    <div className="form-body">
                        <FormItem label={this.fieldOptions.name.label} hasFeedback>
                            {getFieldDecorator(
                                "name",
                                this.fieldOptions.name.decorator
                            )(<Input/>)}
                        </FormItem>
                        <FormItem label={this.fieldOptions.expiration.label} hasFeedback>
                            {getFieldDecorator(
                                "expiration",
                                this.fieldOptions.expiration.decorator
                            )(<DatePicker
                                disabled={isSending}
                                format="DD/MM/YYYY"
                                placeholder="Sem validade"
                            />)}
                        </FormItem>
                    </div>
                </Form>
            </Drawer>
        );
    }
}

export default Form.create({
    validateMessages: FORM_VALIDATION_MESSAGES,
})(Create);

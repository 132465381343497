import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Icon, Input, Menu, Modal, Table, Typography } from "antd";

import moment from "moment";

import { commentService } from "./../../redux/services";

import DefaultTemplate from "./../../templates/defaultTemplate";

import ModalShow from "./show";
import ModalExport from "./export";
import UserModalShow from "./../Customers/show";

const config = {
	title               : "Comentários",
	orderByField        : "created_at",
	orderBySort         : "desc",
	permissionPrefix    : "comments",
	userPermissionPrefix: "users",
};

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading           : false,
			data                : [],
			pagination          : {
				current : 1,
				pageSize: 20,
				total   : 0,
			},
			orderByField        : config.orderByField,
			orderBySort         : config.orderBySort,
			search              : "",
			// Actions
			showModalVisible    : false,
			exportModalVisible  : false,
			// Users
			userShowModalVisible: false,
		};
	}

	componentDidMount() {
		// Fecth all
		this.fetchGetAll();
	}

	menuMain = () => (
		<Menu className="actions-dropdown-menu">
			{this.props.permissions.includes(config.permissionPrefix + ".export") && <Menu.Item key="export">
				<a onClick={this.exportOpen}>
					<Icon type="export" />Exportar
				</a>
			</Menu.Item>}
		</Menu>
	);

	menu = (item) => (
		<Menu className="actions-dropdown-menu">
			{this.props.permissions.includes(config.permissionPrefix + ".show") && <Menu.Item key="show">
				<a onClick={() => this.showOpen(item)}>
					<Icon type="file-text" />Visualizar
				</a>
			</Menu.Item>}
			{this.props.permissions.includes(config.permissionPrefix + ".delete") && <Menu.Item key="delete">
				<a onClick={() => this.deleteConfirm(item)}>
					<Icon type="delete" />Excluir
				</a>
			</Menu.Item>}
		</Menu>
	);

	columns = () => {
		let columns = [
			{
				title    : "ID",
				key      : "id",
				dataIndex: "id",
				width    : 90,
				sorter   : true,
				fixed    : "left",
			},
			{
				title    : "Tipo",
				key      : "comment_type",
				dataIndex: "comment_type",
				width    : 200,
				sorter   : false,
				className: "disable-sort",
				render   : (text, item) => {
					if( text === "disease" )
					{
						return "Doença";
					}
					else if( text === "nutritional_deficiency" )
					{
						return "Deficiência nutricional";
					}
					else if( text === "pest" )
					{
						return "Praga";
					}
					else if( text === "variety" )
					{
						return "Variedade";
					}
					else if( text === "weed" )
					{
						return "Planta daninha";
					}
					else if( text === "machine_implement" )
					{
						return "Máquinas/Implementos"
					}

					return null;
				},
			},
			{
				title    : "Usuário",
				key      : "user.name",
				dataIndex: "user.name",
				width    : 200,
				sorter   : true,
				render   : (text, item) => (
					this.props.permissions.includes(config.userPermissionPrefix + ".show") ? <a onClick={() => this.userShowOpen(item.user)}>
						{text}
					</a> : text
				),
			},
			{
				title    : "Mensagem",
				key      : "message",
				dataIndex: "message",
				sorter   : true,
			},
			{
				title    : "Curtidas",
				key      : "likes_count",
				dataIndex: "likes_count",
				width    : 110,
				sorter   : true,
			},
			{
				title           : "Criação",
				key             : "created_at",
				dataIndex       : "created_at",
				width           : 150,
				sorter          : true,
				defaultSortOrder: "descend",
				render          : (text) => moment(text).format("DD/MM/YYYY HH:mm"),
			},
			{
				title    : "Últ. modificação",
				key      : "updated_at",
				dataIndex: "updated_at",
				width    : 160,
				sorter   : true,
				fixed    : window.innerWidth > 991 ? "right" : false,
				render   : (text) => moment(text).format("DD/MM/YYYY HH:mm"),
			},
		];

		if( this.props.permissions.includes(config.permissionPrefix + ".show") || this.props.permissions.includes(config.permissionPrefix + ".delete") )
		{
			columns.push({
				title    : "Ações",
				key      : "actions",
				width    : 78,
				className: "actions disable-sort",
				fixed    : window.innerWidth > 767 ? "right" : false,
				render   : (text, item) => (
					<Dropdown overlay={this.menu(item)} className="actions-dropdown" placement="bottomRight" trigger={["click"]}>
						<Button icon="more" />
					</Dropdown>
				),
			});
		}

		return columns;
	};

	fetchGetAll = () => {
		const {pagination, orderByField, orderBySort, search} = this.state;

		this.setState({
			isLoading: true,
		});

		commentService.getAll({
			page   : pagination.current,
			limit  : pagination.pageSize,
			orderBy: `${orderByField}:${orderBySort}`,
			search : search,
		})
		.then((response) => {
			this.setState(state => ({
				isLoading : false,
				data      : response.data.data,
				pagination: {
					...state.pagination,
					total: response.data.meta.total,
				},
			}));
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
			});
		});
	};

	handleTableChange = (pagination, filters, sorter) => {
		this.setState(state => ({
			pagination  : {
				...state.pagination,
				current : pagination.current,
				pageSize: pagination.pageSize,
			},
			orderByField: sorter.field ? sorter.field : config.orderByField,
			orderBySort : sorter.order === "descend" ? "desc" : "asc",
		}), () => {
			this.fetchGetAll();
		});
	};

	onSearch = (value) => {
		this.setState({
			search: value,
		}, () => {
			this.fetchGetAll();
		});
	};

	onSearchChange = (e) => {
		// If it does not have type then it's cleaning
		if( !e["type"] )
		{
			const {search} = this.state;

			this.setState({
				search: e.target.value,
			}, () => {
				if( search )
				{
					this.fetchGetAll();
				}
			});
		}
	};

	/**
	 * Show
	 *
	 * @param id
	 */
	showOpen = ({id}) => {
		this.setState({showModalVisible: true});

		// On open screen
		this.showScreen.onOpen(id);
	};

	showOnClose = () => {
		this.setState({showModalVisible: false});
	};

	/**
	 * Delete
	 *
	 * @param id
	 */
	deleteConfirm = ({id}) => {
		Modal.confirm({
			title  : "Confirmar exclusão!",
			content: "Tem certeza de que deseja excluir este registro?",
			okText : "Excluir",
			onOk   : () => {
				return this.deleteConfirmed(id);
			}
		});
	};

	deleteConfirmed = (id) => {
		return commentService.destroy({id})
		.then((response) => {
			// Fecth all
			this.fetchGetAll();
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
			});
		});
	};

	/**
	 * Export
	 */
	exportOpen = () => {
		this.setState({exportModalVisible: true});
	};

	exportOnClose = () => {
		this.setState({exportModalVisible: false});
	};

	exportOnComplete = () => {
		this.setState({exportModalVisible: false});
	};

	/**
	 * Show user
	 *
	 * @param id
	 */
	userShowOpen = ({id}) => {
		this.setState({userShowModalVisible: true});

		// On open screen
		this.userShowScreen.onOpen(id);
	};

	userShowOnClose = () => {
		this.setState({userShowModalVisible: false});
	};

	render() {
		return (
			<DefaultTemplate>
				<div className="page-content" key="1">
					<h1 className="page-title">{config.title}</h1>
					<div className="page-listing-header">
						<div className="search">
							<Input.Search
								placeholder="Pesquisar"
								onSearch={this.onSearch}
								onChange={this.onSearchChange}
								enterButton
								allowClear
							/>
						</div>
						{this.props.permissions.includes(config.permissionPrefix + ".export") && (
							<div className="actions">
								<Dropdown className="actions-dropdown" placement="bottomRight" trigger={["click"]} overlay={this.menuMain}>
									<Button type="primary" icon="more" />
								</Dropdown>
							</div>
						)}
					</div>
					{this.state.search && <Typography.Paragraph>Buscando por <Typography.Text mark>{this.state.search}</Typography.Text></Typography.Paragraph>}
					<Table
						className="page-listing-body"
						columns={this.columns()}
						rowKey="id"
						bordered
						scroll={{x: 1600}}
						dataSource={this.state.data}
						pagination={{...this.state.pagination, showSizeChanger: false, showQuickJumper: false, hideOnSinglePage: true}}
						loading={this.state.isLoading}
						onChange={this.handleTableChange}
						footer={() => `Total ${this.state.pagination.total} de registros encontrados.`}
					/>
				</div>
				<ModalShow
					ref={el => this.showScreen = el}
					visible={this.state.showModalVisible}
					onClose={this.showOnClose}
				/>
				<ModalExport
					visible={this.state.exportModalVisible}
					onComplete={this.exportOnComplete}
					onClose={this.exportOnClose}
				/>
				<UserModalShow
					ref={el => this.userShowScreen = el}
					visible={this.state.userShowModalVisible}
					onClose={this.userShowOnClose}
					external={true}
				/>
			</DefaultTemplate>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
	};
};

export default connect(mapStateToProps)(Index);

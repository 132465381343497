import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Modal,
  Row,
  Spin,
  Switch,
  Upload,
} from "antd";

import moment from "moment";

import { animalService } from "../../../redux/services";

const FormItem = Form.Item;

class Show extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      owner_id: 0,
      id: 0,
      item: {},
      // Images
      imagePreviewVisible: false,
      imagePreviewImage: "",
      imagePreviewType: "image",
      imageList: {
        media: [],
      },
    };
  }

  fieldOptions = {
    name: {
      label: "Nome",
    },
    text: {
      label: "Texto",
    },
    start_open: {
      label: "Iniciar aberto",
    },
    order: {
      label: "Ordem",
    },
    is_active: {
      label: "Ativo",
    },
    created_at: {
      label: "Criação",
    },
    updated_at: {
      label: "Última atualização",
    },
  };

  onOpen = (owner_id, id) => {
    this.setState({
      isLoading: true,
      owner_id: owner_id,
      id: id,
      item: {},
    });

    animalService
      .showContent({
        owner_id,
        id,
      })
      .then((response) => {
        let item = response.data.data;
        let imageList = {
          media: [],
        };

        if (item.media.length) {
          item.media.forEach((image) => {
            let mediaType = image.type === "video" ? "video" : "image";

            imageList.media.push({
              uid: image.id,
              name: image.file.split("/").pop(),
              status: "done",
              url: mediaType === "video" ? image.video : image.file,
              mediaType: mediaType,
              // Has id, is api image
              id: image.id,
            });
          });
        }

        this.setState({
          isLoading: false,
          item: item,
          imageList: imageList,
        });
      })
      .catch((data) => {
        this.setState({
          isLoading: false,
        });

        Modal.error({
          title: "Ocorreu um erro!",
          content: data.error_message,
          onOk: () => {
            // Force close
            return this.onClose();
          },
        });
      });
  };

  resetFields = () => {
    this.setState({
      item: {},
      imageList: {
        media: [],
      },
    });
  };

  onClose = () => {
    // Reset fields
    this.resetFields();

    // Callback
    this.props.onClose();
  };

  onImagePreviewClose = () => this.setState({ imagePreviewVisible: false });

  onImagePreview = (type, file) => {
    this.setState({
      imagePreviewImage: file.url,
      imagePreviewType: file.mediaType,
      imagePreviewVisible: true,
    });
  };

  renderImages(type) {
    const imageList = this.state.imageList[type];

    if (!imageList.length) {
      return <div className="clearfix">Sem imagem</div>;
    }

    return (
      <div className="media-images-wrap">
        <Upload
          listType="picture-card"
          className="media-images-uploader media-images-view"
          fileList={imageList}
          onPreview={(file) => this.onImagePreview(type, file)}
        />
      </div>
    );
  }

  render() {
    const { visible } = this.props;
    const {
      id,
      isLoading,
      item,
      imagePreviewVisible,
      imagePreviewImage,
      imagePreviewType,
    } = this.state;

    return (
      <Drawer
        visible={visible}
        className="drawer-form"
        width={520}
        maskClosable={false}
        closable={false}
        keyboard={false}
        placement="right"
        onClose={this.onClose}
      >
        <Form layout="vertical">
          <div className="form-header">
            <Button
              className="btn-close"
              onClick={this.onClose}
              icon="close"
              disabled={isLoading}
            />
            <div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
          </div>
          {isLoading ? (
            <div className="text-center" style={{ padding: 20 }}>
              <Spin
                indicator={
                  <Icon type="loading" style={{ fontSize: 60 }} spin />
                }
              />
            </div>
          ) : (
            <div className="form-body">
              <FormItem label="Imagens/Vídeos">
                {this.renderImages("media")}
              </FormItem>
              <FormItem label={this.fieldOptions.name.label}>
                {item.name}
              </FormItem>
              <FormItem label={this.fieldOptions.text.label}>
                <div className="show-break-lines">{item.text}</div>
              </FormItem>
              <FormItem label={this.fieldOptions.order.label}>
                {item.order}
              </FormItem>
              <Row gutter={16}>
                <Col xs={12}>
                  <FormItem label={this.fieldOptions.is_active.label}>
                    <Switch disabled checked={item.is_active} />
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem label={this.fieldOptions.start_open.label}>
                    <Switch disabled checked={item.start_open} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <FormItem label={this.fieldOptions.created_at.label}>
                    {moment(item.created_at).calendar()}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem label={this.fieldOptions.updated_at.label}>
                    {moment(item.updated_at).calendar()}
                  </FormItem>
                </Col>
              </Row>
            </div>
          )}
        </Form>
        <Modal
          className="modal-image"
          visible={imagePreviewVisible}
          footer={null}
          destroyOnClose={true}
          onCancel={this.onImagePreviewClose}
        >
          {imagePreviewType === "video" ? (
            <video controls autoPlay>
              <source src={imagePreviewImage} type="video/mp4" />
            </video>
          ) : (
            <img src={imagePreviewImage} />
          )}
        </Modal>
      </Drawer>
    );
  }
}

export default Show;

import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const RouteSession = ({component: Component, ...rest}) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				rest.isAuthenticated ? <Redirect to="/" /> : <Component {...props} />
			)}
		/>
	)
};

const mapStateToProps = (state, ownProps) => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
	};
};

export default connect(mapStateToProps)(RouteSession);

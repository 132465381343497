import { apiv3 } from "../../config/api";
import { appendToFormData } from "../../helpers/form";

const basePath = "tokens";

/**
 * Get all
 *
 * @param {Object} options
 * @param {number} [options.page]
 * @param {number} [options.limit]
 * @param {string} [options.search]
 * @param {string} [options.orderBy]
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
  const options_default = {};

  // Merge config
  options = Object.assign({}, options_default, options);

  let params = [];
  let params_qs = "";

  if (options.hasOwnProperty("page")) {
    params.push(`page=${options.page}`);
  }

  if (options.hasOwnProperty("limit")) {
    params.push(`limit=${options.limit}`);
  }

  if (options.hasOwnProperty("search")) {
    params.push(`search=${options.search}`);
  }

  if (options.hasOwnProperty("orderBy")) {
    params.push(`orderBy=${options.orderBy}`);
  }

  if (params.length) {
    params_qs = `?${params.join("&")}`;
  }

  return apiv3.get(`${basePath}${params_qs}`);
};

/**
 * Show
 *
 * @param {Object} options
 * @param {number} options.id
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
  return apiv3.get(`${basePath}/${options.id}`);
};

/**
 * Create
 *
 * @param {Object} options
 * @param {Object} options.file - file
 * @param {Object} [options.pdf] - file
 * @param {string} options.name
 * @param {number} options.order
 * @param {boolean} [options.is_active]
 *
 * @returns {Promise<T>}
 */
export const create = (options) => {
  const formData = new FormData();

  for (let key in options) {
    if (options.hasOwnProperty(key)) {
      appendToFormData(formData, key, options[key]);
    }
  }

  return apiv3.post(basePath, formData);
};

/**
 * Edit
 *
 * @param {Object} options
 * @param {number} options.id
 * @param {Object} [options.file] - file
 * @param {Object} [options.pdf] - file
 * @param {string} [options.name]
 * @param {number} [options.order]
 * @param {boolean} [options.is_active]
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
  const formData = new FormData();

  for (let key in options) {
    if (options.hasOwnProperty(key)) {
      appendToFormData(formData, key, options[key]);
    }
  }

  return apiv3.post(`${basePath}/${options.id}`, formData);
};

/**
 * Delete
 *
 * @param {Object} options
 * @param {number} options.id
 *
 * @returns {Promise<T>}
 */
export const destroy = (options) => {
  return apiv3.delete(`${basePath}/${options.id}`);
};

/**
 * Export
 *
 * @param {Object} options
 * @param {string} [options.date_start]
 * @param {string} [options.date_end]
 *
 * @returns {Promise<T>}
 */
export const exportItens = (options) => {
  let params = [];
  let params_qs = "";

  if (options.hasOwnProperty("date_start")) {
    params.push(`date_start=${options.date_start}`);
  }

  if (options.hasOwnProperty("date_end")) {
    params.push(`date_end=${options.date_end}`);
  }

  if (params.length) {
    params_qs = `?${params.join("&")}`;
  }

  return apiv3.get(`${basePath}/export${params_qs}`);
};

/**
 * Get contents
 *
 * @param {Object} options
 * @param {number} options.owner_id
 * @param {number} [options.page]
 * @param {number} [options.limit]
 * @param {string} [options.search]
 * @param {string} [options.orderBy]
 *
 * @returns {Promise<T>}
 */
export const getContents = (options) => {
  const options_default = {};

  // Merge config
  options = Object.assign({}, options_default, options);

  let params = [];
  let params_qs = "";

  if (options.hasOwnProperty("page")) {
    params.push(`page=${options.page}`);
  }

  if (options.hasOwnProperty("limit")) {
    params.push(`limit=${options.limit}`);
  }

  if (options.hasOwnProperty("search")) {
    params.push(`search=${options.search}`);
  }

  if (options.hasOwnProperty("orderBy")) {
    params.push(`orderBy=${options.orderBy}`);
  }

  if (params.length) {
    params_qs = `?${params.join("&")}`;
  }

  return apiv3.get(`${basePath}/${options.owner_id}/contents${params_qs}`);
};

/**
 * Show content
 *
 * @param {Object} options
 * @param {number} options.owner_id
 * @param {number} options.id
 *
 * @returns {Promise<T>}
 */
export const showContent = (options) => {
  return apiv3.get(`${basePath}/${options.owner_id}/contents/${options.id}`);
};

/**
 * Create content
 *
 * @param {Object} options
 * @param {number} options.owner_id
 * @param {string} options.name
 * @param {string} [options.text]
 * @param {string} [options.start_open]
 * @param {number} options.order
 * @param {boolean} [options.is_active]
 * @param {Object[]} [options.media] - files
 *
 * @returns {Promise<T>}
 */
export const createContent = (options) => {
  const formData = new FormData();

  for (let key in options) {
    if (options.hasOwnProperty(key)) {
      appendToFormData(formData, key, options[key]);
    }
  }

  return apiv3.post(`${basePath}/${options.owner_id}/contents`, formData);
};

/**
 * Edit content
 *
 * @param {Object} options
 * @param {number} options.owner_id
 * @param {number} options.id
 * @param {string} [options.name]
 * @param {string} [options.text]
 * @param {string} [options.start_open]
 * @param {number} [options.order]
 * @param {boolean} [options.is_active]
 * @param {Object[]} [options.media] - files
 * @param {number[]} [options.delete_media] - IDs to delete
 *
 * @returns {Promise<T>}
 */
export const editContent = (options) => {
  const formData = new FormData();

  for (let key in options) {
    if (options.hasOwnProperty(key)) {
      appendToFormData(formData, key, options[key]);
    }
  }

  return apiv3.post(
    `${basePath}/${options.owner_id}/contents/${options.id}`,
    formData
  );
};

/**
 * Delete content
 *
 * @param {Object} options
 * @param {number} options.owner_id
 * @param {number} options.id
 *
 * @returns {Promise<T>}
 */
export const destroyContent = (options) => {
  return apiv3.delete(`${basePath}/${options.owner_id}/contents/${options.id}`);
};

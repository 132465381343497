import { api } from "./../../config/api";
import { appendToFormData } from "./../../helpers/form";

const basePath = "videos";

/**
 * Index
 *
 * @param {Object} options
 * @param {number} options.id
 * @param {string} options.type
 * 
 * @returns {Promise<T>}
 */
export const index = (options) => {
	return api.get(`${options.type}/${options.id}/${basePath}`);
};


/**
 * Show
 *
 * @param {Object} options
 * @param {number} options.id
 * @param {string} options.type
 * 
 * @returns {Promise<T>}
 */
export const show = (options) => {
	return api.get(`${options.type}/${options.content_id}/${basePath}/${options.id}`);
};



/**
 * Create
 *
 * @param {Object} options
 * @param {number} options.id
 * @param {string} options.type
 *
 * @returns {Promise<T>}
 */
 export const create = (options) => {
	const formData = new FormData();

	for( let key in options )
	{
		if( options.hasOwnProperty(key) )
		{
			appendToFormData(formData, key, options[key]);
		}
	}

	return api.post(`${options.type}/${options.id}/${basePath}`, formData);
};



/**
 * Create
 *
 * @param {Object} options
 * @param {number} options.id
 * @param {string} options.type
 *
 * @returns {Promise<T>}
 */
 export const edit = (options) => {
	const formData = new FormData();

	for( let key in options )
	{
		if( options.hasOwnProperty(key) )
		{
			appendToFormData(formData, key, options[key]);
		}
	}

	return api.post(`${options.type}/${options.content_id}/${basePath}/${options.id}`, formData);
};



/**
 * Delete
 *
 * @param {Object} options
 * @param {number} options.id
 * @param {number} options.content_id
 * @param {string} options.type
 *
 * @returns {Promise<T>}
 */
export const destroy = (options) => {
	return api.delete(`${options.type}/${options.content_id}/${basePath}/${options.id}`);
};



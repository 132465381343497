import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Button, Col, Drawer, Form, Icon, Modal, Row, Spin, Switch, Upload} from "antd";

import moment from "moment";

import {tokensService} from "./../../redux/services";

const FormItem = Form.Item;

class Show extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            item: {},
        };
    }

    fieldOptions = {
        name: {
            label: "Nome",
        },
        token: {
            label: "Token",
        },
        expiration: {
            label: "Validade",
        },
        created_at: {
            label: "Criação",
        },
        updated_at: {
            label: "Última atualização",
        },
    };

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            item: {},
        });

        tokensService.show({id})
            .then((response) => {
                let item = response.data.data;

                this.setState({
                    isLoading: false,
                    item: item,
                });
            })
            .catch((data) => {
                this.setState({
                    isLoading: false,
                });

                Modal.error({
                    title: "Ocorreu um erro!",
                    content: data.error_message,
                    onOk: () => {
                        // Force close
                        return this.onClose();
                    }
                });
            });
    };

    resetFields = () => {
        this.setState({
            item: {},
        });
    };

    onClose = () => {
        // Reset fields
        this.resetFields();

        // Callback
        this.props.onClose();
    };

    render() {
        const {visible} = this.props;
        const {id, isLoading, item} = this.state;

        return (
            <Drawer
                visible={visible}
                className="drawer-form"
                width={"50%"}
                maskClosable={false}
                closable={false}
                keyboard={true}
                placement="right"
                onClose={this.onClose}>
                <Form layout="vertical">
                    <div className="form-header">
                        <Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading}/>
                        <div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
                    </div>
                    {isLoading ? (
                        <div className="text-center" style={{padding: 20}}>
                            <Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin/>}/>
                        </div>
                    ) : (
                        <div className="form-body">
                            <FormItem label={this.fieldOptions.name.label}>
                                {item.name}
                            </FormItem>
                            <FormItem label={this.fieldOptions.token.label}>
                                {item.token}
                            </FormItem>
                            <FormItem label={this.fieldOptions.expiration.label}>
                                {moment(item.expiration).format('DD/MM/YYYY')}
                            </FormItem>
                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <FormItem label={this.fieldOptions.created_at.label}>
                                        {moment(item.created_at).calendar()}
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <FormItem label={this.fieldOptions.updated_at.label}>
                                        {moment(item.updated_at).calendar()}
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Form>
            </Drawer>
        )
    }
}

export default Show;

import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Drawer, Form, Icon, Modal, Spin, Tag } from "antd";
import ReactJson from "react-json-view";

import moment from "moment";

import { systemLogService } from "./../../redux/services";

const FormItem = Form.Item;

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			id       : 0,
			item     : {},
		};
	}

	fieldOptions = {
		message   : {
			label: "Mensagem",
		},
		user      : {
			label: "Usuário",
		},
		level     : {
			label: "Level",
		},
		ip        : {
			label: "IP",
		},
		user_agent: {
			label: "User agent",
		},
		url       : {
			label: "Url",
		},
		created_at: {
			label: "Criação",
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		systemLogService.show({id})
		.then((response) => {
			this.setState({
				isLoading: false,
				item     : response.data.data,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	colorMethod = () => {
		const {item} = this.state;

		let color = "#808080";

		if( item.method === "GET" )
		{
			color = "#26B47F";
		}
		else if( item.method === "POST" )
		{
			color = "#FFB400";
		}
		else if( item.method === "PUT" )
		{
			color = "#097BED";
		}
		else if( item.method === "DELETE" )
		{
			color = "#ED4B48";
		}

		return color;
	};

	render() {
		const {visible}             = this.props;
		const {id, isLoading, item} = this.state;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={1150}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label={this.fieldOptions.message.label}>
								<Tag color={item.color}>{item.level}</Tag> {item.message}
							</FormItem>
							{item.user_id && <FormItem label={this.fieldOptions.user.label}>
								{item.user_id} - {item.user_name}
							</FormItem>}
							<FormItem label={this.fieldOptions.ip.label}>
								{item.ip}
							</FormItem>
							<FormItem label={this.fieldOptions.user_agent.label}>
								{item.user_agent}
							</FormItem>
							<FormItem label={this.fieldOptions.url.label}>
								<Tag color={this.colorMethod()}>{item.method}</Tag>{item.url}
							</FormItem>
							<FormItem label={this.fieldOptions.created_at.label}>
								{moment(item.created_at).calendar()}
							</FormItem>
							<ReactJson name="context" src={item.context} iconStyle="square" indentWidth={6} />
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Show;

import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import { Empty, Input, Table, Typography } from "antd";

import moment from "moment";

const config = {
	orderByField: "created_at",
	orderBySort : "desc",
};

class Index extends Component {
	static propTypes = {
		items: PropTypes.array.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			data        : [],
			dataRaw     : [],
			pagination  : {
				current : 1,
				pageSize: 10,
			},
			orderByField: config.orderByField,
			orderBySort : config.orderBySort,
			search      : "",
		};
	}

	componentDidMount() {
		this.setState({
			data   : this.props.items,
			dataRaw: this.props.items,
		});
	}

	columns = () => {
		return [
			{
				title    : "ID",
				key      : "id",
				dataIndex: "id",
				width    : 90,
				sorter   : true,
			},
			{
				title    : "Usuário",
				key      : "name",
				dataIndex: "user.name",
				sorter   : true,
			},
			{
				title           : "Criação",
				key             : "created_at",
				dataIndex       : "created_at",
				width           : 150,
				sorter          : true,
				defaultSortOrder: "descend",
				render          : (text) => moment(text).format("DD/MM/YYYY HH:mm"),
			},
		];
	};

	filterList = () => {
		this.setState(state => {
			let newDate = [...state.dataRaw];

			if( state.search )
			{
				let search = state.search.toLowerCase();

				newDate = newDate.filter(item => {
					if( String(item.user.name).toLowerCase().indexOf(search) !== -1 )
					{
						return true;
					}

					return false;
				});
			}

			return {
				data: orderBy(newDate, [state.orderByField], [state.orderBySort]),
			}
		});
	};

	handleTableChange = (pagination, filters, sorter) => {
		this.setState(state => ({
			pagination  : {
				...state.pagination,
				current : pagination.current,
				pageSize: pagination.pageSize,
			},
			orderByField: sorter.field ? sorter.field : config.orderByField,
			orderBySort : sorter.order === "descend" ? "desc" : "asc",
		}), () => {
			this.filterList();
		});
	};

	onSearch = (value) => {
		this.setState({
			search: value,
		}, () => {
			this.filterList();
		});
	};

	onSearchChange = (e) => {
		// If it does not have type then it's cleaning
		if( !e["type"] )
		{
			const {search} = this.state;

			this.setState({
				search: e.target.value,
			}, () => {
				if( search )
				{
					this.filterList();
				}
			});
		}
	};

	render() {
		return (
			<div>
				<div className="page-content" key="1">
					{!this.state.dataRaw.length ? (
						<Empty />
					) : (
						<Fragment>
							<div className="page-listing-header">
								<div className="search">
									<Input.Search
										placeholder="Pesquisar"
										onSearch={this.onSearch}
										onChange={this.onSearchChange}
										enterButton
										allowClear
									/>
								</div>
							</div>
							{this.state.search && <Typography.Paragraph>Buscando por <Typography.Text mark>{this.state.search}</Typography.Text></Typography.Paragraph>}
							<Table
								className="page-listing-body"
								columns={this.columns()}
								rowKey="id"
								bordered
								scroll={{x: 500}}
								dataSource={this.state.data}
								pagination={{...this.state.pagination, showSizeChanger: false, showQuickJumper: false, hideOnSinglePage: true}}
								size="small"
								onChange={this.handleTableChange}
								footer={() => `Total ${this.state.data.length} de registros encontrados.`}
							/>
						</Fragment>
					)}
				</div>
			</div>
		)
	}
}

export default Index;

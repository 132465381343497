import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Button, DatePicker, Drawer, Form, Icon, Input, message, Modal, Select, Spin, Tag, Typography } from "antd";

import { API_ERRO_TYPE_VALIDATION, API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { pushStateService, webserviceService } from "./../../redux/services";

const FormItem = Form.Item;

class Create extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			states   : [],
		};
	}

	fieldOptions = {
		state_id    : {
			label    : "Estado",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		title       : {
			label    : "Título",
			decorator: {
				initialValue: "",
			},
		},
		body        : {
			label    : "Mensagem",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		url         : {
			label    : "Url",
			decorator: {
				initialValue: "",
			},
		},
		scheduled_at: {
			label    : "Agendado para",
			decorator: {},
		},
	};

	onOpen = () => {
		this.setState({
			isLoading: true,
		});

		webserviceService.getStates()
		.then((response) => {
			this.setState({
				isLoading: false,
				states   : response.data.data,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			states: [],
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				let data = {...values};

				if( data.scheduled_at )
				{
					data.scheduled_at = data.scheduled_at.format("YYYY-MM-DDTHH:mm:ssZ");
				}

				pushStateService.create(data)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro cadastrado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	setUrl = (url) => {
		this.props.form.setFieldsValue({
			url: url,
		});
	};

	render() {
		const {visible, form}                = this.props;
		const {isLoading, isSending, states} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={360}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">Inserir novo registro</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label={this.fieldOptions.state_id.label} hasFeedback>
								{getFieldDecorator("state_id", this.fieldOptions.state_id.decorator)(
									<Select
										filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										allowClear
										showSearch>
										{states.map((item, index) => (
											<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
										))}
									</Select>
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.title.label} hasFeedback>
								{getFieldDecorator("title", this.fieldOptions.title.decorator)(
									<Input maxLength={50} />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.body.label} hasFeedback>
								{getFieldDecorator("body", this.fieldOptions.body.decorator)(
									<Input.TextArea maxLength={100} autoSize={{minRows: 3, maxRows: 6}} />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.scheduled_at.label} hasFeedback extra={<Typography>Deixe em branco para enviar agora</Typography>}>
								{getFieldDecorator("scheduled_at", this.fieldOptions.scheduled_at.decorator)(
									<DatePicker
										showTime
										format="DD/MM/YYYY HH:mm"
										style={{width: "100%"}}
									/>
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.url.label} hasFeedback>
								{getFieldDecorator("url", this.fieldOptions.url.decorator)(
									<Input />
								)}
							</FormItem>
							<div>
								<p>Utilizar página interna do app:</p>
								<Tag style={{marginBottom: 8}} onClick={() => this.setUrl("commodities-news")}>Cotações/Notícias</Tag>
								<Tag style={{marginBottom: 8}} onClick={() => this.setUrl("agronomist")}>Consulte os agrônomos</Tag>
								<Tag style={{marginBottom: 8}} onClick={() => this.setUrl("collection")}>Acervo</Tag>
								<Tag style={{marginBottom: 8}} onClick={() => this.setUrl("profile")}>Perfil</Tag>
							</div>
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Create);

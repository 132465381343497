// -----------------------------------------------------------------------------
// General
// -----------------------------------------------------------------------------
export const IS_DEBUG = process.env.REACT_APP_ENVIRONMENT;

// -----------------------------------------------------------------------------
// SideBar
// -----------------------------------------------------------------------------
export const SIDEBAR_DRAWER_MEDIA_QUERIE = "screen and (max-width: 1199px)";

// -----------------------------------------------------------------------------
// API
// -----------------------------------------------------------------------------

let api, apiv3, customer;

switch (IS_DEBUG) {
    case 'development':
        api = "https://game.agroapp.com.br/api/v1/admin/";
        apiv3 = "https://game.agroapp.com.br/api/v3/admin/";
        customer = "https://game.agroapp.com.br/api/v1/customer/";
        break;
    case 'production':
        api = "https://api.agroapp.com.br/api/v1/admin/";
        apiv3 = "https://api.agroapp.com.br/api/v3/admin/";
        customer = "https://api.agroapp.com.br/api/v1/customer/";
        break;
    case 'local':
        api = "http://localhost:8000/api/v1/admin/";
        apiv3 = "http://localhost:8000/api/v3/admin/";
        customer = "http://localhost:8000/api/v1/customer/";
        break;
}

export const API_URL = api;
export const API_URL_V3 = apiv3;
export const API_URL_CUSTOMER = customer;
// -----------------------------------------------------------------------------
// Errors
// -----------------------------------------------------------------------------
export const API_ERRO_TYPE_VALIDATION = "validation";
export const API_ERRO_TYPE_API = "api";
export const API_ERRO_TYPE_SERVER = "server";
export const API_ERRO_TYPE_CONNECTION = "connection";
export const API_ERRO_TYPE_OTHER = "other";
export const API_ERRO_TYPE_ACCESS_TOKEN = "access_token";
export const API_ERRO_TYPE_CANCEL = "cancel";

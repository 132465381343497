const authConstants = {
  LOGIN_REQUEST: "AUTH_LOGIN_REQUEST",
  LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
  LOGIN_ERROR: "AUTH_LOGIN_ERROR",

  LOGOUT: "AUTH_LOGOUT",

  REFRESH_TOKEN: "AUTH_REFRESH_TOKEN",

  USERDATA_REQUEST: "AUTH_USERDATA_REQUEST",
  USERDATA_SUCCESS: "AUTH_USERDATA_SUCCESS",
  USERDATA_ERROR: "AUTH_USERDATA_ERROR",

  UPDATE_AVATAR: "AUTH_UPDATE_AVATAR",
};

export default authConstants;

import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Button, Col, Drawer, Form, Icon, Input, Modal, Row, Select, Spin, Switch, Upload} from "antd";

import moment from "moment";

import {publicationService} from "./../../redux/services";
import TextEditor from "../../components/TextEditor";

const FormItem = Form.Item;

class Show extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            id: 0,
            item: {},
            // Images
            imagePreviewVisible: false,
            imagePreviewImage: "",
            imageList: {
                file: [],
            },
            publicationTypes: {},
            activePublicationType: {}
        };
    }
    fieldOptionsModel = {
        title: {
            label: "Título",
            decorator: {
                rules: [],
            },
        },
        text: {
            label: "Texto",
            decorator: {
                initialValue: "",
                rules: [],
            },
        },
        author: {
            label: "Autor",
            decorator: {
                rules: [],
            },
        },
        link: {
            label: "Link",
            decorator: {
                rules: [],
            },
        },
    };
    fieldOptions = {
        publication_type: {
            label: "Tipo",
            decorator: {
                rules: [],
                readOnly: true
            },
        },
        is_active: {
            label: "Ativo",
        },
        created_at: {
            label: "Criação",
        },
        updated_at: {
            label: "Última atualização",
        },
    };

    onOpen = (id, publicationTypes) => {
        this.setState({
            isLoading: true,
            id: id,
            item: {},
        });

        publicationService.show({id})
            .then((response) => {
                let item = response.data.data;
                item.publication_type = item.type_id || 1;
                const activePublicationType = publicationTypes.find(type => type.id === item.publication_type);
                this.setState({
                    isLoading: false,
                });

                const keys = Object.keys(activePublicationType);

                for(const key of keys){
                    if(this.fieldOptionsModel[key]){
                        if(!this.fieldOptions[activePublicationType.id]){
                            this.fieldOptions[activePublicationType.id] = {};
                        }
                        this.fieldOptions[activePublicationType.id][key] = JSON.parse(JSON.stringify(this.fieldOptionsModel[key]));
                        if(activePublicationType[key] === 1) {
                            this.fieldOptions[activePublicationType.id][key].decorator.rules.push({required: true, message: "Campo obrigatório."});
                        }
                    }
                }

                let imageAdsList = {
                    images: [],
                };

                let imageList = {
                    file: [],
                    files: [],
                }

                let file = {
                    mp3: [],
                    mp4: [],
                    pdf: []
                };

                if (item.ads) {
                    imageAdsList.images.push({
                        uid: item.id,
                        name: item.ads.split("/").pop(),
                        status: "done",
                        url: item.ads,
                        // Has id, is api image
                        id: item.id,
                    });
                }

                if (item.banner_top) {
                    imageAdsList.images.push({
                        uid: item.id,
                        name: item.banner_top.split("/").pop(),
                        status: "done",
                        url: item.banner_top,
                        // Has id, is api image
                        id: item.id,
                    });
                }

                if (item.banner_foot) {
                    imageAdsList.images.push({
                        uid: item.id,
                        name: item.banner_foot.split("/").pop(),
                        status: "done",
                        url: item.banner_foot,
                        // Has id, is api image
                        id: item.id,
                    });
                }

                if (item.images && item.images.length) {
                    for (let image of item.images) {
                        imageList.files.push({
                            uid: image.id,
                            name: image.file.split("/").pop(),
                            status: "done",
                            url: image.file,
                            // Has id, is api image
                            id: image.id,
                        })
                    }
                }

                if (item.audio) {
                    file.mp3.push({
                        uid: item.id,
                        name: item.audio.split("/").pop(),
                        status: "done",
                        url: item.audio,
                        // Has id, is api item.audio
                        id: item.id,
                    });
                }

                if (item.video) {
                    file.mp4.push({
                        uid: item.id,
                        name: item.video.split("/").pop(),
                        status: "done",
                        url: item.video,
                        // Has id, is api item.video
                        id: item.id,
                    });
                }

                if (item.pdf) {
                    file.pdf.push({
                        uid: item.id,
                        name: item.pdf.split("/").pop(),
                        status: "done",
                        url: item.pdf,
                        // Has id, is api item.pdf
                        id: item.id,
                    });
                }

                this.setState({
                    publicationTypes,
                    activePublicationType,
                    imageAdsList,
                    imageList,
                    file,
                    item
                });

            })
            .catch((data) => {
                this.setState({
                    isLoading: false,
                });

                Modal.error({
                    title: "Ocorreu um erro!",
                    content: data.error_message,
                    onOk: () => {
                        // Force close
                        return this.onClose();
                    }
                });
            });
    };

    resetFields = () => {
        this.setState({
            isLoading: false,
            id: 0,
            item: {},
            // Images
            imagePreviewVisible: false,
            imagePreviewImage: "",
            imageList: {
                file: [],
            },
            publicationTypes: {},
            activePublicationType: {}
        });
    };

    onClose = () => {
        // Reset fields
        this.resetFields();

        // Callback
        this.props.onClose();
    };

    onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

    onImagePreview = (type, file) => {
        this.setState({
            imagePreviewImage: file.url,
            imagePreviewVisible: true,
        });
    };

    renderImages(type) {
        const imageList = this.state.imageList[type];

        if (!imageList.length) {
            return (
                <div className="clearfix">
                    Sem imagem
                </div>
            );
        }

        return (
            <div className="media-images-wrap">
                <Upload
                    listType="picture-card"
                    className="media-images-uploader media-images-view"
                    fileList={imageList}
                    onPreview={(file) => this.onImagePreview(type, file)}
                />
            </div>
        );
    }

    renderUpload(type) {
        const tiposAceitos = ['pdf', 'mp3', 'mp4'];
        if (!tiposAceitos.includes(type)) {
            return <>Formato <strong>{type}</strong> não encontrado. Formatos aceitos {tiposAceitos.join(', ')}</>
        }
        const file = this.state.file[type] || [];

        return (
            <Upload
                accept={`.${type.toLowerCase()}`}
                fileList={file}
                onRemove={(file) => {
                    this.setState(state => ({
                        file: {
                            ...state.file,
                            [type]: [],
                        },
                    }));
                }}
                beforeUpload={(file) => {
                    this.setState(state => ({
                        file: {
                            ...state.file,
                            [type]: [file],
                        },
                    }));

                    return false;
                }}>
                {file.length === 0 && <Button>
                    <Icon type="upload"/> Selecionar arquivo
                </Button>}
            </Upload>
        );
    }

    render() {
        const {visible} = this.props;
        const {
            id,
            isLoading,
            isSending,
            imagePreviewVisible,
            imagePreviewImage,
            imageAdsPreviewVisible,
            imageAdsPreviewImage,
            publicationTypes,
            activePublicationType,
            item
        } = this.state;

        return (
            <Drawer
                visible={visible}
                className="drawer-form"
                width={500}
                maskClosable={false}
                closable={false}
                keyboard={false}
                placement="right"
                onClose={this.onClose}>
                <Form layout="vertical">
                    <div className="form-header">
                        <Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading}/>
                        <div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
                    </div>
                    {isLoading ? (
                        <div className="text-center" style={{padding: 20}}>
                            <Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin/>}/>
                        </div>
                    ) : (
                        <div className="form-body">
                            <FormItem label={this.fieldOptions.publication_type.label} hasFeedback>
                                {activePublicationType && activePublicationType.description}
                            </FormItem>

                            {activePublicationType?.id && <>
                                {activePublicationType.author > 0 &&
                                    <FormItem label={this.fieldOptions[activePublicationType.id.toString()].author.label} hasFeedback>
                                        {item.author}
                                    </FormItem>
                                }
                                {activePublicationType.title > 0 &&
                                    <FormItem label={this.fieldOptions[activePublicationType.id.toString()].title.label} hasFeedback>
                                        {item.title}
                                    </FormItem>
                                }
                                {activePublicationType.text > 0 &&
                                    <FormItem label={this.fieldOptions[activePublicationType.id.toString()].text.label} hasFeedback>
                                        {item.text}
                                    </FormItem>
                                }
                                {activePublicationType.images > 0 &&
                                    <FormItem label="Imagens">
                                        {this.renderImages("files", 'images')}
                                    </FormItem>
                                }
                                {activePublicationType.thumbnail > 0 &&
                                    <FormItem label="Thumbnail">
                                        {this.renderImages("file", 'thumbnail')}
                                    </FormItem>
                                }
                                {activePublicationType.ads > 0 &&
                                    <FormItem label="Ads">
                                        {this.renderAds("images")}
                                        <small>Dimensões: (940x780px)</small>
                                    </FormItem>
                                }
                                {activePublicationType.banner_top > 0 &&
                                    <FormItem label="Banner Topo">
                                        {this.renderImages("file", 'banner_top')}
                                    </FormItem>
                                }
                                {activePublicationType.banner_foot > 0 &&
                                    <FormItem label="Banner Rodapé">
                                        {this.renderImages("file", 'banner_foot')}
                                    </FormItem>
                                }
                                {activePublicationType.pdf > 0 &&
                                    <FormItem label="Pdf">
                                        {this.renderUpload("pdf")}
                                    </FormItem>
                                }
                                {activePublicationType.video > 0 &&
                                    <FormItem label="Vídeo">
                                        {this.renderUpload("mp4")}
                                    </FormItem>
                                }
                                {activePublicationType.audio > 0 &&
                                    <FormItem label="Áudio">
                                        {this.renderUpload("mp3")}
                                    </FormItem>
                                }
                                {activePublicationType.link > 0 &&
                                    <FormItem label={this.fieldOptions[activePublicationType.id.toString()].link.label} hasFeedback>
                                        {item.link}
                                    </FormItem>
                                }
                                <FormItem label={this.fieldOptions.is_active.label}>
                                    <Switch disabled checked={item.is_active}/>
                                </FormItem>
                            </>
                            }
                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <FormItem label={this.fieldOptions.created_at.label}>
                                        {moment(item.created_at).calendar()}
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <FormItem label={this.fieldOptions.updated_at.label}>
                                        {moment(item.updated_at).calendar()}
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Form>
                <Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true}
                       onCancel={this.onImagePreviewClose}>
                    <img src={imagePreviewImage}/>
                </Modal>
            </Drawer>
        )
    }
}

export default Show;

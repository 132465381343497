import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Avatar, Button, Col, Drawer, Form, Icon, Modal, Rate, Row, Select, Spin } from "antd";

import moment from "moment";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { denunciationService } from "./../../redux/services";

const FormItem = Form.Item;

class Show extends Component {
	static propTypes = {
		visible     : PropTypes.bool.isRequired,
		onClose     : PropTypes.func.isRequired,
		onDeleteItem: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading                : true,
			isSending                : false,
			id                       : 0,
			item                     : {},
			// Delete
			deleteModalVisible       : false,
			deleteModalConfirmLoading: false,
		};
	}

	fieldOptions = {
		id               : {
			label: "ID",
		},
		denunciation_type: {
			label: "Tipo",
		},
		created_at       : {
			label: "Denúnciado em",
		},
		user             : {
			label: "Usuário",
		},
	};

	deleteFieldOptions = {
		reason: {
			label    : "Motivo",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."}
				],
			}
		}
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		denunciationService.show({id})
		.then((response) => {
			this.setState({
				isLoading: false,
				item     : response.data.data,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	itemType = (val) => {
		if( val === "customer_ask_agronomist_answer" )
		{
			return "Resposta em consulte os agrônomos";
		}
		else if( val === "customer_comment" )
		{
			return "Comentário";
		}
		else if( val === "customer_rating" )
		{
			return "Avaliação";
		}
	};

	deleteItemConfirm = () => {
		this.setState({deleteModalVisible: true});
	};

	deleteItemOnCancel = () => {
		if( this.state.deleteModalConfirmLoading ) return null;

		this.setState({
			deleteModalVisible       : false,
			deleteModalConfirmLoading: false
		});
	};

	deleteItemOnConfirm = () => {
		this.props.form.validateFields((error, values) => {
			if( !error )
			{
				this.setState({
					deleteModalConfirmLoading: true
				});

				const {item} = this.state;

				denunciationService.destroyItem({
					id    : item.id,
					reason: values.reason,
				})
				.then((response) => {
					this.setState({
						deleteModalVisible       : false,
						deleteModalConfirmLoading: false
					});

					// Callback
					this.props.onDeleteItem();
				})
				.catch((data) => {
					this.setState({
						deleteModalConfirmLoading: false
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.deletefieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	render() {
		const {visible, form}                  = this.props;
		const {id, isLoading, isSending, item} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={600}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
						<Button type="danger" className="btn-save" icon="delete" loading={isSending} disabled={isLoading} onClick={() => this.deleteItemConfirm()}>Remover item denunciado</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.denunciation_type.label}>
										{this.itemType(item.denunciation_type)}
									</FormItem>
								</Col>
								<Col xs={24} sm={12}>
									<FormItem label={this.fieldOptions.created_at.label}>
										{moment(item.created_at).calendar()}
									</FormItem>
								</Col>
							</Row>
							<FormItem label={this.fieldOptions.user.label}>
								{item.user && (
									<Row gutter={16}>
										<Col xs={24} sm={4}>
											<p>{item.user.avatar ? <Avatar size={64} src={item.user.avatar_sizes.admin_listing} /> : <Avatar size={64} icon="user" />}</p>
										</Col>
										<Col xs={24} sm={20}>
											{item.user.name}<br />
											<b>Celular:</b> {item.user.customer.cellphone}<br />
											<b>E-mail:</b> {item.user.email}<br />
											<b>Atividade:</b> {item.user.customer.activity.name}
										</Col>
									</Row>
								)}
							</FormItem>
							<h3>Informações do item denunciado</h3>
							{item.item && (
								<div>
									{item.item.name}<br />
									<b>Tipo:</b> {this.itemType(item.denunciation_type)}<br />
									<b>ID:</b> {item.denunciation_id}<br />
									{item.denunciation_type === "customer_ask_agronomist_answer" && (
										<Fragment>
											<b>Mensagem:</b> <br />{item.item.message}<br />
										</Fragment>
									)}
									{item.denunciation_type === "customer_comment" && (
										<Fragment>
											<b>Mensagem:</b> <br />{item.item.message}<br />
										</Fragment>
									)}
									{item.denunciation_type === "customer_rating" && (
										<Fragment>
											<b>Nota:</b> <Rate disabled defaultValue={item.item.star} /><br />
											<b>Mensagem:</b> <br />{item.item.message}<br />
										</Fragment>
									)}
									<br />
									{item.item.user && (
										<Row gutter={16}>
											<Col xs={24} sm={4}>
												<p>{item.item.user.avatar ? <Avatar size={64} src={item.item.user.avatar_sizes.admin_listing} /> : <Avatar size={64} icon="user" />}</p>
											</Col>
											<Col xs={24} sm={20}>
												{item.item.user.name}<br />
												<b>Celular:</b> {item.item.user.customer.cellphone}<br />
												<b>E-mail:</b> {item.item.user.email}<br />
												<b>Atividade:</b> {item.item.user.customer.activity.name}
											</Col>
										</Row>
									)}
								</div>
							)}
							<br />
						</div>
					)}
				</Form>
				<Modal
					visible={this.state.deleteModalVisible}
					title="Remoção de item"
					destroyOnClose={true}
					width={400}
					okText={this.state.deleteModalConfirmLoading ? "Removendo" : "Remover"}
					cancelButtonProps={{disabled: this.state.deleteModalConfirmLoading}}
					onCancel={this.deleteItemOnCancel}
					onOk={this.deleteItemOnConfirm}
					confirmLoading={this.state.deleteModalConfirmLoading}>
					<Form layout="vertical">
						<Form.Item label={this.deleteFieldOptions.reason.label} hasFeedback style={{marginBottom: 0}}>
							{getFieldDecorator("reason", this.deleteFieldOptions.reason.decorator)(
								<Select disabled={this.state.deleteModalConfirmLoading}>
									<Select.Option value="violates_terms_of_use">Viola nossos termos de uso</Select.Option>
								</Select>
							)}
						</Form.Item>
					</Form>
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Show);

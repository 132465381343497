import React, { Component, Fragment } from "react";
import { Button, Col, Form, Icon, Input, message, Modal, Row, Select, Spin } from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { settingService } from "./../../redux/services";

import DefaultTemplate from "./../../templates/defaultTemplate";

const FormItem = Form.Item;

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
		};
	}

	componentDidMount() {
		settingService.getNotifications()
		.then((response) => {
			this.setState({
				isLoading: false,
			});

			// Fill form
			this.fillForm(response.data.data);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
			});
		});
	}

	fieldOptions = {
		report_a_problem: {
			label    : "Problemas relatados",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
					{
						validator: (rule, values, callback) => {
							const emailRegex    = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
							const invalidInputs = values.filter((value) => !value.match(emailRegex));

							if( invalidInputs.length === 0 )
							{
								callback();
							}
							else if( invalidInputs.length === 1 )
							{
								callback("\"" + invalidInputs.join("") + "\" não é um e-mail válido");
							}
							else
							{
								callback("\"" + invalidInputs.slice(0, -1).join("\", \"") + "\" e não são e-mails válidos");
							}
						}
					}
				],
			},
		},
		ask_agronomists : {
			label    : "Consulte os agrônomos",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
					{
						validator: (rule, values, callback) => {
							const emailRegex    = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
							const invalidInputs = values.filter((value) => !value.match(emailRegex));

							if( invalidInputs.length === 0 )
							{
								callback();
							}
							else if( invalidInputs.length === 1 )
							{
								callback("\"" + invalidInputs.join("") + "\" não é um e-mail válido");
							}
							else
							{
								callback("\"" + invalidInputs.slice(0, -1).join(", ") + "\" e ' + invalidInputs.slice(-1) + ' não são e-mails válidos");
							}
						}
					}
				],
			},
		},
		denunciations   : {
			label    : "Denúncias",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
					{
						validator: (rule, values, callback) => {
							const emailRegex    = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
							const invalidInputs = values.filter((value) => !value.match(emailRegex));

							if( invalidInputs.length === 0 )
							{
								callback();
							}
							else if( invalidInputs.length === 1 )
							{
								callback("\"" + invalidInputs.join("") + "\" não é um e-mail válido");
							}
							else
							{
								callback("\"" + invalidInputs.slice(0, -1).join(", ") + "\" e ' + invalidInputs.slice(-1) + ' não são e-mails válidos");
							}
						}
					}
				],
			},
		},
		inactivity      : {
			label    : "Usuários inativos e deletados",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
					{
						validator: (rule, values, callback) => {
							const emailRegex    = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
							const invalidInputs = values.filter((value) => !value.match(emailRegex));

							if( invalidInputs.length === 0 )
							{
								callback();
							}
							else if( invalidInputs.length === 1 )
							{
								callback("\"" + invalidInputs.join("") + "\" não é um e-mail válido");
							}
							else
							{
								callback("\"" + invalidInputs.slice(0, -1).join(", ") + "\" e ' + invalidInputs.slice(-1) + ' não são e-mails válidos");
							}
						}
					}
				],
			},
		}
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			report_a_problem: data.report_a_problem,
			ask_agronomists : data.ask_agronomists,
			denunciations   : data.denunciations,
			inactivity   : data.inactivity,
		});
	};

	resetValidations = () => {
		const values = this.props.form.getFieldsValue();
		let fields   = {};

		for( let key in this.fieldOptions )
		{
			if( this.fieldOptions[key] && values.hasOwnProperty(key) )
			{
				fields = {
					...fields,
					[key]: {
						value: values[key],
					}
				};
			}
		}

		this.props.form.setFields(fields);
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				settingService.updateNotifications(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset validations
					this.resetValidations();

					// Success message
					message.success("Configuração atualizada.");
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	render() {
		const {form}                 = this.props;
		const {isLoading, isSending} = this.state;

		const {getFieldDecorator} = form;

		return (
			<DefaultTemplate>
				<div className="page-content" key="1">
					<h1 className="page-title">Configurações de notificações</h1>
					<Form layout="vertical" onSubmit={this.onSubmit}>
						{isLoading ? (
							<div className="text-center" style={{padding: 20}}>
								<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
							</div>
						) : (
							<Fragment>
								<h3>E-mails que receberão notificações</h3>
								<Row gutter={16}>
									<Col xs={24} sm={12} lg={8} xl={6}>
										<FormItem label={this.fieldOptions.report_a_problem.label} hasFeedback extra="">
											{getFieldDecorator("report_a_problem", this.fieldOptions.report_a_problem.decorator)(
												<Select
													mode="tags"
													tokenSeparators={[',']}
													dropdownRender={() => <div />}
												/>
											)}
										</FormItem>
										<FormItem label={this.fieldOptions.ask_agronomists.label} hasFeedback extra="">
											{getFieldDecorator("ask_agronomists", this.fieldOptions.ask_agronomists.decorator)(
												<Select
													mode="tags"
													tokenSeparators={[',']}
													dropdownRender={() => <div />}
												/>
											)}
										</FormItem>
										<FormItem label={this.fieldOptions.denunciations.label} hasFeedback extra="">
											{getFieldDecorator("denunciations", this.fieldOptions.denunciations.decorator)(
												<Select
													mode="tags"
													tokenSeparators={[',']}
													dropdownRender={() => <div />}
												/>
											)}
										</FormItem>
										<FormItem label={this.fieldOptions.inactivity.label} hasFeedback extra="">
											{getFieldDecorator("inactivity", this.fieldOptions.inactivity.decorator)(
												<Select
													mode="tags"
													tokenSeparators={[',']}
													dropdownRender={() => <div />}
												/>
											)}
										</FormItem>
										<Button type="primary" htmlType="submit" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
									</Col>
								</Row>
							</Fragment>
						)}
					</Form>
				</div>
			</DefaultTemplate>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Index);
